.root {
    // Translate down to center icon on position, add 3px to counter a bug where 3px is added to bottom of svg content wrapper
    transform: translateY(50%);

    .marker {
        display: block;

        fill: var(--executive-blues-80);

        &.darkMode {
            width: 48px;
            height: 48px;

            fill: var(--white);
        }

        &.highlighted {
            fill: var(--eh-orange);
        }
    }
}
