@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 8px;
    padding: 32px;

    color: var(--executive-blues-80);

    .title {
        padding-bottom: 8px;

        font-weight: 700;
        font-size: 32px;
        letter-spacing: 1px;
        text-align: center;
        text-transform: uppercase;
    }

    .streetAddress {
        font-weight: 700;
        font-size: 16px;
        text-transform: uppercase;
    }

    .neighborhoodAndCity {
        margin-bottom: 12px;

        font-variant: small-caps;
        font-weight: 300;
        font-size: 14px;
    }

    .inputRowWrapper {
        display: flex;
        flex-direction: column;

        width: 100%;
    }

    .input {
        width: 100%;
    }

    .buttonsWrapper {
        display: flex;
        gap: 8px;

        margin-top: 24px;
        width: 100%;

        button {
            flex: 1;

            &.submitted {
                cursor: not-allowed;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        min-width: 600px;

        .inputRowWrapper {
            flex-direction: row;
            gap: 12px;
        }
    }
}
