@use "../../styles/mixins.scss" as Mixins;
@import "../../styles/breakpoints.scss";

.root {
    .mobileDetailsBlock {
        // subtract an addition 58px for page header and tab navigator
        height: calc(100svh - var(--header-height) - 58px);
    }

    .videoWrapper {
        width: 100%;
        height: 100%;

        .video {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }

        .videoTextBlock {
            // Absolute position the text boxes to the bottom left
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;

            background: linear-gradient(180deg, transparent 0%, var(--executive-blues-90) 100%);

            padding: 24px 12px 24px;
            height: fit-content;

            .videoTitle {
                color: var(--white);
                font-variant: small-caps;
                font-weight: 700;
                font-size: 14px;
                line-height: 150%;
            }

            .videoDescription {
                color: var(--white);
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;

                // Set paragraph margin to 0 to avoid unnecessary spacing on description
                p {
                    margin: 0;
                }
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .videoWrapper {
            .videoTextBlock {
                padding-bottom: 10px;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE) {
    .root {
        .videoWrapper {
            .videoTextBlock {
                .videoTitle {
                    font-size: 16px;
                }

                .videoDescription {
                    font-size: 14px;
                }
            }
        }
    }
}
