@use "../../styles/mixins.scss" as Mixins;

.root {
    display: flex;

    align-items: center;
    height: 100svh;

    .backgroundImage {
        width: 100%;
        height: 100%;

        @include Mixins.fullSizeAbsolute;
        z-index: -1;
    }

    .inputFormWrapper {
        display: flex;
        flex-direction: column;

        margin: auto;
        border-radius: 8px;
        background-color: var(--white);

        padding: 50px;
        max-width: 500px;

        .ehLogoWithWordmark {
            display: flex;
            justify-content: space-between;

            align-items: center;

            fill: var(--executive-blues-90);

            .ehLogo {
                width: 100px;
                height: 100px;
            }

            .ehWordmarkLogo {
                width: 300px;
                height: 50px;
            }
        }

        .loginFailed {
            margin-top: 24px;

            color: var(--alert);
            font-weight: 600;
            font-size: 16px;
            text-align: center;
        }

        .inputText {
            margin-top: 24px;
        }

        .logInButton {
            align-self: center;

            margin-top: 32px;
            min-width: 200px;
        }
    }
}
