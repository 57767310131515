@use "../../../styles/mixins.scss" as Mixins;
@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    gap: 8px;
    cursor: pointer;

    background: linear-gradient(180deg, transparent 0%, var(--black-90) 100%);
    padding: 24px;
    width: 100%;

    height: 100%;

    // Required to not make the background image overflow onto surrounding images
    overflow: hidden;

    .backgroundImage {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;

        width: 100%;
        height: auto;
        min-height: 100%;
        object-fit: cover;
    }

    .name {
        color: var(--white);
        font-weight: 800;
        font-size: 48px;
        line-height: 100%;
        text-transform: uppercase;
    }

    .divider {
        border: none;
        background-color: var(--secondary-50);
        width: 100%;
        height: 1px;
    }

    .counts {
        display: flex;
        gap: 8px;
        width: 100%;

        .countWrapper {
            display: flex;

            flex: 1;
            justify-content: center;
            align-items: center;
            gap: 8px;
            backdrop-filter: blur(4px);

            border-radius: 4px;
            background: var(--white-20);
            padding: 8px;

            // Over flow hidden for children ellipsis to work
            overflow: hidden;
            font-variant: small-caps;

            .count {
                color: var(--white);
                font-weight: 700;
                font-size: 12px;
                line-height: 100%;

                @include Mixins.ellipsis;
            }

            .units {
                color: var(--white);
                font-weight: 300;
                font-size: 12px;
                line-height: 100%;

                @include Mixins.ellipsis;
            }
        }
    }

    .description {
        color: var(--white);
        font-weight: 400;
        font-size: 16px;
        line-height: 160%; /* 25.6px */
    }

    .browseButton {
        padding: 8px 16px;
        color: var(--white);

        .chevron {
            stroke-width: 3px;
            height: 12px;
            stroke: var(--white);
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        padding: 40px;

        &:hover {
            .browseButton {
                background-color: var(--seafoam-green-15);
            }
        }
    }
}
