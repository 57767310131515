@use "../../../styles/mixins.scss" as Mixins;
@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    transition: height 0.6s ease;
    width: 100%;
    height: 300px;
    overflow: hidden;

    &::before {
        @include Mixins.fullSizeAbsolute;
        z-index: 1;

        background: var(--black-40);
        pointer-events: none;
        content: "";
    }

    .titleWrapper {
        @include Mixins.fullSizeAbsolute;

        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        margin: 0 auto 40px;
        max-width: 85%;
        color: var(--white);
        font-weight: 600;
        font-size: 24px;
        line-height: 1.14;
        font-family: var(--merriweather);
        text-align: center;
        text-shadow:
            0 0 1px var(--black-20),
            0 1px 0 var(--black-20);
    }

    .image {
        width: 100%;
        max-width: none;
        height: auto;
        min-height: 100%;
        object-fit: cover;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        height: 400px;
        .titleWrapper {
            max-width: 65%;
            font-size: 36px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        height: 500px;
        .titleWrapper {
            font-weight: 400;
            font-size: 48px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE) {
    .root {
        .titleWrapper {
            font-size: 60px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-DESKTOP) {
    .root {
        .titleWrapper {
            font-size: 70px;
        }
    }
}
