.root {
    position: absolute;
    // Use -1px position to make sure the edges are clean and no under-content is sticking out
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;

    border: 6px solid var(--seafoam-green);
    border-radius: 8px;
    pointer-events: none; // allows mouse events to pass through the overlay
}
