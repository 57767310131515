@import "../../styles/breakpoints.scss";

.root {
    .contentWrapper {
        box-sizing: content-box;
        margin: 40px auto;
        padding: 0px 15px;
        max-width: 900px;
        color: var(--executive-blues-80);

        .titleDiv {
            margin: 10px 0px 0px;
            font-weight: 700;
            font-size: 24px;
            line-height: 1.5em;
            font-family: var(--merriweather);
        }

        .textDiv {
            margin: 20px 0px;
            font-size: 16px;
            line-height: 1.68em;

            .link {
                color: inherit;
                text-decoration: underline;

                &:hover {
                    color: var(--eh-orange);
                    text-decoration: none;
                }
            }
        }

        .mainBulletPoint {
            font-weight: 600;
        }

        li {
            line-height: 26px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        .contentWrapper {
            padding-right: 30px;
            padding-left: 30px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .contentWrapper {
            padding-right: 40px;
            padding-left: 40px;
        }
    }
}
