@import "../../../styles/breakpoints.scss";
@import "../cards.variables.scss";

.root {
    position: relative;
    margin-bottom: 25px;

    .linkWrapper {
        display: flex;

        position: relative;
        flex-direction: column;
        justify-content: flex-end;

        border-radius: 8px;
        background: var(--executive-blues-90);
        padding: 0px 10px 13px;
        width: $DEFAULT-CARD-WIDTH;
        height: 190px;
        overflow: hidden;
        text-decoration: none;

        .houseImg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;

            width: $DEFAULT-CARD-WIDTH;
            height: 150px;
            object-fit: cover;
        }

        &::before {
            display: block;

            position: absolute;
            right: 0;
            bottom: 40px;
            left: 0;
            z-index: 1;
            background: linear-gradient(180deg, transparent 0%, var(--executive-blues-90) 100%);

            height: 30%;
            content: "";
        }

        .content {
            z-index: 2;
            color: var(--white);
            font-size: 12px;
            line-height: 14px;

            .cardTitle {
                font-weight: 700;
                text-transform: uppercase;
            }

            .subTitle {
                font-variant: small-caps;
                font-weight: 300;
            }

            .bottomSection {
                display: flex;
                gap: 8px;

                margin-top: 8px;

                .detailSection {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 6px;

                    border-radius: 4px;
                    background: var(--executive-blues-80);
                    width: 150px;

                    height: 21px;
                    font-variant: small-caps;

                    font-weight: 500;
                }
            }
        }
    }

    .directionsLink {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        margin-bottom: 0;
    }
}
