@import "../cards.variables.scss";

.root {
    position: relative;

    // Needed to not allow the background color to win over the repeating EH logos from below
    z-index: 0;

    border-radius: 8px;

    background-color: var(--executive-blues-100);

    width: $DEFAULT-CARD-WIDTH;
    height: 190px;
    overflow: hidden;

    .repeatingLogoBackground {
        position: absolute;
        right: 0;

        left: 0;

        // Needed to go behind the text but infront of the background color
        z-index: -1;

        fill: var(--executive-blues-90);
    }

    .gradient {
        display: flex;
        flex-direction: column;

        justify-content: center;

        background: linear-gradient(180deg, transparent 0%, var(--executive-blues-100) 100%);

        width: 100%;
        height: 100%;

        color: var(--white);
        text-align: center;
        text-transform: uppercase;

        .cardTitle {
            font-weight: 700;
            font-size: 24px;
        }

        .cardDescription {
            font-weight: 200;
            font-size: 16px;
        }
    }

    &.white {
        box-shadow: var(--default-box-shadow);
        background-color: var(--white);

        .repeatingLogoBackground {
            fill: var(--secondary-40);
        }

        .gradient {
            background: linear-gradient(180deg, transparent 0%, var(--white) 100%);

            color: var(--executive-blues-80);
        }
    }
}
