.root {
    display: flex;
    position: fixed;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // z-index 10 to beat eh-header
    z-index: 10;

    background-color: var(--executive-blues-90-alpha-90);

    overflow: auto;

    .closeButton {
        position: fixed;

        top: 20px;
        left: 20px;

        .xIcon {
            border-radius: 50%;

            background-color: var(--executive-blues-90-alpha-90);

            padding: 8px;
            width: 40px;
            height: 40px;

            stroke: var(--white);
        }
    }

    .pdfDocument {
        margin: auto;
        width: fit-content;

        :global(.react-pdf__message) {
            display: none;
        }

        .hiddenPdfPage {
            display: none;
        }
    }

    .loadingIndicator {
        position: fixed;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        border-radius: 12px;

        background-color: var(--executive-blues-90-alpha-90);
        padding-top: 20px;
        padding-right: 32px;
        padding-bottom: 20px;

        padding-left: 32px;
        color: var(--white);

        font-size: 16px;
    }

    .pdfControls {
        $cornerRadius: 8px;

        display: flex;

        position: fixed;
        bottom: 20px;
        left: 50%;
        flex-direction: row;

        gap: 1px;
        transform: translateX(-50%);

        color: var(--white);
        font-variant: small-caps;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.6px;
        text-align: center;

        .pageInfo {
            display: flex;
            flex-direction: row;

            $paddingHorizontal: 32px;
            $paddingVertical: 12px;

            align-items: center;

            gap: 8px;

            border-top-left-radius: $cornerRadius;
            border-bottom-left-radius: $cornerRadius;

            background-color: var(--executive-blues-90);
            padding: $paddingVertical $paddingHorizontal;

            .pageInput {
                border-radius: 8px;

                background-color: var(--executive-blues-100);
                max-width: 30px;
                height: 28px;

                color: var(--seafoam-green);
                font-weight: inherit;
                font-size: inherit;
                text-align: inherit;
            }

            .grayText {
                color: var(--secondary-70);
            }
        }

        .pageZoom {
            display: flex;

            $paddingHorizontal: 32px;
            $paddingVertical: 12px;

            align-items: center;

            border-top-right-radius: $cornerRadius;
            border-bottom-right-radius: $cornerRadius;

            background-color: var(--executive-blues-90);
            padding: $paddingVertical $paddingHorizontal;

            .zoomButton {
                display: flex;

                padding: 8px;
            }

            .zoomInput {
                border-radius: 8px;

                background-color: var(--executive-blues-100);
                max-width: 56px;
                height: 28px;

                color: var(--seafoam-green);
                font-weight: inherit;
                font-size: inherit;
                text-align: inherit;
            }
        }
    }

    .pageChangeButton {
        position: fixed;

        top: 50%;
        transform: translateY(-50%);

        background-color: var(--executive-blues-90-alpha-90);

        padding: 20px;

        $borderRadius: 8px;

        stroke: var(--white);

        &.left {
            left: 0;
            border-top-right-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;
        }

        &.right {
            right: 0;
            border-top-left-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
        }
    }
}
