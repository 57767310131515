@use "./styles/themes.scss";

* {
    box-sizing: border-box;
}

body,
button {
    margin: 0;
    font-family: var(--montserrat);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // Hides the Google reCAPTCHA badge on the bottom right of the website
    div .grecaptcha-badge {
        visibility: hidden;
    }
}

a {
    color: unset;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input,
fieldset,
button {
    margin: 0;
    border: 0;
    background: none;
    padding: 0;
}

input,
select,
textarea {
    font-family: var(--montserrat);

    &:focus {
        outline: none;
    }
}

ul {
    list-style-type: square;

    li::marker {
        color: var(--seafoam-green);
    }
}
