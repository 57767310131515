@import "../../styles/breakpoints.scss";

.root {
    .mobileStickyFooterButtonWrapper {
        display: flex;
        gap: 4px;

        button {
            flex: 1;

            font-variant: small-caps;
        }

        .reserveButton {
            border: 0;

            &.reserved {
                background-color: var(--seafoam-green-30);

                color: var(--executive-blues-80-alpha-50);
                font-weight: 700;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .detailsBlock {
            // Makes the shadow appear above the top of the property details block
            margin-top: 12px;
        }
    }
}
