.root {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-bottom: 1px solid var(--secondary-50);
    padding: 15px 12px;

    overflow: hidden;

    .titleButton {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        color: var(--executive-blues-80);
        font-variant: small-caps;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;

        .chevron {
            width: 10px;
            height: 10px;
            stroke: var(--secondary-50);
            stroke-width: 3px;
        }
    }
}
