.root {
    position: relative;
    height: 30px;

    .pinTextWrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: default;

        height: 100%;

        color: var(--white);
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 2px;
        text-transform: uppercase;

        .chevronIcon {
            stroke: var(--white);
            stroke-width: 3px;
            width: 10px;

            height: 10px;
        }

        &.pointerCursor {
            cursor: pointer;
        }
    }

    .locationPin {
        width: 10px;
        height: 14px;
    }

    .dropDown {
        display: flex;
        position: absolute;
        top: 100%;
        right: 0;
        flex-direction: column;

        border: 1px solid var(--seafoam-green);
        border-radius: 8px;
        background-color: var(--executive-blues-100);

        .optionButton {
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;

            padding: 8px 16px;

            color: var(--white);
            font-weight: 400;
            font-size: 12px;
            letter-spacing: 2px;
            text-transform: uppercase;
            text-wrap: nowrap;

            &:hover {
                background: var(--seafoam-green-15);
            }

            &.selected {
                font-weight: 700;
            }
        }
    }

    &.white {
        .pinTextWrapper {
            color: var(--executive-blues-80);

            .chevronIcon {
                stroke: var(--executive-blues-80);
            }
        }

        .dropDown {
            background-color: var(--white);

            .optionButton {
                color: var(--executive-blues-80);

                &.selected {
                    color: var(--black);
                }
            }
        }
    }
}
