.root {
    display: flex;
    flex-direction: row;

    width: 100%;

    .outerLine {
        flex: 1;
        border: 1px solid var(--secondary-40);
        height: 0;
    }

    .centerButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        padding: 4px 8px 0px 8px;

        .buttonText {
            color: var(--executive-blues-80);
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            text-transform: uppercase;
        }

        .chevronIcon {
            stroke: var(--executive-blues-80);
            width: 7px;
            height: 7px;
        }
    }
}
