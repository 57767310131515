@import "../../../../styles/breakpoints.scss";

.root {
    display: flex;

    position: relative;
    flex-direction: row;

    cursor: pointer;

    box-shadow: var(--default-box-shadow);

    border-radius: 8px;
    width: 100%;
    height: fit-content;

    text-decoration: none;

    &.otherSelected {
        opacity: 0.4;
    }

    &.verticalFlex {
        flex-direction: column;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        width: fit-content;
    }
}
