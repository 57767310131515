@use "../../../styles/mixins.scss" as Mixins;
@import "../../../styles/breakpoints.scss";

.root {
    $cardWidth: 162px;

    display: flex;
    flex-direction: column;

    border: 1px solid var(--black-10);
    border-radius: 8px;
    width: $cardWidth;
    height: fit-content;

    // Hides the corners overflowing from the cardHeaderImage
    overflow: hidden;

    text-transform: uppercase;

    &.highlighted {
        border-color: var(--seafoam-green);
    }

    .cardHeader {
        display: flex;
        position: relative;

        width: $cardWidth;
        height: 120px;

        .cardHeaderImage {
            @include Mixins.fullSizeAbsolute;

            z-index: -1;
            width: 100%;
            height: 100%;

            object-fit: cover;
        }

        .repeatingLogoWrapper {
            background: var(--executive-blues-100);
            overflow: hidden;

            .repeatingLogos {
                fill: var(--executive-blues-90);
            }

            .repeatingLogosGradient {
                position: absolute;

                bottom: 0;

                background: linear-gradient(180deg, transparent 0%, var(--executive-blues-100) 100%);
                width: 100%;
                height: 80%;
            }
        }
    }

    .cardSection {
        display: flex;
        flex-direction: column;

        margin-bottom: 2px;
        width: 100%;
        min-height: 48px;

        $paddingVertical: 12px;
        justify-content: center;

        align-items: center;

        background-color: var(--secondary-30);
        padding-top: $paddingVertical;
        padding-bottom: $paddingVertical;
        color: var(--executive-blues-80);

        font-size: 10px;
        line-height: 12px;
        text-wrap: balance;

        &.highlighted {
            background-color: var(--seafoam-green-075);

            &.darker {
                background-color: var(--seafoam-green-15);
            }
        }

        &.darker {
            background-color: var(--secondary-40);
        }

        .neighborhoodName {
            $paddingHorizontal: 12px;
            padding-right: $paddingHorizontal;
            padding-left: $paddingHorizontal;
            font-weight: 700;

            font-size: 12px;
            text-align: center;
            text-wrap: wrap;
        }

        .cardSectionTitle {
            font-weight: 700;
        }

        .cardSectionContent {
            display: flex;
            align-items: center;

            font-weight: 400;
            text-align: center;

            &.minHeight2Lines {
                min-height: 24px;
            }
        }

        .cardSectionContentContainer {
            display: flex;
            flex-direction: column;

            align-items: center;

            gap: 4px;

            margin-top: 6px;
        }
    }

    .cardFooter {
        display: flex;

        justify-content: center;

        background-color: var(--secondary-30);

        padding: 16px;
        width: 100%;

        &.highlighted {
            background-color: var(--seafoam-green-075);
        }

        .cardFooterButton {
            padding: 8px;
            width: 100%;

            color: var(--executive-blues-100);
            font-variant: small-caps;

            font-size: 14px;
            text-transform: capitalize;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE) {
    .root {
        $cardWidth: 260px;
        width: $cardWidth;

        .cardHeader {
            width: $cardWidth;
            height: 165px;
        }

        .cardSection {
            min-height: 60px;

            line-height: 18px;

            .neighborhoodName {
                $paddingHorizontal: 18px;
                padding-right: $paddingHorizontal;
                padding-left: $paddingHorizontal;

                font-size: 14px;
            }

            .cardSectionTitle {
                font-size: 12px;
            }

            .cardSectionContent {
                font-size: 14px;

                &.minHeight2Lines {
                    min-height: 36px;
                }
            }
        }

        .cardFooter {
            .cardFooterButton {
                font-size: 16px;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-DESKTOP) {
    .root {
        $cardWidth: 308px;
        width: $cardWidth;

        .cardHeader {
            width: $cardWidth;
            height: 175px;
        }

        .cardSection {
            min-height: 68px;

            line-height: 20px;

            .neighborhoodName {
                $paddingHorizontal: 22px;
                padding-right: $paddingHorizontal;
                padding-left: $paddingHorizontal;

                font-size: 18px;
            }

            .cardSectionTitle {
                font-size: 14px;
            }

            .cardSectionContent {
                font-size: 16px;

                &.minHeight2Lines {
                    min-height: 40px;
                }
            }
        }
    }
}
