@import "../../../styles/breakpoints.scss";

.estimatedClosing {
    display: flex;
    flex-direction: column;

    font-size: 12px;
    text-align: end;

    .light {
        font-weight: 400;
        font-size: 8px;
    }

    .bold {
        font-weight: 700;
    }
}

.firstSubtitle {
    color: var(--executive-blues-80);
    font-weight: 700;
    font-size: 12px;
    line-height: 125%;
    text-transform: uppercase;
}

.secondSubtitle {
    color: var(--secondary-80);
    font-variant: small-caps;
    font-weight: 300;
    font-size: 12px;
    line-height: 125%;
}

.tourTabContent {
    border: none;
    width: 100%;
    height: 100%;
}

.detailIcon {
    min-width: 24px;
    height: 24px;
}

.smallerDetailIcon {
    min-width: 20px;
    height: 20px;
}

@media (min-width: $HORIZONTAL-BREAKPOINT-X-SMALL) {
    .firstSubtitle {
        font-size: 14px;
    }

    .secondSubtitle {
        font-size: 14px;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .estimatedClosing {
        flex-direction: row;

        gap: 6px;

        font-size: 20px;
        text-align: start;

        .light {
            font-size: 20px;
        }
    }

    .firstSubtitle {
        font-size: 16px;
    }

    .secondSubtitle {
        font-size: 16px;
    }

    .detailIcon {
        min-width: 45px;
        height: 45px;
    }

    .smallerDetailIcon {
        min-width: 36px;
        height: 36px;
    }
}
