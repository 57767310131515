@import "../../../styles/breakpoints.scss";

.root {
    box-shadow: var(--default-box-shadow);
    background: var(--white);
    color: var(--executive-blues-80);

    .cardTitle {
        padding-bottom: 30px;
        padding-bottom: 10px;
        font-weight: 300;
        font-size: 24px;
        line-height: 30px;
        font-family: var(--merriweather);
        letter-spacing: 0.6px;
        text-align: center;
    }

    .sectionDivider {
        margin: 0 auto;
        border: 2px solid var(--eh-orange);
        width: 95px;
    }

    .content {
        padding-top: 10px;
        font-size: 14px;
        line-height: 1.625;
        text-align: center;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .cardTitle {
            padding-bottom: 20px;
            font-size: 32px;
        }

        .content {
            padding-top: 20px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE) {
    .root {
        .cardTitle {
            padding-bottom: 30px;
            font-size: 50px;
            line-height: 50px;
            text-align: left;
        }

        .sectionDivider {
            margin: 0;
        }

        .content {
            padding-top: 30px;
            text-align: left;
        }
    }
}
