@import "../../../styles/breakpoints.scss";

.root {
    width: 100%;
    max-width: 600px;

    .formTitle {
        margin: 15px 0px;
        color: var(--white);
        font-weight: 300;
        font-size: 24px;
        line-height: 20px;
        letter-spacing: 2px;
        text-align: center;
        text-transform: uppercase;
    }

    .expandPanel {
        margin: 0 auto;
        max-width: 512px;
    }

    &.darkText {
        .formTitle {
            color: var(--executive-blues-80);
        }
    }
}

.successBottomSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.backButton {
    min-width: 162px;
    font-variant: small-caps;

    font-size: 14px;
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .successBottomSection {
        flex-direction: row;
        gap: 32px;
    }
}
