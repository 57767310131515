$side-padding: 20px;

.root {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;

    gap: 20px;
    padding: 0 $side-padding;

    .childWrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 450px;

        &.fakeWrapper {
            position: absolute;

            top: 0;
            right: $side-padding;
            left: $side-padding;
            visibility: hidden;
        }

        &.realWrapper {
            transition: height 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
            overflow: hidden;
        }
    }
}
