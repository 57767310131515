@import "../../styles/breakpoints.scss";

.root {
    color: var(--executive-blues-80);

    .pageTitle {
        margin-top: 0;
        margin-bottom: 12px;
        font-weight: 400;

        font-size: 24px;
        letter-spacing: 2px;
        text-align: center;
        text-transform: uppercase;
    }

    .pageSection {
        margin-right: auto;
        margin-left: auto;
        padding-right: 16px;
        padding-left: 16px;
        width: 100%;
        max-width: 900px;

        .sectionHeader {
            font-weight: 700;
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;

            &.big {
                font-size: 32px;
            }
        }

        .sectionText {
            font-weight: 400;
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;
        }

        .embedInteractiveWalkthrough {
            margin-bottom: 64px;

            border: 1px solid var(--secondary-50);
            width: 100%;
            height: 550px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        .pageSection {
            padding-right: 30px;
            padding-left: 30px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-DESKTOP) {
    .root {
        .pageTitle {
            font-size: 40px;
        }
    }
}
