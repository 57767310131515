.blue {
    border-color: var(--light-blue);
    background-color: var(--light-blue-70);
}

.green {
    border-color: var(--green);
    background-color: var(--green-70);
}

.orange {
    border-color: var(--eh-orange);
    background-color: var(--eh-orange-70);
}

.purple {
    border-color: var(--purple);
    background-color: var(--purple-70);
}

.red {
    border-color: var(--alert);
    background-color: var(--alert-70);
}

.seafoamGreen {
    border-color: var(--seafoam-green);
    background-color: var(--seafoam-green-70);
}

.white {
    border-color: var(--white);
    background-color: var(--white-70);
}

.innerIcon {
    transform: rotate(45deg);
    overflow: visible;
}
