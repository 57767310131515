@import "../../../styles/breakpoints.scss";

.root {
    $contentMaxWidth: 1300px;

    .searchInput {
        margin-right: 8px;

        margin-bottom: 8px;
        margin-left: 8px;
        // - 16px because there is margin of 8px on each left and right side
        width: calc(100% - 16px);
        max-width: 960px;
    }

    .stickyTop {
        position: sticky;
        top: var(--header-bottom);
        z-index: 1;
        transition: var(--header-transition);

        background-color: var(--white);
        max-width: $contentMaxWidth;
    }

    .faqSection {
        position: relative;
        margin-right: 16px;

        margin-bottom: 16px;
        margin-left: 16px;
        max-width: $contentMaxWidth;

        .sectionAnchor {
            position: absolute;
            // -120px to ensure the anchor is positioned above the start of the icon tab and header which comes out to roughly 120 pixels
            top: -120px;
        }

        .faqCategoryTitle {
            margin: 0;
            border-bottom: 1px solid var(--seafoam-green);
            padding: 16px 8px;
            font-weight: 700;

            font-size: 24px;
            text-align: center;
            text-transform: uppercase;
        }

        .accordion {
            .accordionItem {
                border-bottom: 1px solid var(--secondary-40);

                .question {
                    display: flex;
                    flex-direction: row;

                    justify-content: space-between;
                    align-items: center;

                    gap: 12px;

                    cursor: pointer;
                    margin: 0;

                    padding: 16px 8px;
                    font-weight: 700;

                    font-size: 12px;
                    text-decoration: none;
                    text-transform: uppercase;

                    .chevron {
                        width: 12px;
                        height: 12px;

                        stroke: var(--executive-blues-80);
                    }
                }

                .answer {
                    position: relative;
                    padding-right: 8px;

                    padding-left: 8px;
                    max-height: 0;

                    overflow: hidden;

                    color: var(--secondary-100);
                    font-weight: 400;

                    font-size: 14px;

                    img {
                        max-width: 100%;
                        height: fit-content;

                        object-fit: contain;
                    }
                }

                &.open {
                    .answer {
                        max-height: fit-content;
                    }
                }
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .searchInput {
            margin-right: auto;
            margin-left: auto;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-DESKTOP) {
    .root {
        .faqSection {
            margin-right: auto;
            margin-left: auto;
        }
    }
}
