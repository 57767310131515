@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    position: relative;
    flex-direction: column;

    height: fit-content;

    .headerTitle {
        display: flex;
        flex-direction: row;

        align-items: center;

        gap: 32px;

        .selectButton {
            $verticalPadding: 8px;

            padding-top: $verticalPadding;
            padding-bottom: $verticalPadding;

            text-transform: initial;
        }
    }

    .stickyWrapper {
        position: sticky;
        // Add 28 pixels for mobile tab navigator
        top: calc(var(--header-bottom) + 28px);
        // Above bottom gradient on cards
        z-index: 3;

        transition: var(--header-transition);

        background-color: var(--white);
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        margin-bottom: 50px;
        background-image: none;
        padding-bottom: 0;
        // Subtract an additional 80 for extra spacing to fit full view in screen easily
        height: calc(100svh - var(--header-height) - 80px);

        .stickyWrapper {
            top: var(--header-bottom);
        }

        &::after {
            content: none;
        }
    }
}
