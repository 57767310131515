@use "../../../styles/mixins.scss" as Mixins;
@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;

    border-radius: 8px;
    padding: 16px;

    width: 95%;
    max-width: 900px;
    max-height: 95%;
    overflow: hidden;

    .titleText {
        color: var(--executive-blues-80);
        font-weight: 300;
        font-size: 20px;
        line-height: 200%;
        letter-spacing: 4px;
        text-transform: uppercase;
    }

    .priceRow {
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-bottom: 1px solid var(--secondary-50);
        padding-top: 4px;
        padding-bottom: 4px;

        .priceLabel {
            color: var(--executive-blues-80);
            font-weight: 700;
            font-size: 12px;
            line-height: 150%;
            text-transform: uppercase;

            @include Mixins.ellipsis;
        }

        .priceText {
            color: var(--executive-blues-80);
            font-variant: small-caps;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;

            &.purchasePrice {
                font-weight: 700;
                font-size: 18px;
                line-height: 100%;
            }
        }
    }

    .reserveButton {
        margin-top: 16px;
        margin-bottom: 16px;

        border: 0;

        width: 100%;

        color: var(--executive-blues-80);
        font-weight: 500;
        font-size: 14px;

        &.reserved {
            cursor: not-allowed;
            background-color: var(--seafoam-green-30);

            color: var(--executive-blues-80-alpha-50);
            font-weight: 700;
        }
    }

    .catalogItemsCarousel {
        width: 100%;
    }

    .catalogItemsListsSection {
        display: flex;
        gap: 24px;

        > :first-child {
            border-right: 1px solid var(--secondary-50);
            padding-right: 24px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        padding: 48px;

        .titleText {
            font-size: 32px;
        }

        .reserveButton {
            padding: 12px 0px;
            font-size: 24px;
        }
    }
}
