@use "../../../styles/mixins.scss" as Mixins;

.root {
    position: relative;

    .iconButton {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 50%;

        background-color: var(--secondary-40);

        width: 31px;
        height: 31px;

        &.sortSelected {
            border: 1px solid var(--seafoam-green);
            background-color: var(--seafoam-green-10);

            .sqftIcon {
                svg {
                    stroke: var(--seafoam-green);
                }
            }

            .priceSortIcon {
                :first-child {
                    fill: var(--seafoam-green);
                }

                :not(:first-child) {
                    stroke: var(--seafoam-green);
                }
            }
        }
    }

    .dropdown {
        position: absolute;
        top: 100%;
        right: 0;

        transition: all 300ms linear;
        border: 1px solid var(--secondary-50);

        border-radius: 8px;
        background-color: var(--white);

        max-width: 200px;
        max-height: 200px;

        overflow: hidden;

        &.hidden {
            visibility: hidden;
            max-width: 0;
            max-height: 0;
        }

        .dropdownOption {
            $horizontalPadding: 14px;
            $verticalPadding: 4px;

            display: flex;
            flex-direction: row;

            align-items: center;
            padding: $verticalPadding $horizontalPadding;

            @include Mixins.ellipsis;

            cursor: pointer;

            &:hover {
                background-color: var(--seafoam-green-15);
            }

            &.selectedOption {
                background-color: var(--seafoam-green-30);

                .iconWrapper {
                    .popularPickIcon {
                        stroke: var(--executive-blues-80);
                    }

                    .sqftIcon {
                        svg {
                            stroke: var(--executive-blues-80);
                        }
                    }

                    .priceSortIcon {
                        :first-child {
                            fill: var(--executive-blues-80);
                        }

                        :not(:first-child) {
                            stroke: var(--executive-blues-80);
                        }
                    }
                }

                .optionText {
                    color: var(--executive-blues-80);
                    font-weight: 600;
                }
            }

            .iconWrapper {
                display: flex;

                align-items: center;
                width: 24px;
                height: 24px;

                .starIcon {
                    width: 12px;
                    height: 12px;

                    fill: var(--secondary-70);
                }

                .sqftIcon {
                    svg {
                        stroke: var(--secondary-70);
                    }
                }

                .priceSortIcon {
                    :first-child {
                        fill: var(--secondary-70);
                    }

                    :not(:first-child) {
                        stroke: var(--secondary-70);
                    }
                }
            }

            .optionText {
                color: var(--secondary-70);
                font-variant: small-caps;
                font-weight: 500;
                font-size: 12px;
            }
        }
    }
}
