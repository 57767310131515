.root {
    display: flex;
    align-items: center;
    gap: 7px;

    margin-top: 2px;
    padding: 5px;

    color: var(--white);
    font-weight: 400;
    font-size: 10px;
    text-decoration: none;
    text-transform: uppercase;

    .mapIcon {
        width: 18px;
        height: 18px;
    }

    &.darkText {
        color: var(--executive-blues-80);
    }
}
