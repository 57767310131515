@import "../../styles/breakpoints.scss";

.root {
    .marketsWrapper {
        display: flex;
        flex-direction: column;
        height: calc(100svh - var(--header-height));

        .marketDisplay {
            flex: 1;
            height: 100%;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .marketsWrapper {
            flex-direction: row;
        }
    }
}
