@import "../../../styles/breakpoints.scss";

.root {
    .blockSectionHeader {
        margin-bottom: 20px;
    }

    .differenceMakers {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        margin: 0 auto;
        padding: 0 50px;

        max-width: 940px;
    }

    .icon {
        margin-bottom: 12px;
        width: 37px;
        height: 37px;

        overflow: visible;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        .blockSectionHeader {
            margin-bottom: 32px;
        }

        .icon {
            width: 45px;
            height: 45px;

            &.streamlinedEfficiency {
                // 17px margin to account for 5px less size
                margin-bottom: 17px;
                width: 40px;
                height: 40px;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE) {
    .root {
        .differenceMakers {
            grid-template-columns: repeat(3, 1fr);
            gap: 50px;
            width: 1300px;

            max-width: 100%;
        }
    }
}
