@use "../../../../../styles/mixins.scss" as Mixins;
@import "../../../../../styles/breakpoints.scss";

.root {
    display: flex;
    position: relative;
    flex-direction: column;

    height: 100%;

    .imageWrapper {
        position: relative;
        flex: 1;

        width: 100%;

        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &::after {
            position: absolute;
            right: 0;
            bottom: 0px;
            left: 0;

            background: linear-gradient(180deg, transparent 0%, var(--white) 100%);
            height: 30%;

            content: "";
        }
    }

    .bottomSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        background-color: var(--white);
        padding: 0px 16px 40px 16px;
        height: fit-content;

        .firstContainer {
            flex: 1;

            color: var(--executive-blues-80);

            h2 {
                margin: 0px;
                font-weight: 700;
                font-size: 18px;
                text-transform: uppercase;
            }

            p {
                margin: 4px 0px 0px;
                font-weight: 400;
                font-size: 14px;
            }
        }

        .secondContainer {
            flex: 1;
            width: 100%;

            .detailContainer {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 8px;

                .detail {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    svg {
                        flex-shrink: 0;
                        width: 28px;
                        height: 28px;
                    }

                    .detailStat {
                        display: flex;
                        flex-direction: column;
                        overflow: hidden;

                        color: var(--executive-blues-80);
                        font-size: 10px;
                        text-transform: uppercase;

                        label {
                            font-weight: 700;
                        }

                        span {
                            font-weight: 400;
                        }
                    }
                }
            }

            .buttonContainer {
                display: flex;
                gap: 8px;
                margin-top: 8px;

                button {
                    flex: 1;

                    border: none;
                    background-color: var(--seafoam-green-30);

                    color: var(--executive-blues-80);

                    svg {
                        stroke: var(--executive-blues-80);
                    }
                }
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .imageWrapper {
            &::after {
                background: linear-gradient(180deg, transparent 0%, var(--executive-blues-90) 100%);
            }
        }

        .bottomSection {
            flex-direction: row;
            gap: 24px;

            background-color: var(--executive-blues-90);
            padding: 0px 48px 24px 24px;

            .firstContainer {
                color: var(--white);
            }

            .secondContainer {
                .detailContainer {
                    .detail {
                        svg {
                            width: 32px;
                            height: 32px;
                        }

                        .detailStat {
                            color: var(--white);
                            font-size: 12px;
                        }
                    }
                }

                .buttonContainer {
                    margin-top: 24px;

                    button {
                        color: var(--white);

                        svg {
                            stroke: var(--white);
                        }
                    }
                }
            }
        }

        .convenienceMapWrapper {
            @include Mixins.fullSizeAbsolute;

            .xButton {
                display: flex;
                position: absolute;
                top: 8px;
                right: 8px;

                cursor: pointer;

                border: 4px solid var(--white);
                border-radius: 50%;
                background-color: var(--secondary-30);
                padding: 4px;

                svg {
                    width: 12px;
                    height: 12px;
                }
            }

            &.hidden {
                display: none;
            }
        }
    }
}
