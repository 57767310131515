@import "../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 8px;
    padding: 40px 16px 24px;
    // Need important so we don't have to nest this class
    width: 90% !important;
    max-width: 650px;

    color: var(--executive-blues-80);

    .title {
        padding-bottom: 8px;

        font-weight: 700;
        font-size: 32px;
        letter-spacing: 1px;
        text-align: center;
        text-transform: uppercase;
    }

    .subTitle {
        font-weight: 700;
        font-size: 16px;
        text-transform: uppercase;
    }

    .secondSubTitle {
        margin-bottom: 12px;

        font-variant: small-caps;
        font-weight: 300;
        font-size: 14px;
    }

    .inputRowWrapper {
        display: flex;
        flex-direction: column;

        width: 100%;
    }

    .input {
        width: 100%;
    }

    .sendMessageButton {
        margin-top: 24px;

        &.submitted {
            cursor: not-allowed;
        }
    }

    .successMessageBottom {
        display: flex;
        flex-direction: row;

        gap: 12px;

        .successMessageButton {
            padding: 8px;

            width: 180px;

            font-variant: small-caps;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        padding: 32px;

        .inputRowWrapper {
            flex-direction: row;

            gap: 12px;
        }
    }
}
