@use "../../../styles/mixins.scss" as Mixins;

.root {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    min-width: 200px;

    .price {
        display: flex;
        align-items: baseline;
        gap: 4px;

        color: var(--executive-blues-80);
        font-variant: small-caps;
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;

        .lotPremiumText {
            color: var(--secondary-90);
            font-weight: 400;
            font-size: 16px;
            text-transform: uppercase;
        }

        &.pointer {
            cursor: pointer;
        }
    }

    .fullAddress {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // Add min-width to get ellipsis to work
        min-width: 80px;

        .streetAddress {
            color: var(--executive-blues-80);
            font-weight: 700;
            font-size: 14px;
            line-height: 100%;
            text-transform: uppercase;

            @include Mixins.ellipsis;

            &.largeText {
                font-size: 20px;
            }
        }

        .neighborhoodAndCity {
            color: var(--secondary-80);
            font-variant: small-caps;
            font-size: 14px;
            line-height: 125%;

            @include Mixins.ellipsis;
        }
    }

    .selection {
        display: flex;
        align-items: center;
        gap: 6px;

        .selectionIcon {
            stroke: var(--executive-blues-80);
            width: 24px;
            height: 24px;
        }

        .selectionText {
            color: var(--executive-blues-80);
            font-variant: small-caps;
            font-weight: 500;
            font-size: 16px;
            text-wrap: nowrap;
        }
    }

    // Give all immediate children divs a left border and padding to act as gap
    > div:not(:first-child) {
        border-left: 1px solid var(--secondary-50);
        padding-left: 16px;
    }
}
