@import "../../../styles/breakpoints.scss";

.root {
    // Need display flex for dynamic heights of children
    display: flex;
    flex-direction: column;

    // Use VH as we assume we are scrolled down so header/search bars are hidden
    // Subtract 70 for sticky footer short height
    height: calc(100vh - 70px);

    .stickyWrapper {
        position: sticky;
        // Add 28 pixels for mobile tab navigator
        top: calc(var(--header-bottom) + 28px);
        // Above + signs on upgrade cards
        z-index: 2;

        transition: var(--header-transition);

        background-color: var(--white);
    }

    .compareSlide {
        // Need important to override carousel slide default display inline
        display: flex !important;
        flex-direction: column;
        gap: 8px;
        box-shadow: var(--default-box-shadow);
        border-radius: 8px;

        padding: 12px;

        height: 100%;
        overflow: hidden;
    }

    .carousel {
        padding-top: 8px;

        height: 100%;
        overflow: hidden;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        margin-bottom: 50px;
        background-image: none;
        padding-bottom: 0;
        // Subtract an additional 80 for extra spacing to fit full view in screen easily
        height: calc(100svh - var(--header-height) - 80px);

        .stickyWrapper {
            top: var(--header-bottom);
        }

        .compareSlide {
            flex-direction: row;
            gap: 16px;

            padding: 16px;
        }

        .carousel {
            padding-top: 16px;
        }
    }
}
