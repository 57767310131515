@use "../../../styles/mixins.scss" as Mixins;
@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;

    gap: 12px;

    width: 100%;
    height: 250px;
    overflow: hidden;

    .title {
        color: var(--executive-blues-80);
        font-weight: 300;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 4px;
        text-transform: uppercase;

        @include Mixins.ellipsis;
    }

    .listWrapper {
        padding-right: 8px;
        height: 100%;
        overflow-y: scroll;

        @include Mixins.customScrollBar;

        .itemWrapper {
            display: flex;
            gap: 8px;
            border-bottom: 1px solid var(--secondary-50);
            padding: 8px 0px;

            .checkWrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;

                background-color: var(--seafoam-green);
                width: 20px;
                height: 20px;

                .checkIcon {
                    fill: var(--white);
                    width: 12px;
                    height: 12px;
                }

                &.included {
                    background-color: var(--secondary-50);
                }

                &.unselected {
                    border: 2px solid var(--seafoam-green);
                    background-color: var(--white);
                }
            }

            .itemName {
                color: var(--executive-blues-80);
                font-weight: 700;
                font-size: 12px;
                line-height: 166%;
                text-transform: uppercase;

                @include Mixins.ellipsis;
            }

            .itemPrice {
                margin-left: auto;
                color: var(--secondary-80);
                font-variant: small-caps;
                font-weight: 400;
                font-size: 12px;
                line-height: 166%;
            }

            &.pointer {
                cursor: pointer;
                user-select: none;
            }
        }
    }

    .emptyListPlaceholder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;

        width: 100%;
        height: 100%;

        color: var(--executive-blues-80);
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
}

@media (min-height: $VERTICAL-BREAKPOINT-SMALL) {
    .root {
        height: 300px;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .title {
            font-size: 24px;
        }
    }
}
