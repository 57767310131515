:global(.flatpickr-calendar) {
    display: none;
    position: absolute;
    box-shadow: var(--default-box-shadow);

    border-radius: 16px;
    background: var(--executive-blues-90);
    width: fit-content;
    overflow: hidden;

    font-size: 14px;
    line-height: 24px;

    &:focus {
        outline: 0;
    }

    // Sets display off of none when open
    &:global(.open) {
        display: inline-block;
        z-index: 99999;

        // Define the animation for when the calendar opens
        &:global(.animate) {
            animation: fpFadeInDown 600ms cubic-bezier(0.23, 1, 0.32, 1);
        }
    }

    // Used if calendar has inline property set
    &:global(.inline) {
        display: block;
        position: relative;
        top: 2px;
    }

    :global(.numInputWrapper) {
        display: flex;
        position: relative;
        justify-content: center;
        margin-top: 1px;
        border-left: 1px solid var(--secondary-60);
        padding-left: 10px;
        width: 100%;
        height: 40px;

        input {
            border: 0;
            background: transparent;
            padding-right: 14px;
            width: 100%;
            text-align: center;

            &:focus {
                outline: 0;
                border: 0;
            }

            // Remove default up and down arrows for input
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        span {
            position: absolute;
            right: 0;
            opacity: 0;
            cursor: pointer;
            border: 1px solid var(--secondary-70);
            padding: 0 4px 0 2px;
            width: 14px;
            height: 50%;

            &:hover {
                background: var(--white-10);
            }

            &:active {
                background: var(--white-10);
            }

            &::after {
                display: block;
                position: absolute;
                content: "";
            }

            &:global(.arrowUp) {
                &::after {
                    top: 26%;
                    border-right: 4px solid transparent;
                    border-bottom: 4px solid var(--secondary-70);
                    border-left: 4px solid transparent;
                }
            }
            &:global(.arrowDown) {
                top: 50%;

                &::after {
                    top: 40%;
                    border-top: 4px solid var(--secondary-70);
                    border-right: 4px solid transparent;
                    border-left: 4px solid transparent;
                }
            }
        }

        &:hover {
            input {
                background: var(--white-10);
            }

            span {
                opacity: 1;
            }
        }
    }

    :global(.flatpickr-months) {
        width: 307.875px;

        :global(.flatpickr-prev-month),
        :global(.flatpickr-next-month) {
            position: absolute;
            top: 3px;
            cursor: pointer;
            padding: 10px;
            height: 34px;

            svg {
                width: 12px;
                height: 16px;
                stroke-width: 2px;
                stroke: var(--white);
            }
        }

        :global(.flatpickr-prev-month) {
            left: 0;
        }

        :global(.flatpickr-next-month) {
            right: 0;
        }

        :global(.flatpickr-month) {
            flex: 1;
            height: 42px;
            overflow: hidden;
            color: var(--white);

            :global(.flatpickr-current-month) {
                display: flex;
                position: absolute;
                left: 12.5%;
                width: 75%;
                font-variant: small-caps;
            }
        }

        :global(.cur-year) {
            display: inline-block;
            color: inherit;

            &:focus {
                outline: 0;
            }
        }

        :global(.flatpickr-monthDropdown-months) {
            position: relative;

            // Make appearance menulist for all browsers
            appearance: menulist;
            -webkit-appearance: menulist;
            -moz-appearance: menulist;
            cursor: pointer;
            margin-top: 1px;
            margin-right: 10px;
            margin-bottom: 1px;
            border: none;
            background: var(--executive-blues-90);
            padding: 5px 5px;
            height: 40px;
            color: inherit;

            option {
                background: var(--executive-blues-100);
            }

            &:hover,
            &:focus,
            &:active {
                outline: none;
                background: var(--white-10);
            }
        }
    }

    :global(.flatpickr-innerContainer) {
        border-top: 1px solid var(--secondary-60);

        :global(.flatpickr-weekdays) {
            display: flex;
            align-items: center;
            margin: 10px 0px;
            width: 100%;
            height: 28px;
            overflow: hidden;
            text-align: center;

            :global(.flatpickr-weekdaycontainer) {
                display: flex;
                flex: 1;
            }

            :global(.flatpickr-weekday) {
                flex: 1;
                color: var(--white);
                font-weight: 600;
                font-size: 14px;
            }
        }

        :global(.dayContainer) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            width: 308px;

            :global(.flatpickr-day) {
                flex-basis: 40px;
                cursor: pointer;
                border: 1px solid transparent;
                border-radius: 50%;
                width: 40px;

                height: 40px;

                color: var(--white);
                font-weight: 400;
                line-height: 40px;
                text-align: center;

                &:hover,
                &:focus {
                    background: var(--seafoam-green-30);
                }

                &:global(.today) {
                    border-color: var(--white);

                    &:focus,
                    &:hover {
                        background: var(--seafoam-green-30);
                    }
                }

                &:global(.prevMonthDay),
                &:global(.nextMonthDay),
                &:global(.notAllowed) {
                    color: var(--white-30);
                }

                &:global(.flatpickr-disabled),
                &:global(.flatpickr-disabled:hover) {
                    cursor: not-allowed;
                    border-color: transparent;
                    background: transparent;
                    color: var(--white-10);
                }

                &:global(.selected),
                &:global(.startRange),
                &:global(.endRange) {
                    border-color: var(--seafoam-green);
                    background: var(--seafoam-green);
                    color: var(--executive-blues-90);
                }

                &:global(.startRange) {
                    border-radius: 50px 0 0 50px;
                }

                &:global(.endRange) {
                    border-radius: 0 50px 50px 0;

                    &:global(.startRange) {
                        border-radius: 50px;
                    }
                }

                &:global(.inRange) {
                    border-radius: 0;
                    background: var(--seafoam-green-50);
                }
            }
        }
    }
}

.flatpickrInput[readonly] {
    cursor: pointer;
}

@keyframes fpFadeInDown {
    from {
        transform: translate3d(0, -20px, 0);
        opacity: 0;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
