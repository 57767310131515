.intersectionIcon {
    width: 16px;
    min-width: 16px;
    height: 16px;
}

.bookIcon {
    width: 16px;
    min-width: 16px;
    height: 16px;

    stroke: var(--white);
}
