@use "../../../../styles/mixins.scss" as Mixins;
@import "../../../../styles/breakpoints.scss";

.root {
    .titleWrapper {
        display: flex;
        cursor: pointer;

        .titleInformationIcon {
            display: flex;
            padding: 4px;

            width: 24px;
            height: 24px;
            stroke: var(--secondary-70);
        }
    }

    .dividedSelections {
        display: flex;
        width: 100%;

        > div:first-child {
            padding-right: 8px;
        }

        > div:not(:first-child) {
            border-left: 1px solid var(--secondary-50);
            padding-left: 8px;
        }

        .selection {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            padding-right: 8px;

            width: 50%;

            .selectionName {
                color: var(--executive-blues-80);
                font-variant: small-caps;
                font-weight: 500;
                font-size: 12px;

                @include Mixins.ellipsis;
            }

            .selectionIcon {
                width: 16px;
                height: 16px;

                stroke: var(--executive-blues-80);
            }
        }
    }

    .uniqueLotDescription {
        color: var(--secondary-80);
        font-variant: small-caps;
        font-weight: 300;
        font-size: 16px;
    }

    .buttonContainer {
        display: flex;

        gap: 8px;

        width: 100%;
    }

    .buttonRow {
        display: flex;
        gap: 8px;

        padding-bottom: 8px;
        width: 100%;

        .redirectButton {
            flex: 1;
        }

        // If not last child add a border on the bottom to separate next child
        &:not(:last-child) {
            border-bottom: 1px solid var(--secondary-50);
        }
    }

    .reserveButton {
        flex: 1;
        border: 0;
        width: 100%;

        color: var(--executive-blues-80);
        font-weight: 500;
        font-size: 14px;

        &.reserved {
            cursor: not-allowed;
            background-color: var(--seafoam-green-30);

            color: var(--executive-blues-80-alpha-50);
            font-weight: 700;
        }
    }

    .googleMaps {
        width: 100%;
    }

    .tourTab {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-X-SMALL) {
    .root {
        .dividedSelections {
            .selection {
                .selectionName {
                    font-size: 14px;
                }

                .selectionIcon {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        .dividedSelections {
            .selection {
                .selectionIcon {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .buttonContainer {
            flex-direction: column;

            box-shadow: var(--default-box-shadow);

            border-radius: 8px;

            background: var(--white);

            padding: 16px;

            .reserveButton {
                font-size: 20px;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .titleWrapper {
            .titleInformationIcon {
                margin-top: 4px;
                width: 32px;
                height: 32px;
            }
        }

        .inquireButton {
            width: fit-content;
        }

        .googleMaps {
            height: 100%;
        }
    }
}
