.root {
    display: flex;
    flex-direction: column;

    .carouselSliderWrapper {
        height: calc(100% - 56px);
    }

    .carouselInformation {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        margin-top: 16px;

        .slideCounter {
            display: flex;
            flex-direction: row;
            gap: 6px;

            font-size: 18px;
            line-height: 22px;

            .numerator {
                color: var(--executive-blues-80);
                font-weight: 700;
            }

            .denominator {
                color: var(--secondary-70);
                font-weight: 400;
            }
        }

        .centerLine {
            flex: 1;
            margin-right: 36px;
            margin-left: 36px;

            background-color: var(--secondary-50);
            height: 1px;
        }

        .arrowButton {
            cursor: pointer;
            border-width: 1px;
            border-style: solid;
            border-color: var(--secondary-50);

            background-color: transparent;
            width: 40px;
            height: 40px;

            &.leftArrowButton {
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
            }

            &.rightArrowButton {
                border-left: 0;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
            }

            .chevronIcon {
                width: 12px;
                height: 12px;

                stroke: var(--black);
            }
        }
    }
}
