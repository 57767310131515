.flagIcon {
    width: 16px;
    height: 16px;
    fill: var(--seafoam-green);

    &.floorPlanIcon {
        fill: none;
        stroke: var(--seafoam-green);
    }
}
