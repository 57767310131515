@use "../../../styles/mixins.scss" as Mixins;
@import "../../../styles/breakpoints.scss";

.root {
    position: relative;

    .map {
        @include Mixins.fullSizeAbsolute;

        :global(.gm-style) {
            iframe + div {
                // Google has a 2 px solid blue border on one of the divs, this gets rid of that border when focusing on the map
                border: none !important;
            }
        }
    }

    .debug {
        position: absolute;

        top: 10px;
        left: 10px;

        border-radius: 8px;

        background-color: var(--black-50);

        padding: 4px;
        color: var(--white);
    }

    .recenterButton {
        // Display flex to center properly
        display: flex;

        // Position above google maps +- buttons
        position: absolute;
        right: 60px;
        bottom: 22px;
        cursor: pointer;
        // Same as Googles default button box shadows to match
        box-shadow: 0px 1px 4px -1px var(--black-30);

        border-radius: 50%;

        background-color: var(--white);
        padding: 9px;

        .recenterIcon {
            width: 22px;
            height: 22px;

            stroke: var(--executive-blues-80);
        }
    }

    .clusterWrapper {
        border-radius: 50%;
        background-color: var(--executive-blues-80-alpha-25);
        padding: 8px;

        .clusterDiv {
            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 50%;
            background-color: var(--executive-blues-80);
            color: var(--white);
            font-weight: 500;
            font-size: 14px;

            font-family: var(--montserrat);
        }

        &.hoveredCluster {
            background-color: var(--eh-orange-25);

            .clusterDiv {
                background-color: var(--eh-orange);
            }
        }
    }
}

@media (min-height: $VERTICAL-BREAKPOINT-SMALL) {
    .recenterButton {
        right: 10px;
        bottom: 115px;
    }
}
