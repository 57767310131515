@use "../../../styles/mixins.scss" as Mixins;

.root {
    display: flex;

    position: relative;
    flex: 1;

    align-items: center;

    gap: 10px;

    border: 1px solid var(--secondary-50);

    border-radius: 18px;

    padding: 4px 8px;
    min-width: 250px;

    .searchIcon {
        width: 24px;
        height: 24px;

        fill: var(--executive-blues-80);
    }

    .searchInput {
        padding-right: 32px;
        width: 100%;

        color: var(--executive-blues-80);
        font-variant: small-caps;
        font-weight: 500;
        font-size: 14px;

        @include Mixins.ellipsis;

        &::placeholder {
            color: var(--secondary-90);
        }
    }

    .roundButton {
        display: flex;

        position: absolute;
        right: 3px;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        border-radius: 50%;

        background-color: var(--secondary-40);
        width: 28px;
        height: 28px;
    }
}
