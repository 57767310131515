@import "../../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    padding: 10px 0;

    .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 50%;
        width: 66px;
        height: 66px;

        &.success {
            background-color: var(--seafoam-green-30);
            padding-top: 3px;
            padding-left: 3px;
        }

        &.fail {
            background-color: var(--alert-30);
        }
    }

    .messageWrapper {
        text-align: center;

        .messageTitle {
            color: var(--executive-blues-80);
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
        }

        .message {
            color: var(--secondary-80);
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }
    }

    &.footerVariant {
        .messageWrapper {
            .messageTitle {
                color: var(--white);
                line-height: 20px;
            }

            .message {
                color: var(--white);
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        &.footerVariant {
            flex-direction: row;
            gap: 20px;

            padding: 0;

            .messageWrapper {
                text-align: left;
            }
        }
    }
}
