@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;

    padding: 0 20px;

    .iconAndShadow {
        display: flex;
        flex-direction: column;
        justify-content: center;

        align-items: center;
        min-width: 80px;
    }

    .centerPiece {
        display: flex;

        position: relative;
        flex-direction: row;
        align-items: center;
        align-self: center;

        .verticalBar {
            position: absolute;
            top: 0;
            bottom: 0;

            background-color: var(--secondary-50);
            width: 1px;

            &.left {
                left: 0;
            }

            &.right {
                right: 0;
            }
        }

        .horizontalBar {
            border-top: 1px solid var(--secondary-50);
            border-bottom: 1px solid var(--secondary-50);
            width: 200px;
            height: 8px;
        }

        .centerText {
            border-width: 1px;
            border-style: solid;
            border-color: var(--secondary-50);

            border-radius: 8px;

            padding: 8px;
            max-width: 250px;

            color: var(--executive-blues-80);
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 2px;
            text-align: center;
            text-transform: uppercase;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        gap: 20px;

        .iconAndShadow {
            min-width: 145px;
        }

        .centerPiece {
            .centerText {
                padding: 16px;
                max-width: 340px;

                font-size: 14px;
                line-height: 16px;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        gap: 40px;

        .iconAndShadow {
            min-width: 210px;
        }

        .centerPiece {
            .centerText {
                padding: 24px;
                max-width: 400px;

                font-size: 18px;
                line-height: 24px;
            }
        }
    }
}
