.root {
    .optionalUpgradesButton {
        transition: opacity 0.3s ease-in;

        &.hidden {
            opacity: 0;
            cursor: default;
        }
    }

    .detailsBlockButtonsWrapper {
        display: flex;
        gap: 8px;

        .baseButton {
            flex-grow: 1;
        }
    }
}
