@import "../../../styles/breakpoints.scss";

:root {
    --header-transition: all 600ms ease-out;
    --header-height: 40px;
    --header-bottom: 40px;
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    :root {
        --header-height: 50px;
        --header-bottom: 50px;
    }
}
