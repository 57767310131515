@use "../../../styles/mixins.scss" as Mixins;
.root {
    @include Mixins.fullSizeAbsolute;

    display: flex;

    position: fixed;

    flex-direction: column;

    // 11 to stay above the modal and the rest of the page's content
    z-index: 11;

    background-color: var(--black-75);
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    overscroll-behavior: none;

    > img {
        width: 100%;
        height: auto;
    }

    .closeButton {
        display: flex;
        position: fixed;
        top: 24px;
        right: 24px;

        justify-content: center;
        align-items: center;

        // Puts the close button on top of the image
        z-index: 1;

        cursor: pointer;

        border-radius: 40px;

        background-color: var(--white-75);
        width: 40px;
        height: 40px;

        .xIcon {
            width: 16px;
            height: 16px;
        }
    }
}
