.root {
    position: relative;
    box-shadow: var(--default-box-shadow);

    border-radius: 8px;
    width: 100%;

    height: 100%;
    overflow: hidden;

    &.clickable {
        cursor: pointer;
    }

    .imageSlide {
        // Override default text block padding to 8px
        > div {
            padding-bottom: 8px;
        }
    }

    .styleSelectButton {
        display: flex;
        position: absolute;
        top: 16px;
        left: 16px;

        align-items: center;

        gap: 12px;

        box-shadow: var(--default-box-shadow);
        border-radius: 16px;

        background-color: var(--white-75);

        padding: 6px 12px;

        color: var(--executive-blues-80);
        font-variant: small-caps;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;

        .chevronIcon {
            width: 16px;
            height: 16px;

            stroke: var(--executive-blues-80);
        }
    }
}
