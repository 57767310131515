.root {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 10px;
    width: 100%;
    height: 100%;

    .levelImage {
        max-width: 100%;
        object-fit: contain;

        &.single {
            height: 100%;
        }

        &.withSwitch {
            height: calc(100% - 55px);
        }

        &.hidden {
            display: none;
        }
    }

    .levelSwitch {
        width: 240px;

        .switchButton {
            padding: 8px;
            font-size: 16px;
        }
    }
}

.carousel {
    width: 100%;
    height: 100%;

    div {
        height: 100%;
    }

    .imageInSlide {
        height: 100%;
        object-fit: contain;
    }
}
