.root {
    .blockHeaderSpacing {
        margin-bottom: 32px;
    }

    .carousel {
        margin: auto;
        padding-right: 32px;
        padding-left: 32px;
        max-width: 1300px;
    }
}
