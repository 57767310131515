.root {
    position: relative;
    flex: 1;

    cursor: pointer;

    box-shadow: var(--default-box-shadow);
    border-radius: 16px;
    // Needed for when information is expanded to do the blue tint on background image on mobile
    background-blend-mode: multiply, normal;

    height: 100%;
    min-height: 342px;

    overflow: hidden;
    user-select: none;

    &.noBackground {
        &:has(.expandedPanel) {
            transition: background 0.2s;
            background: var(--executive-blues-100-alpha-90);
        }
    }

    .iconWrapper {
        display: flex;
        position: absolute;
        top: 8px;
        left: 8px;

        box-shadow: 0px 4px 4px var(--black-50);
        border: solid 2px var(--seafoam-green);
        border-radius: 50%;
        background-color: var(--executive-blues-100);
        padding: 6px;
    }

    .expandedPanel {
        position: absolute;
        top: 56px;

        padding: 0px 8px;
        width: 100%;

        h2 {
            margin: 0 0 24px;

            color: var(--white);
            font-weight: 700;
            font-size: 16px;
            text-transform: uppercase;
        }

        p {
            color: var(--white);
            font-weight: 300;
            font-size: 12px;
        }
    }

    .bottomDetails {
        position: absolute;
        bottom: 0;

        background: linear-gradient(180deg, transparent 0%, var(--executive-blues-100) 100%);

        padding: 32px 8px 16px;
        width: 100%;

        h2 {
            margin: 0 0 12px;

            color: var(--white);
            font-weight: 700;
            font-size: 24px;
            text-transform: uppercase;
        }

        p {
            margin: 0;

            color: var(--white);
            font-weight: 400;
            font-size: 14px;
        }
    }
}
