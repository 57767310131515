.root {
    .link {
        display: block;

        margin-top: 24px;
        margin-bottom: 24px;
        width: 100%;

        color: var(--executive-blues-90);
        font-weight: 700;
        font-size: 24px;
        font-family: var(--merriweather);

        text-align: center;
    }
}
