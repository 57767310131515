@use "../../../styles/mixins.scss" as Mixins;
@import "../cards.variables.scss";

.root {
    grid-column: span 2;

    .titleWrapper {
        display: flex;
        justify-content: space-between;

        .name {
            @include Mixins.ellipsis;
        }

        .price {
            font-weight: 400;
        }
    }

    &.verticalCard {
        grid-column: span 1;
        width: 100%;

        .cardContent {
            width: 100%;
        }

        .floorPlanLevelDisplay {
            width: 100%;
        }
    }
}
