@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;

    border-radius: 8px;

    padding: 12px;

    width: 95%;
    max-width: 650px;

    .title {
        margin-top: 8px;
        margin-bottom: 8px;

        color: var(--executive-blues-80);

        font-weight: 700;
        font-size: 18px;
        text-align: center;
    }

    .horizontalSeparator {
        margin-top: 0;
        margin-bottom: 4px;

        border: none;
        border-top: 1px solid var(--secondary-50);

        width: 100%;
        height: 1px;
    }

    .sectionHeader {
        margin-top: 4px;
        margin-bottom: 4px;

        color: var(--executive-blues-80);
        font-variant: small-caps;

        font-weight: 700;
        font-size: 12px;
    }

    .sectionList {
        margin-top: 8px;
        margin-bottom: 0;

        padding-left: 20px;

        list-style-type: disc;

        .sectionBulletPoint {
            color: var(--secondary-80);

            font-variant: small-caps;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;

            &::marker {
                color: var(--secondary-80);
            }
        }
    }

    .reserveButton {
        align-self: center;

        margin-top: 8px;

        width: 90%;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-DESKTOP) {
    .root {
        padding: 24px;

        .title {
            margin-bottom: 16px;
        }

        .horizontalSeparator {
            margin-bottom: 12px;
        }

        .sectionHeader {
            margin-top: 8px;
            margin-bottom: 8px;
        }

        .reserveButton {
            margin-top: 16px;
        }
    }
}
