@import "../../styles/breakpoints.scss";

.root {
    justify-content: center;

    gap: 80px;
    // Used to hide x overflow from carousels
    overflow: hidden;
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        gap: 100px;
    }
}
