.root {
    // On mobile make cards stretch to width of screen to fill white space
    .mobilePropertyCardWrapper {
        width: 100%;

        .mobilePropertyCardContent {
            width: 100%;
        }
    }
}
