.root {
    width: 394px;

    .panelTitle {
        margin: 15px 0px;
        color: var(--white);
        font-weight: 300;
        font-size: 24px;
        line-height: 20px;
        letter-spacing: 2px;
        text-align: center;
        text-transform: uppercase;
    }

    .switch {
        display: flex;

        justify-content: center;
        margin-right: auto;
        margin-bottom: 15px;

        margin-left: auto;
    }

    .openHouseClosedCardWrapper {
        display: flex;

        justify-content: center;

        width: 100%;
    }

    &.darkText {
        .panelTitle {
            color: var(--executive-blues-80);
        }
    }
}
