@use "../../../styles/mixins.scss" as Mixins;
@import "../../../styles/breakpoints.scss";

.root {
    display: flex;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 100%;
    flex-direction: column;
    visibility: hidden;
    z-index: 11; // Higher than header

    transition:
        all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1),
        opacity 0.6s linear;
    outline: none;
    background-color: var(--secondary-30);

    overflow: hidden;

    &.open {
        left: max(100% - 800px, 0px);
        visibility: visible;
    }

    .title {
        display: flex;
        flex-shrink: 0; /* Prevent top and bottom children from shrinking */
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--secondary-50);
        padding: 6px 12px;

        width: 100%;

        color: var(--executive-blues-80);
        font-variant: small-caps;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;

        .xButton {
            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 50%;
            background-color: var(--secondary-40);
            width: 31px;

            height: 31px;
        }
    }

    .filterList {
        flex: 1;
        // Make the property card container scroll only
        overflow-y: auto;

        overscroll-behavior: contain; /* Prevent SCROLL-CHAINING to parent elements. */

        @include Mixins.noScrollBar;
    }

    .bottomFilterButtons {
        display: flex;
        flex-shrink: 0; /* Prevent top and bottom children from shrinking */
        gap: 8px;
        border-top: 3px solid var(--seafoam-green);
        background-color: var(--seafoam-green-10);
        padding: 12px 16px;

        .resetButton {
            flex: 1;
            border: 1px solid var(--seafoam-green);
            background-color: transparent;
            padding: 12px 16px;
            height: fit-content;
            font-variant: small-caps;
        }

        .applyButton {
            flex: 1;
            border: 1px solid var(--seafoam-green); // Add border to keep consistent size
            padding: 12px 16px;
            height: fit-content;
            font-variant: small-caps;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        position: absolute;
        bottom: auto;
        left: 90%; // Left 90% to make the transition go into the x button
        opacity: 0;
        z-index: 1; // Needs to be lower than X button

        border-radius: 18px;
        height: fit-content;

        max-height: 0; // Use max-height for the vertical transition since regular height is variable

        &.open {
            opacity: 1;
            border: 1px solid var(--secondary-50);

            max-height: min(850px, calc(100svh - 240px));
        }

        .filterList {
            padding: 0px 12px 12px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE) {
    .root {
        .title {
            justify-content: center;
        }
    }
}
