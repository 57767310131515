@import "../../../../styles/breakpoints.scss";

.root {
    // Above previous block
    z-index: 1;

    .detailsBlock {
        // 1150 height for height of 3 cards, since we can't do fit content as tabs are absolute
        height: 1150px;
    }

    .desktopHeader {
        position: sticky;
        top: var(--header-bottom);
        z-index: 1;

        margin-bottom: 20px;
        border-bottom: solid 1px var(--secondary-50);
        background-color: var(--white);
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .detailsBlock {
            // Subtract 200 to allow room for header and book tour bottom ribbon on screen at same time
            height: calc(100svh - 200px);
            // Add max height to not stretch too tall
            max-height: 800px;

            .tabWrapper {
                box-shadow: none;
                padding-right: 16px;
                overflow: visible;
            }
        }
    }
}
