@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;

    gap: 8px;
    padding: 16px;

    .imageSlide {
        box-shadow: var(--default-box-shadow);
        border-radius: 8px;
        height: 264px;
        overflow: hidden;

        > div {
            // Override default text block padding to 8px
            padding-bottom: 8px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        .imageSlide {
            height: 450px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        // 16 top 8 sides 0 bottom
        padding: 16px 8px 0px;
        height: 100%;

        overflow: hidden;

        .imageSlide {
            height: 100%;
        }
    }
}
