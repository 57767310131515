@use "./fonts/fonts.scss";

/**
 * Light Theming
 */
:root {
    // Seafoam-greens
    --seafoam-green: #76d5d8;
    --seafoam-green-70: rgba(118, 213, 216, 0.7);
    --seafoam-green-50: rgba(118, 213, 216, 0.5);
    --seafoam-green-30: rgba(118, 213, 216, 0.3);
    --seafoam-green-15: rgba(118, 213, 216, 0.15);
    --seafoam-green-10: rgba(118, 213, 216, 0.1);
    --seafoam-green-075: rgba(118, 213, 216, 0.075);
    --seafoam-green-5: rgba(118, 213, 216, 0.05);

    // OSU oranges
    --osu-orange: #fe5c00;

    // OU reds
    --ou-red: #841617;

    // Executive-blues
    --executive-blues-100: #1a2229;
    --executive-blues-100-alpha-90: rgb(26, 34, 41, 0.9);
    --executive-blues-90: #293139;
    --executive-blues-90-alpha-90: rgba(62, 69, 76, 0.9);
    --executive-blues-80: #3e454c;
    --executive-blues-80-alpha-60: rgba(62, 69, 76, 0.6);
    --executive-blues-80-alpha-50: rgba(62, 69, 76, 0.5);
    --executive-blues-80-alpha-40: rgba(62, 69, 76, 0.4);
    --executive-blues-80-alpha-25: rgba(62, 69, 76, 0.25);
    --executive-blues-70: #566370;
    --executive-blues-70-alpha-50: rgba(86, 99, 112, 0.5);

    // Secondaries
    --secondary-100: #585858;
    --secondary-90: #6e6e6e;
    --secondary-80: #828282;
    --secondary-70: #959595;
    --secondary-60: #a9a9a9;
    --secondary-50: #d2d2d2;
    --secondary-45: #e3e3e3;
    --secondary-40: #f3f3f3;
    --secondary-30: #f9f9f9;
    --white: #ffffff;
    --white-75: rgba(255, 255, 255, 0.75);
    --white-70: rgba(255, 255, 255, 0.7);
    --white-30: rgba(255, 255, 255, 0.3);
    --white-20: rgba(255, 255, 255, 0.2);
    --white-10: rgba(255, 255, 255, 0.1);
    --white-05: rgba(255, 255, 255, 0.05);
    --black: #000000;
    --black-90: rgba(0, 0, 0, 0.9);
    --black-80: rgba(0, 0, 0, 0.8);
    --black-75: rgba(0, 0, 0, 0.75);
    --black-60: rgba(0, 0, 0, 0.6);
    --black-50: rgba(0, 0, 0, 0.5);
    --black-40: rgba(0, 0, 0, 0.4);
    --black-30: rgba(0, 0, 0, 0.3);
    --black-25: rgba(0, 0, 0, 0.25);
    --black-20: rgba(0, 0, 0, 0.2);
    --black-15: rgba(0, 0, 0, 0.15);
    --black-10: rgba(0, 0, 0, 0.1);
    --black-05: rgba(0, 0, 0, 0.05);
    --black-025: rgba(0, 0, 0, 0.025);

    // Tertiary
    --purple: #9a6fef;
    --purple-70: rgba(154, 111, 239, 0.7);
    --yellow: #ffcf38;
    --light-blue: #6685f3;
    --light-blue-70: rgb(102, 133, 243, 0.7);
    --green: #61ba53;
    --green-70: rgb(97, 186, 83, 0.7);
    --alert: #c32a2a;
    --alert-70: rgb(195, 42, 42, 0.7);
    --alert-30: rgb(195, 42, 42, 0.3);
    --moderate-red: #c05451;

    // Other
    --eh-orange: #fe7e66;
    --eh-orange-70: rgba(254, 126, 102, 0.7);
    --eh-orange-25: rgba(254, 126, 102, 0.25);
    --eh-orange-5: rgba(254, 126, 102, 0.05);

    // Box Shadows
    //                    x   y   radius
    --default-box-shadow: 0px 2px 12px var(--black-15);
}

/**
 * Dark Theming
 */
// @media (prefers-color-scheme: dark) {
//     :root {
//     }
// }
