$dot-size: 10px;
$animation-time: 0.5s;
$active-scale: 1;
$active-color: var(--seafoam-green);
$inner-scale: 0.8;
$inner-color: var(--secondary-40);
$outer-scale: 0.6;
$outer-color: var(--secondary-30);
$hidden-scale: 0.2;
$hidden-color: transparent;
$gap: 4px;
$travel-distance: calc($dot-size + ($gap));

.root {
    .dots {
        display: flex;
        position: absolute;
        bottom: 12px;
        left: 50%;
        gap: $gap;
        transform: translateX(-50%);

        backdrop-filter: blur(4px);
        cursor: pointer;

        margin: 0;
        border-radius: 16px;
        background-color: var(--black-40);
        padding: 4px;

        li {
            translate: 0;
            opacity: 1;
            // allows animation to/from display: none
            transition-behavior: allow-discrete;

            border-radius: 50%;
            width: $dot-size;
            height: $dot-size;

            list-style: none;

            &.active {
                scale: $active-scale;
                background-color: $active-color;
            }

            &.innerBefore,
            &.innerAfter {
                scale: $inner-scale;
                background-color: $inner-color;
            }

            &.outerBefore,
            &.outerAfter {
                scale: $outer-scale;
                background-color: $outer-color;
            }

            &.hidden {
                scale: $hidden-scale;
                opacity: 0;
                background-color: $hidden-color;
                pointer-events: none;
            }

            &.removed {
                display: none;
            }
        }

        //#region base dot animation configuration
        &.rightToLeft {
            li {
                &.outerBefore {
                    animation: innerBeforeToOuterBefore $animation-time ease forwards;
                }

                &.innerBefore {
                    animation: activeToInnerBefore $animation-time ease forwards;
                }

                &.active {
                    animation: innerAfterToActive $animation-time ease forwards;
                }

                &.innerAfter {
                    animation: outerAfterToInnerAfter $animation-time ease forwards;
                }

                &.outerAfter {
                    animation: hiddenToOuterAfter $animation-time ease forwards;
                }
            }
        }

        &.leftToRight {
            li {
                &.outerBefore {
                    animation: hiddenToOuterBefore $animation-time ease forwards;
                }

                &.innerBefore {
                    animation: outerBeforeToInnerBefore $animation-time ease forwards;
                }

                &.active {
                    animation: innerBeforeToActive $animation-time ease forwards;
                }

                &.innerAfter {
                    animation: activeToInnerAfter $animation-time ease forwards;
                }

                &.outerAfter {
                    animation: innerAfterToOuterAfter $animation-time ease forwards;
                }
            }
        }
        //#endregion base dot animation configuration

        //#region edge case dot animation configuration
        &.toActiveFirst {
            &.leftToRight li {
                &.active {
                    animation: innerToActiveInPlace $animation-time ease forwards;
                }

                &.innerAfter {
                    animation: activeToInnerInPlace $animation-time ease forwards;
                }

                &.outerAfter {
                    animation: innerAfterToOuterAfterInPlace $animation-time ease forwards;

                    &.inPlace {
                        animation: none;
                    }
                }
            }
        }

        &.toActiveSecond {
            &.rightToLeft li {
                &.innerBefore {
                    animation: activeToInnerInPlace $animation-time ease forwards;
                }

                &.active {
                    animation: innerToActiveInPlace $animation-time ease forwards;
                }

                &.innerAfter {
                    animation: outerToInnerInPlace $animation-time ease forwards;
                }

                &.outerAfter {
                    animation: none;
                }
            }

            &.leftToRight li {
                &.innerBefore {
                    animation: outerToInnerInPlace $animation-time ease forwards;
                }

                &.active {
                    animation: innerToActiveInPlace $animation-time ease forwards;
                }

                &.innerAfter {
                    animation: activeToInnerInPlace $animation-time ease forwards;
                }

                &.outerAfter {
                    animation: innerAfterToOuterAfterInPlace $animation-time ease forwards;

                    &.inPlace {
                        animation: none;
                    }
                }
            }
        }

        &.toActiveSecondFromLast {
            &.rightToLeft li {
                &.outerBefore.inPlace {
                    animation: none;
                }

                &.outerBefore {
                    animation: innerToOuterInPlace $animation-time ease forwards;
                }

                &.innerBefore {
                    animation: activeToInnerInPlace $animation-time ease forwards;
                }

                &.active {
                    animation: innerToActiveInPlace $animation-time ease forwards;
                }

                &.innerAfter {
                    animation: outerToInnerInPlace $animation-time ease forwards;
                }
            }

            &.leftToRight li {
                &.outerBefore {
                    animation: none;
                }

                &.innerBefore {
                    animation: outerToInnerInPlace $animation-time ease forwards;
                }

                &.active {
                    animation: innerToActiveInPlace $animation-time ease forwards;
                }

                &.innerAfter {
                    animation: activeToInnerInPlace $animation-time ease forwards;
                }
            }
        }

        &.toActiveLast {
            &.rightToLeft li {
                &.outerBefore {
                    animation: innerToOuterInPlace $animation-time ease forwards;

                    &.inPlace {
                        animation: none;
                    }
                }

                &.innerBefore {
                    animation: activeToInnerInPlace $animation-time ease forwards;
                }

                &.active {
                    animation: innerToActiveInPlace $animation-time ease forwards;
                }
            }
        }
        //#endregion edge case dot animation configuration
    }

    &.exterior {
        padding-bottom: 24px;

        .dots {
            bottom: 0px;
        }
    }
}

// Mixin for keyframes
@mixin create-keyframes(
    $name,
    $translate-start,
    $translate-end,
    $scale-start,
    $scale-end,
    $color-start,
    $color-end,
    $opacity-start,
    $opacity-end
) {
    @keyframes #{$name} {
        0% {
            translate: $translate-start;
            scale: $scale-start;
            opacity: $opacity-start;
            background-color: $color-start;
        }
        100% {
            translate: $translate-end;
            scale: $scale-end;
            opacity: $opacity-end;
            background-color: $color-end;
        }
    }
}

//#region dot animation keyframes
// stationary animations
@include create-keyframes(innerToOuterInPlace, 0, 0, $inner-scale, $outer-scale, $inner-color, $outer-color, 1, 1);
@include create-keyframes(innerToActiveInPlace, 0, 0, $inner-scale, $active-scale, $inner-color, $active-color, 1, 1);
@include create-keyframes(activeToInnerInPlace, 0, 0, $active-scale, $inner-scale, $active-color, $inner-color, 1, 1);
@include create-keyframes(outerToInnerInPlace, 0, 0, $outer-scale, $inner-scale, $outer-color, $inner-color, 1, 1);

// Key Frames for Right to Left paging motion
@include create-keyframes(innerBeforeToOuterBefore, $travel-distance, 0, $inner-scale, $outer-scale, $inner-color, $outer-color, 1, 1);
@include create-keyframes(activeToInnerBefore, $travel-distance, 0, $active-scale, $inner-scale, $active-color, $inner-color, 1, 1);
@include create-keyframes(innerAfterToActive, $travel-distance, 0, $inner-scale, $active-scale, $inner-color, $active-color, 1, 1);
@include create-keyframes(outerAfterToInnerAfter, $travel-distance, 0, $outer-scale, $inner-scale, $outer-color, $inner-color, 1, 1);
@include create-keyframes(hiddenToOuterAfter, $travel-distance, 0, $hidden-scale, $outer-scale, $hidden-color, $outer-color, 0, 1);

// Key Frames for Left to Right paging motion
@include create-keyframes(hiddenToOuterBefore, -$travel-distance, 0, $hidden-scale, $outer-scale, $hidden-color, $outer-color, 0, 1);
@include create-keyframes(outerBeforeToInnerBefore, -$travel-distance, 0, $outer-scale, $inner-scale, $outer-color, $inner-color, 1, 1);
@include create-keyframes(innerBeforeToActive, -$travel-distance, 0, $inner-scale, $active-scale, $inner-color, $active-color, 1, 1);
@include create-keyframes(activeToInnerAfter, -$travel-distance, 0, $active-scale, $inner-scale, $active-color, $inner-color, 1, 1);
@include create-keyframes(innerAfterToOuterAfter, -$travel-distance, 0, $inner-scale, $outer-scale, $inner-color, $outer-color, 1, 1);
//#endregion dot animation keyframes
