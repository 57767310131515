@use "../../../styles/mixins.scss" as Mixins;

.root {
    position: sticky;
    bottom: 0;
    // Above sticky block headers
    z-index: 3;

    background: var(--white);

    // 0px top 8px sides and bottom
    padding: 0px 8px 8px;
    height: fit-content;
    scroll-snap-align: unset !important;

    .innerWrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;

        box-shadow: var(--default-box-shadow);
        border-radius: 8px;

        padding: 4px 8px;

        .animatedSection {
            display: grid;
            grid-template-rows: 0fr;
            transition: grid-template-rows 0.25s ease-out;

            &.isOpen {
                grid-template-rows: 1fr;
            }

            .contentContainer {
                overflow: hidden;

                > div {
                    padding: 4px 0;

                    &:not(:last-child) {
                        border-bottom: 1px solid var(--secondary-50);
                    }
                }
            }
        }

        .topRow {
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--secondary-50);
            padding-bottom: 4px;

            .price {
                display: flex;
                align-items: center;
                gap: 4px;

                color: var(--executive-blues-80);
                font-weight: 500;
                font-size: 20px;
                text-transform: uppercase;

                @include Mixins.ellipsis;

                .informationIcon {
                    width: 16px;
                    height: 16px;
                    stroke: var(--secondary-70);
                }
            }

            .estimatedClosing {
                flex-shrink: 0;
                margin-left: auto;
                text-align: end;

                .text {
                    color: var(--secondary-90);
                    font-weight: 500;
                    font-size: 9px;
                    text-transform: uppercase;
                }

                .date {
                    color: var(--executive-blues-80);
                    font-weight: 600;
                    font-size: 12px;
                    text-transform: uppercase;
                }
            }

            .closeButton {
                margin-right: -8px;
                padding: 8px 12px;

                .chevron {
                    width: 10px;
                    height: 12px;

                    stroke-width: 3;
                    stroke: var(--executive-blues-80);
                }
            }
        }

        .addressAndNeighborhoodWrapper {
            .streetAddress {
                color: var(--executive-blues-80);
                font-weight: 600;
                font-size: 12px;
                text-transform: uppercase;

                @include Mixins.ellipsis;
            }

            .neighborhoodAndCity {
                color: var(--executive-blues-80);
                font-variant: small-caps;
                font-weight: 400;
                font-size: 12px;

                @include Mixins.ellipsis;
            }
        }

        .selections {
            display: grid;
            grid-template-columns: 1fr 1fr;

            padding-bottom: 8px;

            > :not(:last-child) {
                border-right: 1px solid var(--secondary-50);
            }

            .selection {
                display: flex;
                justify-content: center;

                gap: 4px;

                width: 100%;

                color: var(--executive-blues-80);
                font-variant: small-caps;
                font-weight: 500;
                font-size: 12px;

                @include Mixins.ellipsis;

                .selectionIcon {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}
