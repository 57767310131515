.root {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .directionsLink {
        position: absolute;
        bottom: 22px;
        left: 50%;
        transform: translateX(-50%);

        border-radius: 16px;
        background-color: var(--white);
        padding: 4px 8px;

        font-weight: 500;

        svg {
            stroke: var(--executive-blues-80);
        }
    }
}
