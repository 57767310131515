.root {
    $color: var(--white);

    position: relative;

    transition: all ease-in 0.3s;

    cursor: pointer;

    border: 2px solid $color;
    border-radius: 3px;

    width: 34px;
    height: 22px;

    &::before {
        position: absolute;
        top: 1px;
        right: -1px;

        transform: rotate(60deg);
        transform-origin: top right;
        transition: all 0.3s ease-in;

        background-color: $color;
        width: 2px;
        height: 19px;
        content: "";
    }

    &::after {
        position: absolute;
        top: 1px;
        left: -1px;

        transform: rotate(-60deg);
        transform-origin: top left;
        transition: all 0.3s ease-in;

        background-color: $color;
        width: 2px;
        height: 19px;
        content: "";
    }

    &.dark {
        $color: var(--executive-blues-80);

        border: 2px solid $color;
        &::before {
            background-color: $color;
        }

        &::after {
            background-color: $color;
        }
    }

    &.active {
        border-color: transparent;

        &::before {
            top: -15%;
            right: 48.5%;

            transform: rotate(-135deg);
            transform-origin: center;

            height: 24px;
        }

        &::after {
            top: -15%;
            left: 45%;

            transform: rotate(135deg);
            transform-origin: center;

            height: 24px;
        }
    }
}
