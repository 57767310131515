@import "../../../styles/breakpoints.scss";

.interiorArrows {
    .arrowButton {
        position: absolute;
        top: 60%;
        transform: translateY(-50%);
        // Makes arrows go to top so they are clickable
        z-index: 1;
        cursor: pointer;

        outline: none;

        padding: 8px;
        height: fit-content;

        $borderRadius: 8px;

        &.left {
            left: 0;

            border-top-right-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;
        }

        &.right {
            right: 0;

            border-top-left-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
        }

        .chevron {
            stroke: var(--white);
        }
    }

    &.large {
        .arrowButton {
            top: 50%;
            backdrop-filter: blur(4px);
            background-color: var(--black-10);

            &.left {
                padding-left: 0;
            }

            &.right {
                padding-right: 0;
            }

            .chevron {
                width: 32px;
                height: 32px;
            }
        }
    }
}

.exteriorArrows {
    margin: 0 auto;
    // Add horizontal padding for space for exterior arrows
    padding: 0px 24px;
    overflow: hidden;

    .arrowButton {
        position: absolute;
        top: calc(50% - 24px);
        cursor: pointer;
        padding: 2px 4px;

        &.left {
            left: 8px;
        }

        &.right {
            right: 8px;
        }

        .chevron {
            stroke: var(--secondary-80);
            width: 12px;
            height: 12px;
        }
    }

    &.bordered {
        .arrowButton {
            top: 0;

            transition: all 0.3s ease-in;
            padding: 0;
            width: 24px;
            height: 100%;

            &.left {
                left: 0;
                border-right: 1px solid var(--secondary-50);
            }

            &.right {
                right: 0;
                border-left: 1px solid var(--secondary-50);
            }

            &.hidden {
                opacity: 0;
                cursor: default;
            }

            .chevron {
                height: 16px;
                stroke: var(--executive-blues-80);
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .exteriorArrows {
        .arrowButton {
            &.left {
                left: 0;
            }

            &.right {
                right: 0;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE) {
    .interiorArrows {
        &.large {
            .arrowButton {
                .chevron {
                    width: 48px;
                    height: 48px;
                }
            }
        }
    }
}
