@import "../../../../../styles/breakpoints.scss";

.root {
    display: flex;
    justify-content: center;
    gap: 12px;

    margin-bottom: 30px;

    .callTextButton {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-color: var(--seafoam-green);
        padding: 8px;
        width: 100%;
        min-width: 100px;
        max-width: 250px;
        color: var(--white);
    }

    &.darkText {
        .callTextButton {
            color: var(--executive-blues-80);
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        gap: 30px;

        .callTextButton {
            padding: 16px;
        }
    }
}
