@import "../../../styles/breakpoints.scss";

.root {
    display: flex;

    justify-content: center;
    align-items: center;

    gap: 24px;

    padding: 0px 24px;

    height: fit-content;

    .title {
        color: var(--executive-blues-80);
        font-size: 24px;
        text-align: center;
        text-transform: uppercase;
    }

    .subtitle {
        border-left: 1px solid var(--secondary-50);

        padding-left: 24px;

        color: var(--secondary-90);
        font-size: 14px;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        justify-content: flex-start;
        padding: 8px 24px;

        .title {
            text-wrap: nowrap;
        }
    }
}
