@import "../../styles/breakpoints.scss";

.root {
    .cardWrapper {
        display: flex;
        flex-direction: column;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        height: 100svh;

        .cardWrapper {
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            height: 100%;
            overflow: hidden;

            .card {
                height: 100%;
            }
        }
    }
}
