@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    background: var(--secondary-30);
    padding: 12px;
    width: 600px;
    max-width: 100%;

    height: fit-content;

    .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: 8px;
        border-radius: 50%;
        width: 48px;
        height: 48px;

        &.success {
            background-color: var(--seafoam-green-30);
            padding-top: 3px;
            padding-left: 3px;
        }

        &.fail {
            background-color: var(--alert-30);
        }

        .successIcon {
            stroke: var(--seafoam-green);
            width: 32px;
            height: 20px;
        }

        .failIcon {
            width: 18px;
            height: 18px;
        }
    }

    .messageTitle {
        color: var(--executive-blues-80);
        font-weight: 600;
        font-size: 16px;
        text-align: center;
    }

    .message {
        margin-bottom: 12px;
        color: var(--secondary-80);
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        text-align: center;
    }

    &.dark {
        background: var(--executive-blues-100);

        .messageTitle {
            color: var(--white);
        }

        .message {
            color: var(--white);
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        margin-top: 20px;
        border: 1px solid var(--secondary-50);
        padding: 24px;

        .iconWrapper {
            width: 84px;
            height: 84px;

            .successIcon {
                width: 42px;
                height: 30px;
            }

            .failIcon {
                width: 36px;
                height: 36px;
            }
        }

        .messageTitle {
            font-size: 20px;
        }

        .message {
            margin-bottom: 24px;
            font-size: 14px;
        }

        &.dark {
            border: 1px solid var(--executive-blues-80);
        }
    }
}
