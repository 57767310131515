@import "../../../styles/breakpoints.scss";

.root {
    .convenienceMap {
        // -14px Height for maps so we don't cut off google water-marks
        height: calc(100% - 14px);
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .convenienceMap {
            height: 100%;
        }
    }
}
