@import "../../../styles/breakpoints.scss";

.root {
    position: absolute;
    top: 8px;
    right: 8px;
    border: 1px solid var(--secondary-50);
    border-radius: 8px;
    background-color: white;

    width: 52px;

    &.activeRoot {
        border: 1px solid var(--seafoam-green);
        border-radius: 8px 8px 0px 0px;
    }

    .expandedPanel {
        position: absolute;
        // Position 1 px off to account to line up border for mobile
        top: calc(100% - 1px);
        right: -1px;
        border: 1px solid var(--seafoam-green);

        border-radius: 8px 0px 8px 8px;

        background-color: var(--white);
        padding: 8px;
        width: max-content;

        .legendItem {
            display: flex;
            align-items: center;
            gap: 8px;

            color: var(--executive-blues-80);
            font-weight: 600;
            font-size: 10px;
            line-height: 20px;
            letter-spacing: 1px;
            text-transform: uppercase;

            // Give all children divs the legend color dot
            div {
                border-radius: 50%;
                width: 12px;
                height: 12px;

                &.moveInReady {
                    background-color: var(--seafoam-green);
                }

                &.midConstruction {
                    background-color: var(--purple);
                }

                &.earlyConstruction {
                    background-color: var(--eh-orange);
                }

                &.availableLot {
                    background-color: var(--yellow);
                }
            }
        }
    }

    .expandButton {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        padding: 8px;
        width: 100%;

        height: 42px;

        color: var(--executive-blues-80);
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 2px;
        text-transform: uppercase;

        &.activeButton {
            background-color: var(--seafoam-green-15);
        }

        .mapLegendIcon {
            width: 20px;
            height: 20px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        top: auto;
        right: auto;
        right: 60px; // Position to the side of the google zoom controls
        bottom: 24px; // Position above google terms bar

        width: 180px;

        &.activeRoot {
            border-radius: 8px;
        }

        .expandedPanel {
            position: relative;
            border: none;
            background-color: transparent;
        }

        .expandButton {
            height: fit-content;

            &.activeButton {
                border-top: 1px solid var(--seafoam-green);
            }
        }
    }
}
