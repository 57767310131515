.root {
    .detailsBlock {
        height: calc(100svh - var(--header-height) - 180px);

        &.fullScreen {
            // If full screen take up more space and have smaller margin since we don't have a sticky footer
            margin-bottom: 20px;
            height: calc(100svh - var(--header-height) - 120px);
        }
    }

    .detailsBlockButtonsWrapper {
        display: flex;
        gap: 8px;

        .baseButton {
            flex: 1;
        }
    }

    .mobileStickyFooterBottom {
        display: flex;
        gap: 4px;

        a {
            flex: 1;
        }
    }
}
