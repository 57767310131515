:root {
    // Font Families
    --montserrat: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
    --merriweather: Merriweather, Georgia, Times New Roman, Times, serif;

    --font-weight-xbold: 800;
    --font-weight-bold: 700;
    --font-weight-heavy: 600;
    --font-weight-normal: 500;
    --font-weight-light: 400;
    --font-weight-lighter: 300;

    --font-size-heading-xxl: 2.5rem; // 40px
    --font-size-heading-xl: 1.875rem; // 30px
    --font-size-heading-l: 1.5rem; // 24px
    --font-size-heading-m: 1.25rem; // 20px
    --font-size-heading-s: 1.125rem; // 18px

    --font-size-xxxl: 1.75rem; // 28px
    --font-size-xxl: 1.625rem; // 26px
    --font-size-xl: 1.5rem; // 24px
    --font-size-l: 1.375rem; // 22px
    --font-size-m: 1.25rem; // 20px
    --font-size-s: 1.125rem; // 18px
    --font-size-xs: 1rem; // 16px
    --font-size-xxs: 0.875rem; // 14px
    --font-size-xxxs: 0.75rem; // 12px
    --font-size-xxxxs: 0.625rem; // 10px
    --font-size-xxxxxs: 0.5rem; // 8px
}

//#region Merriweather
@font-face {
    font-style: normal;
    font-weight: 300;
    src: url("./Merriweather/Merriweather-Light.ttf") format("truetype");
    font-family: "Merriweather";
}

@font-face {
    font-style: italic;
    font-weight: 300;
    src: url("./Merriweather/Merriweather-LightItalic.ttf") format("truetype");
    font-family: "Merriweather";
}

@font-face {
    font-style: normal;
    font-weight: 400;
    src: url("./Merriweather/Merriweather-Regular.ttf") format("truetype");
    font-family: "Merriweather";
}

@font-face {
    font-style: italic;
    font-weight: 400;
    src: url("./Merriweather/Merriweather-RegularItalic.ttf") format("truetype");
    font-family: "Merriweather";
}

@font-face {
    font-style: normal;
    font-weight: 700;
    src: url("./Merriweather/Merriweather-Bold.ttf") format("truetype");
    font-family: "Merriweather";
}

@font-face {
    font-style: italic;
    font-weight: 700;
    src: url("./Merriweather/Merriweather-BoldItalic.ttf") format("truetype");
    font-family: "Merriweather";
}

@font-face {
    font-style: normal;
    font-weight: 900;
    src: url("./Merriweather/Merriweather-Black.ttf") format("truetype");
    font-family: "Merriweather";
}

@font-face {
    font-style: italic;
    font-weight: 900;
    src: url("./Merriweather/Merriweather-BlackItalic.ttf") format("truetype");
    font-family: "Merriweather";
}
//#endregion

//#region Montserrat
@font-face {
    font-style: normal;
    font-weight: 100;
    src: url("./Montserrat/Montserrat-Thin.ttf") format("truetype");
    font-family: "Montserrat";
}

@font-face {
    font-style: italic;
    font-weight: 100;
    src: url("./Montserrat/Montserrat-ThinItalic.ttf") format("truetype");
    font-family: "Montserrat";
}

@font-face {
    font-style: normal;
    font-weight: 200;
    src: url("./Montserrat/Montserrat-ExtraLight.ttf") format("truetype");
    font-family: "Montserrat";
}

@font-face {
    font-style: italic;
    font-weight: 200;
    src: url("./Montserrat/Montserrat-ExtraLightItalic.ttf") format("truetype");
    font-family: "Montserrat";
}

@font-face {
    font-style: normal;
    font-weight: 300;
    src: url("./Montserrat/Montserrat-Light.ttf") format("truetype");
    font-family: "Montserrat";
}

@font-face {
    font-style: italic;
    font-weight: 300;
    src: url("./Montserrat/Montserrat-LightItalic.ttf") format("truetype");
    font-family: "Montserrat";
}

@font-face {
    font-style: normal;
    font-weight: 400;
    src: url("./Montserrat/Montserrat-Regular.ttf") format("truetype");
    font-family: "Montserrat";
}

@font-face {
    font-style: italic;
    font-weight: 400;
    src: url("./Montserrat/Montserrat-RegularItalic.ttf") format("truetype");
    font-family: "Montserrat";
}

@font-face {
    font-style: normal;
    font-weight: 500;
    src: url("./Montserrat/Montserrat-Medium.ttf") format("truetype");
    font-family: "Montserrat";
}

@font-face {
    font-style: italic;
    font-weight: 500;
    src: url("./Montserrat/Montserrat-MediumItalic.ttf") format("truetype");
    font-family: "Montserrat";
}

@font-face {
    font-style: normal;
    font-weight: 600;
    src: url("./Montserrat/Montserrat-SemiBold.ttf") format("truetype");
    font-family: "Montserrat";
}

@font-face {
    font-style: italic;
    font-weight: 600;
    src: url("./Montserrat/Montserrat-SemiBoldItalic.ttf") format("truetype");
    font-family: "Montserrat";
}

@font-face {
    font-style: normal;
    font-weight: 700;
    src: url("./Montserrat/Montserrat-Bold.ttf") format("truetype");
    font-family: "Montserrat";
}

@font-face {
    font-style: italic;
    font-weight: 700;
    src: url("./Montserrat/Montserrat-BoldItalic.ttf") format("truetype");
    font-family: "Montserrat";
}

@font-face {
    font-style: normal;
    font-weight: 800;
    src: url("./Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
    font-family: "Montserrat";
}

@font-face {
    font-style: italic;
    font-weight: 800;
    src: url("./Montserrat/Montserrat-ExtraBoldItalic.ttf") format("truetype");
    font-family: "Montserrat";
}

@font-face {
    font-style: normal;
    font-weight: 900;
    src: url("./Montserrat/Montserrat-Black.ttf") format("truetype");
    font-family: "Montserrat";
}

@font-face {
    font-style: italic;
    font-weight: 900;
    src: url("./Montserrat/Montserrat-BlackItalic.ttf") format("truetype");
    font-family: "Montserrat";
}
//#endregion Montserrat
