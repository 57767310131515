@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;

    .switchWrapper {
        padding: 20px 10px;

        .multiStateSwitch {
            box-shadow: var(--default-box-shadow);
        }
    }

    .entitySearchBlock {
        height: 100%;

        // Add extra 28 for tab navigator height
        $searchBarTop: calc(var(--header-bottom) + 28px);

        .mobileMapWrapper {
            // Subtract 180px to take into account the title and icon carousel so it fits into view all at once with a little space left over
            height: calc(100svh - var(--header-height) - 180px);
        }

        .mobileSearchBarWrapper {
            top: $searchBarTop;
        }

        .mobileListViewSwitch {
            // Search bar height + 53px (Height of search bar) to stick to bottom of search bar
            // Need important since class to override is nested higher
            top: calc($searchBarTop + 53px) !important;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        // Subtract 80px on top of the header height to give extra space to make it easier to fit into view
        height: calc(100svh - var(--header-height) - 80px);

        .entitySearchBlock {
            overflow: hidden;
        }

        .switchWrapper {
            padding: 30px;
        }
    }
}
