@import "../../styles/breakpoints.scss";

.root {
    .stoneGroup {
        display: flex;
        flex-direction: column;
        margin-right: auto;

        margin-left: auto;

        max-width: 874px;

        .stoneImage {
            align-self: center;

            margin-bottom: 20px;
            width: 100%;
            max-width: 500px;
        }
    }

    .upgradesTextWrapper {
        display: flex;
        flex-direction: column;

        .upgradesHeader {
            align-self: center;
            max-width: 874px;
            color: var(--executive-blues-90);
            font-weight: 700;

            font-size: 22px;
            text-align: center;
        }

        .upgradesDisclaimer {
            align-self: center;

            margin-bottom: 20px;
            max-width: 874px;
            color: var(--executive-blues-80);
            font-weight: 400;

            font-size: 16px;
        }
    }
}

@media (min-width: $HORIZONTAL_BREAKPOINT_MEDIUM) {
    .root {
        .stoneGroup {
            display: block;

            .stoneImage {
                width: 427px;
                height: 310px;

                &:nth-child(odd) {
                    margin-right: 10px;
                }

                &:nth-child(even) {
                    margin-left: 10px;
                }
            }
        }
    }
}
