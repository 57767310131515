@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;

    height: fit-content;
    min-height: 300px;

    .mobileTitle {
        padding: 4px;
        width: 100%;

        color: var(--executive-blues-80);
        font-weight: 400;
        font-size: 24px;
        letter-spacing: 2px;
        text-align: center;
        text-transform: uppercase;
    }

    .mobileSubTitle {
        padding: 8px;

        color: var(--secondary-90);
        font-weight: 400;
        font-size: 14px;
        text-align: center;
    }

    .stickyHeader {
        position: sticky;
        // Add 28 pixels for mobile tab navigator
        top: calc(var(--header-bottom) + 28px);
        // Higher than card flags
        z-index: 3;

        transition: var(--header-transition);

        background-color: var(--white);

        .searchBar {
            flex: 1;
        }

        .mobileSearchBarWrapper {
            padding: 4px 8px;
        }

        .ehLogo {
            overflow: visible;
            fill: none;
        }
    }

    .cardContainer {
        padding: 16px;
        width: 100%;
        height: fit-content;
        overflow: hidden;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .stickyHeader {
            top: var(--header-bottom);
        }
    }
}
