@import "../../../styles/breakpoints.scss";

.root {
    position: relative;

    .displayButton {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        border-bottom: 1px solid var(--executive-blues-70);
        border-radius: 0;
        padding: 10px 14px;
        width: 100%;
        height: 36px;

        color: var(--secondary-80);
        font-variant: small-caps;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        text-align: left;

        .chevronIcon {
            width: 10px;
            height: 10px;

            stroke: var(--executive-blues-70);
            stroke-width: 4px;
        }

        &.selected {
            color: var(--white);

            .chevronIcon {
                stroke: var(--white);
            }
        }
    }

    .dropdown {
        position: absolute;
        top: calc(100% - 1px);
        right: 0;
        left: 0;
        z-index: 10;

        border-radius: 0px 0px 5px 5px;
        background: var(--executive-blues-80);
        overflow: hidden;

        .optionButton {
            cursor: pointer;
            padding: 6px 14px;
            width: 100%;

            color: var(--white);
            font-variant: small-caps;
            font-weight: 500;
            font-size: 12px;
            text-align: left;

            &:hover {
                background: var(--seafoam-green-10);
            }

            &.selectedOption {
                border-left: 2px solid var(--seafoam-green);
                background: var(--executive-blues-70);
            }
        }
    }

    &.darkText {
        .displayButton {
            border-bottom-color: var(--secondary-50);

            color: var(--secondary-70);

            .chevronIcon {
                stroke: var(--secondary-50);
            }

            &.selected {
                color: var(--executive-blues-80);

                .chevronIcon {
                    stroke: var(--executive-blues-80);
                }
            }
        }

        .dropdown {
            background: var(--secondary-30);

            .optionButton {
                color: var(--executive-blues-80);

                &.selectedOption {
                    background: var(--secondary-50);
                }
            }
        }

        .invalidMessage {
            color: var(--alert);
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        .displayButton {
            padding: 17px 14px;
            height: 50px;
        }
    }
}
