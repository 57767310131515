.root {
    $color: var(--white);
    position: relative;
    transition: all 0.3s ease-in-out;

    cursor: pointer;
    width: 30px;
    height: 30px;

    &::before {
        position: absolute;
        top: 5.5px;
        right: 7px;

        transform: rotate(-45deg);
        transform-origin: right top;
        transition: all 0.3s ease-in-out;
        background-color: $color;

        width: 0;
        height: 2px;
        content: "";
    }

    &::after {
        position: absolute;
        right: 7px;
        bottom: 5px;

        transform: rotate(45deg);
        transform-origin: right bottom;
        transition: all 0.3s ease-in-out;
        background-color: $color;

        width: 12px;
        height: 2px;
        content: "";
    }

    .searchIcon {
        display: block;
        position: absolute;
        top: 4px;
        left: 4px;

        transition: all 0.3s ease-in-out;
        border-width: 2px;
        border-style: solid;
        border-color: $color;

        border-radius: 50%;

        width: 15px;
        height: 15px;
    }

    &.dark {
        $color: var(--executive-blues-80);

        &::before {
            background-color: $color;
        }

        &::after {
            background-color: $color;
        }

        .searchIcon {
            border-color: $color;
        }
    }

    &.active {
        &::before,
        &::after {
            width: 24px;
        }

        .searchIcon {
            border-color: transparent;
            width: 0;
            height: 0;
        }
    }
}
