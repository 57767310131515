.root {
    .warrantyRequestsWrapper {
        position: relative;

        width: 100%;

        .warrantyRequests {
            margin-top: 16px;
            width: 100%;
            min-height: 60px;

            resize: vertical;

            font-variant: small-caps;
            font-weight: 500;
            font-size: 12px;
        }
    }

    .fileInput {
        align-self: start;

        margin-top: 16px;
    }
}
