@import "../../../../styles/breakpoints.scss";

$expandedPanelHeight: 330px;

.root {
    top: 0;
    height: var(--header-height);

    &.panelOpen {
        &::after {
            position: relative;
            // Behind background
            z-index: -2;
            backdrop-filter: blur(15px);
            width: 100%;
            // - Header height to not cause page scroll
            min-height: calc(100svh - var(--header-height));
            content: "";
        }
    }

    .navbarContainer {
        display: flex;
        align-items: center;

        gap: 8px;
        padding: 0px 24px;
        width: 100%;

        height: var(--header-height);
        min-height: var(--header-height);

        .navbarLeft {
            display: flex;

            flex: 1; // Fixed width and does not grow or shrink
            gap: 10px;

            .ehLogo {
                fill: var(--white);
                width: 35px;
                height: 35px;
            }

            .ehWordmarkLogo {
                fill: var(--white);
                width: 150px;
                height: 35px;
            }
        }

        .navbarCenter {
            flex: 1; // Allow it to grow and shrink as needed
            height: 100%;
        }

        .navbarRight {
            display: flex;

            flex: 1; // This will make the third div take the remaining space
            justify-content: flex-end;
            align-items: center;
            gap: 18px;

            .phoneIconWrapper {
                padding: 8px;
                width: 40px;
                height: 40px;
                fill: var(--white);
            }
        }
    }

    .background {
        display: flex;
        position: absolute;
        top: 0;
        justify-content: center;
        // Behind navbar content
        z-index: -1;

        transition: var(--header-transition);
        background-color: var(--executive-blues-100);
        width: 100%;
        height: 0px;
        overflow: hidden;

        .expandedPanel {
            margin-top: var(--header-height);

            &.hidden {
                display: none;
            }
        }

        &.solidBackground {
            height: var(--header-height);
        }

        &.expanded {
            height: calc($expandedPanelHeight + var(--header-height));
        }
    }

    &.white {
        .navbarContainer {
            .navbarLeft {
                .ehLogo {
                    fill: var(--executive-blues-80);
                }

                .ehWordmarkLogo {
                    fill: var(--executive-blues-80);
                }
            }

            .navbarRight {
                .phoneIconWrapper {
                    fill: var(--executive-blues-80);
                }
            }
        }

        .background {
            background-color: var(--white);
        }
    }
}
