@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;

    .iconCarousel {
        position: sticky;
        top: var(--header-bottom);
        z-index: 4; // Above search bar

        transition: var(--header-transition);
        background-color: var(--white);
    }

    .ehLogo {
        fill: var(--executive-blues-80);
        width: 32px;
        height: 32px;
    }

    .imageTab {
        height: 32px;
        object-fit: contain;
    }

    .searchBlock {
        height: 100%;

        // Add an extra 64px (Height of Icon Carousel) to stick to bottom of icon carousel
        $searchBarTop: calc(var(--header-bottom) + 64px);

        .mobileMapWrapper {
            // Subtract 180px to take into account the title and icon carousel so it fits into view all at once with a little space left over
            height: calc(100svh - var(--header-height) - 180px);
        }

        .mobileSearchBarWrapper {
            top: $searchBarTop;
        }

        .mobileListViewSwitch {
            // Search bar height + 53px (Height of search bar) to stick to bottom of search bar
            // Need important since class to override is nested higher
            top: calc($searchBarTop + 53px) !important;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        // Subtract 80px on top of the header height to give extra space to make it easier to fit into view
        height: calc(100svh - var(--header-height) - 80px);

        .iconCarousel {
            top: var(--header-bottom);
        }

        .searchBlock {
            overflow: hidden;
        }
    }
}
