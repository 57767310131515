@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 16px;
    padding-bottom: 40px;
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        flex-direction: row;
        gap: 0;
    }
}
