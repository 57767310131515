.root {
    position: absolute;
    top: 100%;
    left: 14px;
    color: var(--seafoam-green);
    font-variant: small-caps;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
}
