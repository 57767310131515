@use "../../../styles/mixins.scss" as Mixins;
@import "../../inputs/search-input-with-filter/search-input-with-filter.variables.scss";

.root {
    display: flex;

    border: 1px solid var(--seafoam-green);
    border-radius: 15px;
    background-color: var(--seafoam-green-10);

    height: calc($SEARCH-BAR-HEIGHT - 6px);

    .leftSideWrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        border-right: 1px solid var(--seafoam-green);
        padding-right: 10px;

        padding-left: 16px;

        .filterIcon {
            width: 18px;
            height: 18px;
            stroke: var(--executive-blues-80);
        }

        .nameWrapper {
            max-width: 80px;

            color: var(--executive-blues-80);
            font-variant: small-caps;
            font-weight: 500;
            font-size: 14px;

            @include Mixins.ellipsis;
        }
    }

    .rightSideWrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-right: 12px;

        padding-left: 6px;

        .xIcon {
            stroke: var(--seafoam-green);
            width: 12px;
            height: 12px;
        }
    }
}
