.root {
    $margin-horizontal: 20px;
    margin: 0px $margin-horizontal;
    width: calc(100% - ($margin-horizontal * 2));
    height: 60px;

    .thumbWrapper {
        cursor: grab;
        // Add margin left to center thumb circle with start of track range
        margin-left: -$margin-horizontal;
        // Negate default orange outline when clicked
        outline: none;

        .labelText {
            color: var(--executive-blues-80);
            font-variant: small-caps;
            font-weight: 500;
            font-size: 12px;
            text-align: center;
        }

        .outerThumb {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: var(--seafoam-green-30);
            width: 40px;
            height: 40px;

            .innerThumb {
                filter: drop-shadow(0px 1px 3px var(--black-15)) drop-shadow(0px 1px 2px var(--black-30));
                border-radius: 50%;
                background-color: var(--seafoam-green);
                width: 20px;
                height: 20px;
            }
        }

        &:last-child {
            // Add margin left to the last center thumb circle with end of track range
            margin-left: $margin-horizontal;
        }
    }

    .track {
        top: 33px;
        height: 5px;

        &.outOfBoundsTrack {
            background-color: var(--secondary-50);
        }

        &.inBoundsTrack {
            // Add z-index so it is above the right side of the out of bounds track
            z-index: 2;

            background-color: var(--seafoam-green);
        }
    }
}
