.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 10px;
    padding: 24px;

    width: 100%;

    .iconWrapper {
        display: flex;
        border-radius: 50%;
        background-color: var(--secondary-40);
        padding: 12px;

        .noSearchResultsIcon {
            width: 32px;
            height: 32px;
        }
    }

    .noSearchText {
        color: var(--executive-blues-80);
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 2px;
        text-transform: uppercase;
    }

    .clearButton {
        display: flex;
        gap: 8px;
        padding: 8px 12px;

        font-variant: small-caps;

        .xWrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            border: 1px solid var(--executive-blues-80);
            border-radius: 50%;

            width: 16px;
            height: 16px;

            .xIcon {
                width: 8px;
                height: 8px;
            }
        }
    }
}
