.root {
    // Set height to 50 so bottom of marker point is bottom of the whole div
    height: 50px;

    .marker {
        $markerSize: 40px;

        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(-45deg);

        margin-bottom: 3px;

        border-width: 4px;
        border-style: solid;
        border-radius: 50% 50% 50% 0;

        width: $markerSize;
        height: $markerSize;
    }
}
