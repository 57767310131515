.root {
    display: flex;
    flex-wrap: wrap;

    gap: 8px;

    .selectorButton {
        // Center items
        display: flex;

        flex-grow: 1;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid var(--secondary-50);

        border-radius: 8px;
        padding: 15px 16px;

        color: var(--executive-blues-80);
        font-variant: small-caps;
        font-weight: 500;
        font-size: 12px;

        &.activeButton {
            border: 1px solid var(--seafoam-green);
            background-color: var(--seafoam-green-30);
        }

        &.disabledButton {
            cursor: not-allowed;
            border: 1px solid var(--secondary-40);
            background-color: var(--secondary-40);
        }
    }
}
