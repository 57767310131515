@use "../../../../styles/mixins.scss" as Mixins;
@import "../../../../styles/breakpoints.scss";

.root {
    display: flex;
    position: relative;
    flex-direction: column;

    justify-content: flex-end;
    box-shadow: var(--default-box-shadow);

    border-radius: 20px;

    max-width: 1200px;
    height: 65svh;
    max-height: 400px;
    overflow: hidden;

    .backgroundImage {
        width: 100%;
        height: 100%;

        @include Mixins.fullSizeAbsolute;
        z-index: -1;

        object-fit: cover;
    }

    .contentWrapper {
        display: flex;
        flex-direction: column;

        background: linear-gradient(180deg, transparent 0%, var(--executive-blues-100) 100%);
        padding-top: 100px;
        padding-right: 24px;
        padding-bottom: 20px;
        padding-left: 24px;

        .slideTitle {
            color: var(--white);
            font-weight: 800;
            font-size: 20px;
            text-transform: uppercase;
        }

        .slideSubtitle {
            color: var(--white);
            font-variant: small-caps;
            font-weight: 500;
            font-size: 10px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        max-height: 600px;

        .contentWrapper {
            padding-right: 140px;

            .slideTitle {
                font-size: 50px;
            }

            .slideSubtitle {
                font-size: 18px;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        max-height: 800px;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE) {
    .root {
        min-height: 550px;
    }
}
