@import "../../../styles/breakpoints.scss";

.root {
    margin: 0 auto;
    padding-right: 20px;
    padding-left: 20px;
    max-width: 1000px;
    text-align: center;

    .sectionTitle {
        margin-bottom: 5px;
        color: var(--executive-blues-80);

        font-weight: 300;
        font-size: 28px;
        line-height: 1;
        letter-spacing: 4px;
        text-transform: uppercase;
    }

    .subtitle {
        color: var(--secondary-80);
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        padding-right: 30px;
        padding-left: 30px;

        .sectionTitle {
            margin-bottom: 15px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .sectionTitle {
            font-size: 50px;
            line-height: 1;
        }
    }
}
