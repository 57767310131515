@import "../../../styles/breakpoints.scss";

.root {
    .content {
        display: flex;
        flex-direction: column;

        justify-content: flex-end;
        align-items: flex-start;

        gap: 16px;

        height: 100%;

        .titleBlock {
            display: flex;
            flex-direction: column;

            justify-content: flex-end;
            align-items: flex-start;

            gap: 0px;

            .mainBlockTitle {
                display: flex;
                flex-direction: column;

                justify-content: flex-end;
                align-items: flex-start;

                color: var(--white);
                font-weight: 300;
                font-size: 30px;
                line-height: 100%;
                letter-spacing: 8px;
                text-align: center;
                text-transform: uppercase;

                span {
                    display: flex;
                    position: relative;
                    flex-direction: row;

                    &.registered {
                        &::after {
                            display: flex;
                            top: 0px;
                            right: 0px;

                            content: "®";

                            color: var(--white);
                            font-style: normal;
                            font-variant: small-caps;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }
                }
            }

            .subtext {
                padding-left: 3px;

                color: var(--white);
                font-style: normal;
                font-variant: small-caps;
                font-weight: 400;
                font-size: 20px;
                line-height: 100%;
                line-height: normal;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-X-SMALL) {
    .root {
        .content {
            .titleBlock {
                .mainBlockTitle {
                    font-size: 40px;

                    span.registered {
                        &::after {
                            font-size: 20px;
                            line-height: 28px;
                        }
                    }
                }

                .subtext {
                    padding-left: 4px;
                }
            }
        }
    }
}
@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        .content {
            .titleBlock {
                .mainBlockTitle {
                    font-size: 40px;

                    span.registered {
                        &::after {
                            font-size: 22px;
                            line-height: 28px;
                        }
                    }
                }

                .subtext {
                    padding-left: 4px;
                    font-size: 26px;
                }
            }
        }
    }
}
@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .content {
            .titleBlock {
                .mainBlockTitle {
                    font-size: 72px;

                    span.registered {
                        &::after {
                            font-size: 32px;
                            line-height: 40px;
                        }
                    }
                }

                .subtext {
                    padding-left: 8px;
                    font-size: 32px;
                }
            }
        }
    }
}
