.root {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100svh;

    picture img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100svw;
        height: 100svh;

        object-fit: cover;
        object-position: center;
    }

    .textContainer {
        display: flex;
        flex-direction: column;
        align-items: center;

        // Position the text container above the image
        z-index: 1;
        padding: 24px;

        .smallText {
            margin-top: 0;

            color: var(--white);
            font-weight: 300;

            font-size: 24px;
            letter-spacing: 4px;
            text-align: center;
            text-transform: uppercase;
        }
    }
}
