@import "../../../styles/breakpoints.scss";

.root {
    position: relative;
    transition:
        height 0.6s cubic-bezier(0.25, 0.8, 0.25, 1),
        margin 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
    margin-right: auto;
    margin-left: auto;
    max-width: 1150px;

    &.active {
        .imageDiv {
            transform: translateX(0px);
            opacity: 1;
        }

        .card {
            transform: translateY(0px);
            opacity: 1;
        }
    }

    &.left {
        .image {
            right: auto;
            left: 0;
            margin-right: auto;
        }
    }

    &.right {
        .imageDiv {
            right: 0;
            left: auto;
            margin-left: auto;
        }
    }

    .imageDiv {
        position: relative;
        transform: translateX(-10px);
        opacity: 0;
        transition:
            opacity 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0.3s,
            transform 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0.3s,
            height 0.6s cubic-bezier(0.25, 0.8, 0.25, 1),
            width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
        width: 100%;
        height: 300px;
        overflow: hidden;

        .image {
            width: 100%;
        }
    }

    .card {
        position: relative;
        top: -150px;
        transform: translateY(10px);
        opacity: 0;
        transition:
            opacity 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0.8s,
            transform 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0.8s,
            padding 0.6s ubic-bezier(0.25, 0.8, 0.25, 1),
            position 0.6s cubic-bezier(0.25, 0.8, 0.25, 1),
            top 0.6s cubic-bezier(0.25, 0.8, 0.25, 1),
            width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
        margin-right: auto;
        margin-bottom: -150px;
        margin-left: auto;
        padding: 25px 20px;
        width: 90%;
        max-width: 335px;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-X-SMALL) {
    .root {
        .card {
            top: -200px;
            margin-bottom: -200px;
            width: 80%;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        .imageDiv {
            height: 360px;
        }

        .card {
            width: 475px;
            max-width: 100%;
            min-height: 250px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        width: 80%;

        .imageDiv {
            height: 440px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE) {
    .root {
        height: 580px;

        .imageDiv {
            width: 58%;
            height: 500px;
        }

        .card {
            position: absolute;
            top: 80px;
            padding: 65px 50px;
            width: 60%;
            min-height: 500px;
        }

        &.left {
            .card {
                right: 0;
                left: auto;
            }
        }

        &.right {
            .card {
                right: auto;
                left: 0;
            }
        }
    }
}
