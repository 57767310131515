@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    padding: 16px 28px; // 16px Vertical 28px Horizontal

    .labelsWrapper {
        letter-spacing: 4px;
        text-align: center;
        text-transform: uppercase;

        .bookTourText {
            color: var(--executive-blues-80);
            font-weight: 300;
            font-size: 30px;
            line-height: 32px;
        }

        .availabilityText {
            color: var(--secondary-70);
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
        }
    }

    .inputsWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        width: 100%;
        max-width: 1400px;

        .inputClass {
            width: 100%;
        }
    }

    .genericButton {
        margin-top: 10px;

        border: 1px solid var(--secondary-50);
        background-color: transparent;

        padding: 12px;

        color: var(--executive-blues-80);
        font-size: 14px;

        &.defaultPointer {
            cursor: default;
        }

        .chevronIcon {
            stroke: var(--executive-blues-80);
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        gap: 20px;
        padding-top: 23px;
        padding-bottom: 23px;

        .labelsWrapper {
            .bookTourText {
                font-size: 50px;
                line-height: 42px; /* 84% */
            }

            .availabilityText {
                font-weight: 500;
                font-size: 20px;
                line-height: 42px;
            }
        }

        .inputsWrapper {
            display: flex;
            gap: 12px;
        }

        .genericButton {
            margin-top: 0;
        }
    }
}
