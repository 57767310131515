@import "../../../styles/breakpoints.scss";

.root {
    display: flex;

    position: relative;
    flex-direction: column;
    justify-content: flex-end;

    cursor: pointer;
    min-width: 250px;
    max-width: 300px;

    &:focus-visible {
        outline: none;
    }

    .contentWrapper {
        display: flex;

        position: absolute;
        bottom: 0;
        left: 0;
        flex-direction: column;

        gap: 8px;
        margin-right: 30px;
        margin-bottom: 26px;

        margin-left: 30px;
        max-width: 390px;

        color: var(--white);

        .salesText {
            width: fit-content;

            $horizontalPadding: 8px;
            $verticalPadding: 4px;

            border-radius: 5px;

            background-color: var(--executive-blues-100);
            padding: $verticalPadding $horizontalPadding;

            font-weight: 600;
            font-size: 12px;
            line-height: 24px;
            text-transform: uppercase;
        }

        .cardTitle {
            font-weight: 800;
            font-size: 28px;
            line-height: 40px;
            text-transform: uppercase;
        }

        .cardButton {
            font-size: 14px;
        }
    }
}
