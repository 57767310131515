.root {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    width: 100%;

    .stat {
        display: flex;
        flex: 1;

        justify-content: center;

        color: var(--executive-blues-80);
        font-variant: small-caps;
        line-height: 24px;
    }

    .icon {
        height: 16px;
    }

    > :not(:last-child) {
        border-right: 1px solid var(--secondary-50);
    }
}
