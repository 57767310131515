.root {
    .container {
        display: flex;
        flex-direction: column;

        align-items: center;
        margin-right: auto;

        margin-left: auto;

        padding: 24px;
        max-width: 900px;

        .topOfPageMarkup {
            color: var(--executive-blues-90);
            font-weight: 400;
            font-size: 16px;
        }

        .airtableEmbed {
            border: 1px solid var(--secondary-50);
            width: 100%;
            height: 1000px;
        }
    }
}
