@import "../../../../styles/breakpoints.scss";

.root {
    top: calc(var(--header-bottom) - var(--header-height));
    transition: top 600ms ease-out;

    .navbarContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 0px 10px;
        width: 100%;
        height: var(--header-height);

        .navbarLeft {
            display: flex;
            gap: 10px;

            transition:
                opacity 0.4s ease-in-out,
                visibility ease 0.4s;

            .ehLogo {
                fill: var(--white);
                width: 24px;
                height: 24px;
            }

            .ehWordmarkLogo {
                fill: var(--white);
                width: 120px;
                height: 24px;
            }

            &.hidden {
                visibility: hidden;
                opacity: 0;
            }
        }

        .navbarRight {
            display: flex;
            gap: 6px;

            .iconButton {
                transition:
                    opacity 0.4s ease-in-out,
                    visibility ease 0.4s;
                outline: none;
                padding: 0;
                width: 28px;
                height: 28px;

                &.hidden {
                    visibility: hidden;
                    opacity: 0;
                }

                .phoneIcon {
                    stroke-width: 1.3px;
                    stroke: var(--white);
                }

                svg {
                    width: 20px;
                    height: 20px;
                }
            }

            .hamburgerMenu {
                margin-left: 4px;
            }
        }
    }

    .background {
        position: absolute;
        // Behind navbar container
        z-index: -1;

        transition: var(--header-transition);
        background-color: var(--executive-blues-100);
        width: 100%;
        height: 0px;
        overflow: hidden;

        .openPanel {
            // Need fit content to not always have scroll
            height: fit-content;

            &.hidden {
                display: none;
            }
        }

        &.solidBackground {
            height: var(--header-height);
        }

        &.expanded {
            height: 100svh;
            overflow: auto;

            // Use sticky before at the top of the block to prevent overlap of scroll block and X button.
            // Use linear gradient background to make it look like the scroll fades out
            &::before {
                display: block;
                position: sticky;
                top: 0;
                // Above inputs
                z-index: 2;

                background: linear-gradient(0deg, transparent 0%, var(--executive-blues-100) 40%);
                width: 100svw;
                // Use height + 8px padding to have enough room for the fade
                height: calc(var(--header-height) + 8px);
                content: "";
            }
        }
    }

    .listContainer {
        display: flex;
        flex-direction: column;
        align-self: flex-start;

        padding: 0px 20px;

        .marketSelectorsWrapper {
            display: flex;
            gap: 8px;

            margin-bottom: 12px;
            width: 100%;

            .marketButton {
                flex: 1;

                &.unselected {
                    border: 1px solid var(--secondary-30);
                    background-color: transparent;
                    color: var(--white);
                }
            }
        }

        .subList {
            padding-left: 0;
            list-style-type: none;

            li {
                a,
                div {
                    height: 100%;

                    color: var(--seafoam-green);
                    font-weight: 600;

                    font-size: 12px;
                    line-height: 30px;
                    letter-spacing: 2px;
                    text-decoration: none;
                    text-transform: uppercase;
                }
            }
        }
    }

    .openPanel {
        transition: all 600ms cubic-bezier(0, 0, 0.58, 1) 100ms;
        height: 800px;
        overflow: hidden;

        &.hidden {
            visibility: hidden;
            transition: all 300ms cubic-bezier(0.25, 0.8, 0.25, 1);
            padding-top: 0;
            padding-bottom: 0;
            height: 0;
        }
    }

    &.white {
        .navbarContainer {
            .navbarLeft {
                .ehLogo {
                    fill: var(--executive-blues-80);
                }

                .ehWordmarkLogo {
                    fill: var(--executive-blues-80);
                }
            }

            .navbarRight {
                .iconButton {
                    .searchIcon {
                        fill: var(--executive-blues-80);
                    }

                    .phoneIcon {
                        stroke: var(--executive-blues-80);
                    }
                }
            }
        }

        .background {
            background-color: var(--white);

            &.expanded {
                &::before {
                    background: linear-gradient(0deg, transparent 0%, var(--white) 40%);
                }
            }
        }

        .listContainer {
            .marketSelectorsWrapper {
                .marketButton {
                    &.unselected {
                        border: 1px solid var(--secondary-90);
                        color: var(--secondary-90);
                    }
                }
            }

            .subList {
                li {
                    a,
                    div {
                        color: var(--secondary-90);
                    }
                }
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        .navbarContainer {
            padding: 0px 20px;
        }
    }
}
