@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    gap: 5px;
    border: 1px solid var(--secondary-50);

    border-radius: 30px;
    background: var(--white);
    padding: 5px;
    width: 100%;

    .selectorButton {
        flex: 1;

        border-radius: 68px;

        $paddingHorizontal: 16px;
        $paddingVertical: 10px;

        cursor: pointer;
        padding: $paddingVertical $paddingHorizontal;

        color: var(--executive-blues-80);
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;

        &:hover {
            background-color: var(--secondary-40);
        }

        &.activeButton {
            cursor: default;
            background-color: var(--executive-blues-80);
            color: var(--white);
        }

        &.disabled {
            cursor: not-allowed;
            color: var(--secondary-70);
        }
    }

    &.separated {
        gap: 12px;
        border: none;
        background: none;

        .selectorButton {
            border: 1px solid var(--secondary-50);
            padding: 8px;
            min-width: 150px;
            max-width: 150px;

            color: var(--executive-blues-80);
            font-weight: 500;
            font-size: 10px;
            line-height: normal;

            &:hover {
                background-color: transparent;
            }

            &.activeButton {
                cursor: default;
                border-color: var(--executive-blues-80);
                background-color: var(--executive-blues-80);
                color: var(--white);
            }
        }

        &.dark {
            .selectorButton {
                border-color: var(--secondary-70);

                color: var(--white);

                &:hover {
                    background-color: transparent;
                }

                &.activeButton {
                    border-color: var(--seafoam-green);
                    background-color: var(--executive-blues-90);
                }
            }
        }
    }

    &.filter {
        $border-radius: 8px;
        gap: 0;
        border: none;
        background: none;
        padding: 0;

        .selectorButton {
            border: 1px solid var(--secondary-50);
            border-radius: 0;
            padding: 11px;
            color: var(--secondary-90);
            font-variant: small-caps;
            font-weight: 500;
            font-size: 12px;

            text-transform: none;

            &:first-child {
                border-top-left-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
            }

            &:last-child {
                border-top-right-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
            }

            &:hover {
                background-color: transparent;
            }

            &.activeButton {
                cursor: default;
                border: 1px solid var(--seafoam-green);
                background-color: var(--seafoam-green-30);
                color: var(--executive-blues-80);
            }

            &.leftBorderless {
                border-left: 0;
            }

            &.rightBorderless {
                border-right: 0;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        .selectorButton {
            font-size: 14px;
            line-height: 20px;
        }
    }
}
