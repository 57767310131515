@import "../../../styles/breakpoints.scss";

.genericIcon {
    width: 24px;
    height: 24px;

    overflow: visible;
}

.pickleBallIcon {
    width: 32px;
    height: 24px;
}

.ehLogo {
    fill: var(--executive-blues-80);
    width: 24px;
    height: 24px;
}

.hollowEhLogo {
    fill: none;
    overflow: visible;
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .genericIcon {
        width: 32px;
        height: 32px;
    }

    .pickleBallIcon {
        width: 42px;
        height: 32px;
    }

    .ehLogo {
        width: 32px;
        height: 32px;
    }
}
