@import "../../../styles/breakpoints.scss";

.root {
    .houseImageWrapper {
        display: flex;
        position: relative;

        .houseImage {
            width: 100%;
        }

        .gradient {
            position: absolute;
            width: 100%;

            &.top {
                top: 0;

                background: linear-gradient(180deg, var(--white) 0%, transparent 100%);
                height: 20%;
            }

            &.bottom {
                bottom: 0;

                background: linear-gradient(180deg, transparent 0%, var(--executive-blues-100) 100%);
                height: 20%;
            }
        }
    }

    .footerContentWrapper {
        width: 100%;

        .footerContent {
            display: flex;
            flex-direction: column;
            align-items: center;

            margin-right: 25px;
            margin-left: 25px;

            a,
            div {
                font-variant: small-caps;
                font-weight: 500;
                font-size: 14px;
                text-decoration: none;
                text-wrap: nowrap;
            }

            .ehLogo {
                margin-top: 16px;

                width: 35px;
                height: 35px;

                fill: var(--executive-blues-80);
            }

            .separator {
                margin-top: 16px;
                margin-bottom: 16px;

                background-color: var(--secondary-50);

                width: 100%;
                height: 1px;
            }

            .footerLinks {
                display: grid;
                grid-template-rows: repeat(4, auto); /* 4 rows with automatic height */
                grid-template-columns: repeat(2, 1fr); /* 2 columns with equal width */
                column-gap: 25px;
                row-gap: 25px;

                padding-right: 25px;
                padding-left: 25px;

                width: 100%;

                text-align: center;

                a,
                div {
                    cursor: pointer;
                    color: var(--executive-blues-80);
                }
            }

            .legalFootnote {
                display: flex;
                flex-direction: row;

                gap: 6px;

                margin-bottom: 16px;

                color: var(--executive-blues-70);

                font-variant: small-caps;
                font-weight: 500;
                font-size: 14px;

                .link {
                    color: var(--executive-blues-70);
                }
            }
        }

        &.dark {
            background-color: var(--executive-blues-100);

            .ehLogo {
                fill: var(--white);
            }

            .separator {
                background-color: var(--executive-blues-70);
            }

            .footerLinks {
                a,
                div {
                    color: var(--white);
                }
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        .footerContentWrapper {
            .footerContent {
                margin-right: 40px;
                margin-left: 40px;

                .footerLinks {
                    grid-template-rows: repeat(3, auto); /* 3 rows with automatic height */
                    grid-template-columns: repeat(3, 1fr); /* 3 columns with equal width */

                    padding-right: 40px;
                    padding-left: 40px;
                }
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .footerContentWrapper {
            .footerContent {
                margin-right: 80px;
                margin-left: 80px;

                .footerLinks {
                    grid-template-rows: repeat(2, auto); /* 2 rows with automatic height */
                    grid-template-columns: repeat(4, 1fr); /* 4 columns with equal width */

                    padding-right: 80px;
                    padding-left: 80px;
                }
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-DESKTOP) {
    .root {
        .footerContentWrapper {
            .footerContent {
                margin-right: 100px;
                margin-left: 100px;

                .footerLinks {
                    grid-template-rows: repeat(2, auto); /* 2 rows with automatic height */
                    grid-template-columns: repeat(5, 1fr); /* 4 columns with equal width */

                    padding-right: 100px;
                    padding-left: 100px;
                }
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE-DESKTOP) {
    .root {
        .footerContentWrapper {
            .footerContent {
                .ehLogo {
                    width: 52px;
                    height: 52px;
                }
            }
        }
    }
}
