@use "../../../../styles/mixins.scss" as Mixins;
@import "../../../../styles/breakpoints.scss";
@import "../../cards.variables.scss";

.root {
    position: relative;

    $cardTransition: all 0.3s ease-in;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-radius: 8px;

    width: 100%;
    height: $DEFAULT-CARD-HEIGHT;

    // Hides the corner of the images on the card when swiping the carousel
    overflow: hidden;

    .carouselWrapper {
        background-color: var(--white);
        width: 100%;
        $imageHeight: 248px;
        height: $imageHeight;

        $borderRadius: 8px;

        position: absolute;

        transition: $cardTransition;
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;
        overflow: hidden;

        .imageInSlide {
            transition: $cardTransition;
            width: 100%;
            height: $imageHeight;
            object-fit: cover;

            &.scaleDown {
                object-fit: scale-down;
            }
        }

        .imageCarousel {
            height: 100%;

            .carouselSlideNumber {
                opacity: 0;

                transition: $cardTransition;

                background-color: var(--black-25);
            }

            .carouselArrows {
                opacity: 0;

                transition: $cardTransition;

                background-color: var(--black-25);
            }
        }

        .gradientOnCarousel {
            position: absolute;
            bottom: 0;

            background: linear-gradient(180deg, transparent, var(--white) 100%);
            width: 100%;
            height: 50px;
        }
    }

    .top {
        display: flex;

        position: relative;
        flex-direction: column;

        align-items: flex-end;
        // Makes the top div render above the pictures
        z-index: 1;
        width: 100%;

        .infoGroup {
            display: flex;
            gap: 1px;

            transition: $cardTransition;
            width: 100%;
            max-height: 32px; // Used for card transition

            .info {
                display: flex;
                flex: 1;
                flex-direction: row;
                min-width: 0; // This makes the text within this div not make the div grow and instead truncates to ellipsis

                $paddingHorizontal: 12px;
                $paddingVertical: 6px;
                justify-content: center;

                align-items: center;

                gap: 6px;
                backdrop-filter: blur(2px);

                background-color: var(--executive-blues-80-alpha-40);
                padding-top: $paddingVertical;
                padding-right: $paddingHorizontal;
                padding-bottom: $paddingVertical;
                padding-left: $paddingHorizontal;
                color: var(--white);
                font-variant: small-caps;
                font-weight: 500;

                font-size: 12px;

                .topInfoIcon {
                    width: 20px;
                    min-width: 20px; // Prevents the text from shrinking the icon
                    height: 20px;

                    &.shortCard {
                        width: 16px;
                        min-width: 16px;
                        height: 16px;
                    }
                }

                .toEllipsis {
                    @include Mixins.ellipsis;
                }
            }
        }

        .informationButton {
            display: flex;
            justify-content: center;
            align-items: center;

            opacity: 0;
            backdrop-filter: blur(2px);
            transition: opacity 0.3s;

            cursor: pointer;

            margin-top: 8px;
            margin-right: 8px;
            outline: none;

            border-radius: 50%;

            background-color: var(--executive-blues-80-alpha-40);
            width: 32px;
            height: 32px;

            .informationIcon {
                width: 18px;
                height: 18px;
            }
        }
    }

    .bottom {
        position: relative;
        // Makes the bottom div render above the pictures
        z-index: 1;
        margin-right: 8px;
        margin-bottom: 8px;

        margin-left: 8px;

        color: var(--executive-blues-80);

        .preTitleContentWrapper {
            opacity: 1;

            transition: $cardTransition;

            color: var(--white);
        }

        .titleDiv {
            transition: $cardTransition;
            font-variant: small-caps;
            font-weight: 700;
            font-size: 24px;
            line-height: 100%;
        }

        // Used when only one subtitle is displayed
        .subtitleSolo {
            font-weight: 400;
            font-size: 14px;
            text-transform: uppercase;
        }

        // Used when both subtitles are displayed
        .firstSubtitle {
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
        }

        .secondSubtitle {
            font-variant: small-caps;
            font-weight: 300;
            font-size: 10px;
            line-height: 12px;
        }

        .infoGroup {
            display: flex;

            transition: $cardTransition;

            margin-top: 4px;
            max-height: 25px; // Used for card transition

            .infoBlock {
                display: flex;
                flex: 1;

                justify-content: center;
                align-items: center;

                padding: 4px;

                width: max-content;
                font-variant: small-caps;
                font-size: 12px;
                line-height: 100%;

                text-wrap: nowrap;

                &:not(:last-child) {
                    border-right: 1px solid var(--secondary-50);
                }
            }
        }
    }

    &.showArrows {
        .carouselWrapper {
            .imageCarousel {
                .carouselArrows {
                    opacity: 1;
                }
            }
        }

        .top {
            .informationButton {
                opacity: 1;
            }
        }
    }

    &.leftHalfCard {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .carouselWrapper {
            border-top-right-radius: 0;
        }
    }

    &.singleColumnCard {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    &.expandedCarouselView {
        .carouselWrapper {
            height: $DEFAULT-CARD-HEIGHT;

            .imageInSlide {
                height: $DEFAULT-CARD-HEIGHT;
            }

            .gradientOnCarousel {
                height: 100px;
            }

            .imageCarousel {
                .carouselArrows {
                    top: 50%;
                }
            }
        }

        .top {
            .infoGroup {
                opacity: 0;
                max-height: 0;
            }
        }

        .bottom {
            .preTitleContentWrapper {
                opacity: 0;
            }

            .titleDiv {
                font-size: 22px;
                line-height: 24px;
            }

            .infoGroup {
                opacity: 0;
                margin-top: 0;
                max-height: 0;
            }
        }

        &.showArrows {
            .carouselWrapper {
                .imageCarousel {
                    // Only show slide number when expanded and hovered
                    .carouselSlideNumber {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.shortCard {
        $shortImageHeight: 150px;
        height: 200px;

        .carouselWrapper {
            height: $shortImageHeight;

            .imageInSlide {
                height: $shortImageHeight;
            }
        }

        .top {
            .info {
                font-size: 10px;
                $padding-vertical: 4px;
                padding-top: $padding-vertical;
                padding-bottom: $padding-vertical;
            }
        }

        .bottom {
            .titleDiv {
                font-size: 20px;
            }

            .firstSubtitle {
                font-size: 10px;
            }

            .secondSubtitle {
                font-size: 10px;
            }

            .infoGroup {
                margin-top: 6px;

                .infoBlock {
                    font-size: 10px;
                }
            }
        }
    }

    &.darkGradient {
        // Top 20% is transparent to avoid corner clipping, bottom 50% is background color for text background
        background: linear-gradient(transparent 20%, var(--executive-blues-90) 50%);

        .carouselWrapper {
            .gradientOnCarousel {
                background: linear-gradient(180deg, transparent, var(--executive-blues-90) 100%);
            }
        }

        .bottom {
            color: var(--white);

            .infoBlock {
                &:not(:last-child) {
                    border-color: var(--executive-blues-70);
                }
            }
        }
    }
}

.modalWrapper {
    border-radius: 8px;
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        .bottom {
            .titleDiv {
                font-size: 28px;
            }

            // Used when only one subtitle is displayed
            .subtitleSolo {
                font-size: 14px;
            }

            // Used when both subtitles are displayed
            .firstSubtitle {
                font-size: 14px;
                line-height: 16px;
            }

            .secondSubtitle {
                font-size: 12px;
                line-height: 14px;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        width: $DEFAULT-CARD-WIDTH;
    }
}
