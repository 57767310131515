@use "../../../../../styles/mixins.scss" as Mixins;
@import "../../../../../styles/breakpoints.scss";

.root {
    display: flex;

    gap: 20px;
    margin-bottom: 50px;
    width: 100%;
    // Subtract header height and an additional 100px to allow for next block to poke through
    height: calc(100svh - var(--header-height) - 100px);

    &.modal {
        margin: 40px;
        padding-top: 0;
        width: 90svw;
        height: 75svh;
    }

    .leftContent {
        display: flex;
        flex: 2;

        flex-direction: column;
        justify-content: flex-end;

        gap: 24px;

        &.notInModal {
            margin-left: 24px;
        }

        .topContent {
            .preTitle {
                color: var(--secondary-80);
                font-weight: 600;
                font-size: 16px;
                line-height: 150%;
                text-transform: uppercase;
            }

            .title {
                color: var(--executive-blues-80);
                font-weight: 300;
                font-size: 40px;
                line-height: 100%;
                letter-spacing: 4px;
                text-transform: uppercase;
            }

            .description {
                color: var(--secondary-80);
                font-weight: 400;
                font-size: 16px;
                line-height: 125%;
            }

            .divider {
                margin-top: 16px;
                margin-bottom: 8px;

                background-color: var(--secondary-50);

                height: 1px;
            }
        }

        .tabSection {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .tabButton {
                $buttonTransition: 0.3s ease-in-out;
                display: flex;
                align-items: center;
                gap: 0;
                transition: $buttonTransition;

                cursor: pointer;

                font-size: 16px;
                text-align: left;
                text-transform: uppercase;

                &.selected {
                    gap: 12px;

                    color: var(--executive-blues-80);
                    font-weight: 600;
                }

                &.notSelected {
                    color: var(--secondary-70);
                    font-weight: 400;
                }

                .dash {
                    transition: $buttonTransition;

                    background-color: var(--seafoam-green);

                    width: 24px;
                    height: 3px;

                    &.hidden {
                        width: 0;
                    }
                }
            }
        }
    }

    .rightContent {
        position: relative;
        flex: 3;
        // Set max to 100% - min of left side, so it doesn't grow off screen.
        max-width: 100%;

        .tabWrapper {
            @include Mixins.fullSizeAbsolute;

            box-shadow: var(--default-box-shadow);
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;

            overflow: hidden;

            &.hiddenTab {
                visibility: hidden;
            }

            &.rounded {
                border-radius: 8px;
                overflow: hidden;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) and (min-height: $VERTICAL-BREAKPOINT-SMALL) {
    .root {
        gap: 40px;

        .leftContent {
            min-width: 400px;

            &.notInModal {
                margin-left: 32px;
            }

            .topContent {
                .title {
                    padding-bottom: 16px;

                    font-size: 50px;
                }
            }

            .tabSection {
                .tabButton {
                    font-size: 24px;

                    &.selected {
                        gap: 20px;
                    }

                    .dash {
                        width: 40px;
                    }
                }
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE-DESKTOP) and (min-height: $VERTICAL-BREAKPOINT-SMALL) {
    .root {
        .leftContent {
            gap: 40px;

            .topContent {
                .preTitle {
                    font-size: 18px;
                }

                .title {
                    font-size: 60px;
                }

                .description {
                    font-size: 20px;
                }
            }

            .tabSection {
                .tabButton {
                    font-size: 26px;
                }
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-X-LARGE-DESKTOP) and (min-height: $VERTICAL-BREAKPOINT-SMALL) {
    .root {
        .leftContent {
            .topContent {
                .preTitle {
                    font-size: 20px;
                }

                .title {
                    font-size: 72px;
                }

                .description {
                    font-size: 22px;
                }
            }

            .tabSection {
                .tabButton {
                    font-size: 28px;
                }
            }
        }
    }
}
