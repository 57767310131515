@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    gap: 16px;

    background-color: var(--executive-blues-80);

    padding: 16px 28px; // 16px Vertical 28px Horizontal

    .preFooterLabel {
        margin: 0;

        color: var(--white);
        font-weight: 700;
        font-size: 24px;
        text-transform: uppercase;
        text-wrap: nowrap;
    }

    .message {
        margin: 0;

        max-width: 1200px;

        color: var(--white);
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
    }

    .genericButton {
        background-color: var(--executive-blues-70-alpha-50);

        padding: 12px;

        width: 60%;

        color: var(--white);
        text-wrap: nowrap;

        .chevronIcon {
            stroke: var(--white);
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        flex-direction: row;

        gap: 32px;

        .message {
            border-right: 1px solid var(--executive-blues-70);
            border-left: 1px solid var(--executive-blues-70);

            padding-right: 20px;
            padding-left: 20px;

            text-align: start;
        }

        .genericButton {
            width: fit-content;
        }
    }
}
