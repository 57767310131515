@import "../../../styles/breakpoints.scss";

.root {
    padding: 16px;
    width: 100%;
    height: 100%;

    .inner {
        display: flex;
        position: relative;
        flex-direction: column;

        gap: 16px;
        width: 100%;
        height: 100%;
    }

    .svgMask {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        width: 100%;
        height: 100%;
    }

    .backgroundImage {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        flex-direction: row;
        padding: 0;

        .inner {
            flex-direction: row;
        }
    }
}
