@use "../../../styles/mixins.scss" as Mixins;
@import "../../../styles/breakpoints.scss";
@import "../../cards/cards.variables.scss";

.root {
    display: flex;
    flex-direction: column;

    gap: 8px;

    width: 100%;
    height: 100%;

    // Make the property card container scroll only but still have horizontal shadows visible
    overflow-y: scroll;

    overscroll-behavior: contain; /* Prevent SCROLL-CHAINING to parent elements. */

    @include Mixins.noScrollBar;
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        display: grid;
        // Automatically wrap columns with each column min width as default card width
        grid-template-columns: repeat(auto-fill, minmax($DEFAULT-CARD-WIDTH, 1fr));
        // Set each row height to its contents min height
        grid-auto-rows: min-content;
        // Align items at the start to prevent stretching
        align-items: start;

        gap: $DEFAULT-CARD-GAP;

        // Width of one card
        width: $DEFAULT-CARD-WIDTH;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        @include Mixins.customScrollBar;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE) {
    .root {
        // Width of two cards plus the gap or one full floor plan displayed card
        width: calc($DEFAULT-CARD-WIDTH * 2 + $DEFAULT-CARD-GAP);
    }
}
