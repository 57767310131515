@import "../../../styles/breakpoints.scss";

$card-width: 300px;

.root {
    --card-gap: 16px;

    .sectionHeader {
        margin-bottom: 20px;
    }

    .carousel {
        margin: auto;
    }

    @mixin cardCarouselMaxWidth($card-count) {
        max-width: calc($card-width * $card-count + var(--card-gap) * $card-count);
    }

    // Dynamically create a class name for 1 through 5 card widths
    @for $i from 1 through 5 {
        .cardCarouselLayout#{$i} {
            @include cardCarouselMaxWidth($i);
        }
    }

    .cardsWrapperDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: var(--card-gap);
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        --card-gap: 32px;
    }
}
