.informationButton {
    display: flex;

    position: absolute;
    top: 8px;
    right: 8px;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);

    cursor: pointer;
    outline: none;

    border-radius: 50%;

    background-color: var(--executive-blues-80-alpha-40);

    width: 32px;
    height: 32px;

    .informationIcon {
        width: 18px;
        height: 18px;
    }
}

.root {
    display: flex;

    position: absolute;
    top: 8px;
    right: 8px;
    left: 8px;
    // Above carousel slide number
    z-index: 1;
    box-shadow: var(--default-box-shadow);

    border-radius: 8px;
    background-color: var(--white);
    height: fit-content;

    .childrenWrapper {
        padding: 8px 0px 8px 8px;
        width: 100%;
        // Needed to not push xButton off screen
        overflow: hidden;
    }

    .xButton {
        display: flex;

        flex-shrink: 0;
        cursor: pointer;
        padding: 8px;
        height: fit-content;

        .xIcon {
            width: 14px;
            height: 14px;
            stroke-width: 2px;
        }
    }
}
