@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    background-color: var(--executive-blues-80);

    padding: 16px 28px; // 16px Vertical 28px Horizontal

    .labelsWrapper {
        text-align: center;
        text-transform: uppercase;
        text-wrap: nowrap;

        .bookTourText {
            color: var(--white);
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
        }

        .availabilityText {
            color: var(--seafoam-green);
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
        }
    }

    .inputsWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;
        padding-bottom: 12px;

        width: 100%;
        max-width: 1200px;

        .inputClass {
            width: 100%;
        }
    }

    .genericButton {
        background-color: var(--executive-blues-70-alpha-50);

        padding: 12px;
        max-width: 350px;

        color: var(--white);
        font-variant: small-caps;

        &.defaultPointer {
            cursor: default;
        }

        &.expandButton {
            width: 100%;
        }

        .chevronIcon {
            stroke: var(--white);
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        flex-direction: row;

        .labelsWrapper {
            text-align: left;
        }

        .inputsWrapper {
            padding-bottom: 0;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE) {
    .root {
        gap: 20px;
        padding-top: 23px;
        padding-bottom: 23px;

        .inputsWrapper {
            display: flex;
            gap: 12px;
        }

        &.submittedMessageWrapper {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
}
