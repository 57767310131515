@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 8px;

    padding-bottom: 16px;
    width: 100%;

    height: 100%;
    overflow: hidden;

    .layoutDetails {
        display: flex;

        flex-shrink: 0;
        justify-content: center;
        align-items: center;

        gap: 12px;
        height: 24px;

        .floorPlanName {
            color: var(--executive-blues-80);
            font-weight: 500;
            font-size: 24px;
            text-transform: uppercase;
        }

        .stat {
            align-items: center;
            gap: 4px;

            padding-left: 12px;

            height: 100%;

            color: var(--executive-blues-80);
            font-variant: small-caps;
            font-size: 12px;

            &:not(:first-child) {
                border-left: 1px solid var(--secondary-50);
            }

            .framingSquareIcon {
                width: 14px;
            }

            .bedIcon {
                width: 16px;
            }

            .bathIcon {
                width: 16px;
            }
        }

        .tourButton {
            display: flex;
            align-items: center;
            gap: 4px;

            // Auto left margin to align the button right
            margin-left: auto;
            padding: 8px 16px;

            font-variant: small-caps;

            .floorPlanIcon {
                margin-left: 4px;
                width: 16px;
                height: 16px;
            }
        }

        .xButton {
            // Display flex for sizing
            display: flex;

            // Auto left margin to align the button right
            margin-left: auto;
            padding: 8px;

            .xIcon {
                width: 14px;
                height: 14px;
                stroke-width: 2px;
            }
        }
    }

    .bottomDisplay {
        border: none;
        width: 100%;
        height: 100%;

        overflow: hidden; // Hide overflow so height 100% works
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        align-items: normal;
        padding-top: 0px;

        padding-bottom: 0px;

        .layoutDetails {
            justify-content: start;
            gap: 16px;

            border-bottom: 1px solid var(--secondary-40);

            padding: 8px 24px;

            height: 56px;

            .stat {
                gap: 8px;
                padding-left: 16px;
                font-size: 16px;

                .framingSquareIcon {
                    width: 18px;
                }

                .bedIcon {
                    width: 20px;
                }

                .bathIcon {
                    width: 20px;
                }
            }
        }
    }
}
