@import "../../../styles/breakpoints.scss";

.root {
    cursor: pointer;

    width: fit-content;

    font-variant: small-caps;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    // Align text if button has a width
    text-align: center;
    // Get rid of link underlines
    text-decoration: none;

    .childrenAndChevronWrapper {
        display: flex;
        position: relative;
        flex-direction: row;

        // Force text to left and arrow to right
        justify-content: space-between;
        align-items: center;
        // Minimum space between right side of text and left side of arrow
        gap: 8px;

        &.centered {
            justify-content: center;
        }

        .chevron {
            width: 10px;
            height: 10px;

            &.absoluteChevron {
                position: absolute;
                right: 0;
            }
        }
    }

    &.blurry {
        backdrop-filter: blur(5px);
        background-color: var(--white-20);

        color: var(--white);

        .chevron {
            stroke: var(--white);
        }
    }

    &.default {
        border: 1px solid var(--seafoam-green);
        background: var(--seafoam-green);
        color: var(--executive-blues-80);

        .chevron {
            stroke: var(--executive-blues-80);
        }
    }

    &.grayWithBorder {
        border: 1px solid var(--seafoam-green);
        background: var(--executive-blues-90);
        color: var(--white);

        .chevron {
            stroke: var(--white);
        }
    }

    &.hasBorder {
        border-radius: 64px;
        padding: 8px 12px;
    }

    &.transparentWithWhiteBorder {
        border: 1px solid var(--white);
    }

    &.transparentWithSeafoamGreenBorder {
        border: 1px solid var(--seafoam-green);
        color: var(--executive-blues-80);

        .chevron {
            stroke: var(--executive-blues-80);
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-X-SMALL) {
    .root {
        font-size: 14px;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) and (min-height: $VERTICAL-BREAKPOINT-SMALL) {
    .root {
        font-size: 16px;

        &.hasBorder {
            padding: 16px;
            font-size: 16px;
        }

        .childrenAndChevronWrapper {
            gap: 16px;

            .chevron {
                width: 14px;
                height: 14px;
            }
        }
    }
}
