.root {
    display: flex;
    grid-column: span 2;
    align-items: center;
    margin: 12px 0px;
    width: 100%;

    .centerContent {
        display: flex;
        align-items: center;
        gap: 8px;

        border: 1px solid var(--secondary-50);
        border-radius: 16px;
        padding: 5px 15px; // Extra padding on the sides for the rounded edge

        color: var(--secondary-70);
        font-size: 12px;
        text-transform: uppercase;

        .mapIcon {
            height: 16px;
            stroke: var(--secondary-50);
        }
    }

    .sideBorders {
        flex: 1;

        background-color: var(--secondary-50);
        height: 1px;
    }
}
