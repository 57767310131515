@import "../../../styles/breakpoints.scss";

.root {
    padding-right: 12px;
    padding-left: 12px;
    width: 100%;

    .searchWrapper {
        display: flex;
        align-items: center;

        border-bottom: 1px solid var(--executive-blues-90);
        padding: 10px 0px;

        .searchIcon {
            margin-right: 5px;
        }

        .searchInput {
            width: 100%;
            color: var(--secondary-70);
            font-weight: 300;
            font-size: 20px;
            letter-spacing: 1px;
        }

        .submitButton {
            display: flex;
            cursor: pointer;
            padding: 8px;

            .chevronIcon {
                width: 16px;
                height: 16px;
                stroke-width: 3px;
                stroke: var(--seafoam-green);
            }
        }
    }

    &.darkText {
        .searchWrapper {
            border-bottom-color: var(--secondary-60);
        }

        .searchIcon {
            svg {
                fill: var(--secondary-70);
            }
        }

        .xButton {
            .xIcon {
                stroke: var(--secondary-70);
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        padding-right: 20px;
        padding-left: 20px;

        .searchWrapper {
            .searchInput {
                font-size: 30px;
                letter-spacing: 2px;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        width: 900px;

        .searchWrapper {
            padding: 16px 0px;

            .searchIcon {
                margin-right: 15px;
                padding: 6px;
                padding-bottom: 4px;

                svg {
                    width: 28px;
                    height: 32px;
                }
            }

            .submitButton {
                .chevronIcon {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}
