.root {
    display: flex;
    justify-content: center;
    gap: 4px;

    box-shadow: 0px -4px 4px 0px var(--black-05);
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    background-color: var(--white);
    padding: 4px 16px 0px;
    width: 100%;

    color: var(--executive-blues-80);
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
}
