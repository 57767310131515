.orText {
    color: var(--secondary-80);
    font-style: italic;
    font-weight: 500;
    font-size: 14px;

    &.darkText {
        color: var(--executive-blues-80);
    }
}

.callTextWrapper {
    .callText {
        color: var(--secondary-50);
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        text-transform: capitalize;
    }

    .phoneNumber {
        color: var(--seafoam-green);
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        text-decoration-line: underline;
    }

    &.darkText {
        .callText {
            color: var(--executive-blues-80);
        }
    }
}
