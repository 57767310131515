.root {
    --folded-dimension: 5px;
    --ribbon-indent: 5px;
    --top-offset: 40px;
    --padding: 6px;

    display: flex;

    position: absolute;
    justify-content: center;
    align-items: center;
    gap: 5px;

    clip-path: polygon(
        0 0 /*Top Left*/,
        100% 0 /*Top Right*/,
        calc(100% - var(--ribbon-indent)) calc(50% - var(--folded-dimension) / 2) /* Middle Right Indent */,
        100% calc(100% - var(--folded-dimension)) /* Bottom Right*/,
        0% calc(100% - var(--folded-dimension)) /* Bottom Left */,
        calc(0% + var(--folded-dimension)) 100% /* Bottom of Fold*/,
        calc(0% + var(--folded-dimension)) calc(100% - var(--folded-dimension)) /* Top of Fold*/,
        0% calc(100% - var(--folded-dimension)) /* Bottom Left*/
    );
    // Position with top as the set offset and left as the negative fold dimension
    inset: var(--top-offset) auto auto calc(-1 * var(--folded-dimension));
    box-shadow: 0 calc(-1 * var(--folded-dimension)) 0 inset var(--black-30);

    background: var(--executive-blues-80);

    padding-top: var(--padding);
    padding-right: calc(2 * var(--padding) + var(--ribbon-indent)); // Pad from indent on the right
    padding-bottom: calc(var(--padding) + var(--folded-dimension)); // Pad from bottom of ribbon by adding folding dimension
    padding-left: calc(var(--padding) + var(--folded-dimension)); // Pads from start of folded part
}
