@use "../../../styles/mixins.scss" as Mixins;
@import "../cards.variables.scss";

.root {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // Need so the card background doesn't go under page linear gradient
    z-index: 0;

    border-radius: 8px;
    // Set background color for when image is not loaded yet
    background-color: var(--executive-blues-80);

    padding: 0px 16px;
    width: 100%;
    height: $DEFAULT-CARD-HEIGHT;
    overflow: hidden;

    text-align: center;

    .backgroundImage {
        // Below rest of content
        z-index: -1;

        width: 100%;
        height: 100%;

        @include Mixins.fullSizeAbsolute;
    }

    h1 {
        margin: 0px;

        color: var(--white);
        font-variant: small-caps;
        font-weight: 400;
        font-size: 30px;
        line-height: 100%;
    }

    p {
        margin-bottom: 16px;

        color: var(--white);
        font-weight: 400;
        font-size: 14px;
    }

    .actionButton {
        display: flex;
        align-items: center;

        gap: 8px;

        backdrop-filter: blur(2px);
        background-color: var(--seafoam-green-15);

        padding: 8px 16px;

        color: var(--white);
    }
}
