@use "../../../styles/mixins.scss" as Mixins;
@import "../../../styles/breakpoints.scss";

.root {
    --height: 100svh;

    display: flex;
    position: relative;
    flex-direction: column;
    // Need z index so ::after {mix-blend-mode} doesn't affect header background blur
    z-index: 1;

    width: 100%;
    height: var(--height);

    scroll-snap-align: start;

    &::after {
        @include Mixins.fullSizeAbsolute;

        // Puts the gradient behind the content and infront of the background content
        z-index: -1;

        mix-blend-mode: multiply;
        background: linear-gradient(to bottom, transparent 0, var(--executive-blues-80-alpha-40) 100%);

        pointer-events: none;

        content: "";
    }

    .video,
    .fallbackImage {
        @include Mixins.fullSizeAbsolute;

        // Puts the video and image behind the content container
        z-index: -2;

        width: 100%;
        height: var(--height);

        object-fit: cover;
    }

    .contentContainer {
        display: flex;
        flex-direction: column;

        justify-content: flex-end;
        align-items: flex-start;

        gap: 16px;

        padding: 16px;

        width: 100%;
        height: var(--height);

        .actionContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 32px;

            .actionLink {
                display: flex;
                flex-direction: row;
                align-items: center;
                vertical-align: middle;
                gap: 8px;

                color: var(--seafoam-green);
                font-weight: 500;
                font-size: 24px;
                text-decoration: none;
                text-transform: uppercase;

                .icon {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        .contentContainer {
            padding: 24px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .contentContainer {
            padding: 40px;
        }
    }
}
