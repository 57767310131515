@import "../../styles/breakpoints.scss";

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        height: 100svh;

        .entitySearchBlock {
            height: 100%;
            overflow: hidden;
        }
    }
}
