@use "../../../styles/mixins.scss" as Mixins;
@import "../../../styles/breakpoints.scss";

$activeDotSize: 10px;
$inactiveDotSize: 8px;

.root {
    position: relative;
    user-select: none;

    .slideNumber {
        display: flex;
        position: absolute;
        top: 12px;
        left: 12px;
        justify-content: center;
        z-index: 1;

        backdrop-filter: blur(4px);
        border-radius: 8px;
        background-color: var(--black-10);

        padding: 4px;
        min-width: 56px;

        color: var(--white);
        font-size: 12px;
        line-height: 15px;

        .activeSlideNumber {
            font-weight: 700;
        }
    }

    .carouselSliderWrapper {
        height: 100%;

        .carouselSlider {
            display: flex;
            position: relative;
            height: 100%;

            .slide {
                display: flex;
                justify-content: center;
                height: 100%;

                &.fade {
                    opacity: 1;

                    @include Mixins.fullSizeAbsolute;

                    &.hidden {
                        opacity: 0;
                    }
                }
            }
        }

        &.overflowHidden {
            overflow: hidden;
        }
    }

    &.hasFullScreenGallery {
        img {
            cursor: pointer;
        }
    }
}
