@import "../../../styles/breakpoints.scss";

.root {
    display: flex;

    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    transition: background-color 0.3s linear;

    cursor: pointer;

    background-color: var(--black-40);
    width: 100%;
    height: 25svh;
    min-height: 240px;

    &:hover {
        background-color: transparent;
    }

    .backgroundImage {
        z-index: -1; // This makes the image go behind the background color

        width: 100%;
        height: 100%;

        object-fit: cover;
    }

    .textBackground {
        position: absolute;
        bottom: 0;

        background: linear-gradient(180deg, transparent 0%, var(--black-80) 100%);

        width: 100%;
        height: 30%;
    }

    .contentWrapper {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        flex-direction: column;

        gap: 8px;

        margin-right: 30px;
        margin-bottom: 26px;
        margin-left: 30px;

        color: var(--white);

        .salesText {
            width: fit-content;

            $horizontalPadding: 8px;
            $verticalPadding: 4px;

            border-radius: 5px;

            background-color: var(--executive-blues-100);
            padding: $verticalPadding $horizontalPadding;

            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;
        }

        .cardTitle {
            font-weight: 800;
            font-size: 30px;
            line-height: 32px;
            text-transform: uppercase;
        }

        .cardButton {
            $buttonHorizontalPadding: 16px;
            $buttonVerticalPadding: 12px;
            padding: $buttonVerticalPadding $buttonHorizontalPadding;

            font-size: 10px;
            text-transform: inherit;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        min-height: 400px;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        min-height: 0; // Makes the cards flex correctly to the available space

        .contentWrapper {
            .cardTitle {
                font-size: 36px;
                line-height: 40px;
            }

            .cardButton {
                font-size: 14px;
            }
        }
    }
}
