.root {
    display: flex;

    gap: 1px;

    .floorPlanIcon {
        width: 11px;
        height: 9px;
    }

    .arrowIcon {
        width: 5px;
        height: 9px;
    }
}
