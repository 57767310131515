@use "../../../../../styles/mixins.scss" as Mixins;
@import "../../../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;

    // Subtract header height and 60 for tab navigator and page title
    height: calc(100svh - var(--header-height) - 60px);

    &.padBottom {
        // Subtract header height and 56 for tab navigator and page title - 150px for sticky bottom content
        height: calc(100svh - var(--header-height) - 206px);
    }

    .iconCarousel {
        position: sticky;
        // Add 28 pixels for mobile tab navigator
        top: calc(var(--header-bottom) + 28px);
        z-index: 3; // Above card flags

        transition: var(--header-transition);
        background-color: var(--white);
    }

    .tabsContentWrapper {
        position: relative;
        width: 100%;
        height: 100%;

        .tabWrapper {
            @include Mixins.fullSizeAbsolute;

            &.hiddenTab {
                visibility: hidden;
            }
        }
    }

    .bottomContentWrapper {
        // Above tab content
        z-index: 2;
        margin-top: -16px;
        margin-right: 8px;
        margin-bottom: 8px;

        margin-left: 8px;
        box-shadow: var(--default-box-shadow);

        border-radius: 8px;

        background-color: var(--white);

        padding: 8px;

        height: fit-content;

        // Apply to all children except last two
        > :not(:nth-last-child(-n + 2)) {
            border-bottom: 1px solid var(--secondary-50);
        }

        .preTitle {
            padding-bottom: 4px;

            color: var(--secondary-80);
            font-weight: 500;
            font-size: 16px;
            text-transform: uppercase;
        }

        .title {
            padding-bottom: 4px;

            color: var(--executive-blues-80);
            font-weight: 500;
            font-size: 20px;
            text-transform: uppercase;
        }

        .descriptionWrapper {
            padding: 8px 0;
        }

        .additionalStatsWrapper {
            padding: 8px 0;
        }
    }

    &.modal {
        margin-bottom: 0px;
        padding-top: 32px;
        width: 96svw;
        height: 98svh;

        .iconCarousel {
            position: relative;
            // Override top used in sticky to position at top of modal
            top: 0px;
            // Behind X button
            z-index: 0;
        }

        .bottomContentWrapper {
            .title {
                // On Modals position at top center of modal
                position: absolute;
                top: 8px;
                left: 50%;
                transform: translateX(-50%);

                border-bottom: none;

                font-size: 18px;
            }

            .descriptionWrapper {
                padding: 4px 0;
            }

            .additionalStatsWrapper {
                padding: 4px 0;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-X-SMALL) {
    .root {
        .bottomContentWrapper {
            margin-bottom: 16px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        &.padBottom {
            // Subtract header height and 60 for tab navigator and page title - 152px for sticky bottom content
            height: calc(100svh - var(--header-height) - 212px);
        }
    }
}
