@import "../../styles/breakpoints.scss";

.root {
    .link {
        color: inherit;
        text-decoration: underline;

        &:hover {
            color: var(--eh-orange);
            text-decoration: none;
        }
    }

    .warrantyBlock {
        margin-bottom: 50px;
    }

    .submitRequestButtonWrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 70px;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .warrantyBlock {
            margin-bottom: 75px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE) {
    .root {
        .warrantyBlock {
            margin-bottom: 125px;
        }

        .submitRequestButtonWrapper {
            margin-bottom: 140px;
        }
    }
}
