.root {
    width: 50%;
    color: var(--white);
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;

    .linkList {
        padding: 0;
        list-style: none;

        li {
            margin-top: 11px;

            .linkItem {
                cursor: pointer;

                color: var(--seafoam-green);
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                text-decoration: none;
            }
        }
    }

    &.darkText {
        color: var(--executive-blues-80);

        .linkList {
            li {
                .linkItem {
                    color: var(--secondary-70);
                }
            }
        }
    }
}
