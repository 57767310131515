@import "../../styles/breakpoints.scss";

.root {
    border-bottom-width: 1px;
    border-bottom-style: solid;

    border-radius: 0; // Override border radius to 0 for mobile bug
    padding: 10px 14px;
    width: 100%;
    height: fit-content;
    font-variant: small-caps;
    font-weight: 500;

    font-size: 12px;
    line-height: 12px;

    // This is to edit default webkit auto fill styles to fit our style
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: var(--white);
        -webkit-box-shadow: 0 0 0 30px var(--executive-blues-90) inset;
        caret-color: var(--white);
    }

    &.darkText {
        border-bottom-color: var(--secondary-50);
        color: var(--executive-blues-80);

        &::placeholder {
            color: var(--secondary-70);
        }

        // This is to edit default webkit auto fill styles to fit our style
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            -webkit-text-fill-color: var(--executive-blues-80);
            -webkit-box-shadow: 0 0 0 30px var(--secondary-30) inset;
            caret-color: var(--executive-blues-80);
        }
    }

    &.whiteText {
        border-bottom-color: var(--secondary-60);
        color: var(--white);

        &::placeholder {
            color: var(--white);
        }
    }

    &.grayText {
        border-bottom-color: var(--executive-blues-70);
        color: var(--white);

        &::placeholder {
            color: var(--secondary-80);
        }
    }

    &:focus {
        border-bottom-color: var(--seafoam-green);
    }
}

.redMessage {
    color: var(--alert);
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        padding: 17px 14px;
    }
}
