@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 14px;

    padding: 0px 20px;

    width: 100%;
    max-width: 1200px;
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        gap: 30px;
        padding-top: 50px;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        padding: 0px 30px;
    }
}
