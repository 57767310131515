.backgroundWrapper {
    display: flex;
    position: fixed;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;

    align-items: center;

    // z-index 11 to beat eh-header and property summary header
    z-index: 11;
    backdrop-filter: blur(5px);

    cursor: default;

    background-color: var(--black-20);

    &.hidden {
        display: none;
    }
}

.root {
    position: relative;

    background-color: var(--white);

    width: fit-content;
    height: fit-content;

    .closeButton {
        display: flex;
        position: absolute;

        top: 8px;
        right: 8px;
        justify-content: center;

        align-items: center;
        border-radius: 50%;

        background-color: var(--secondary-40);

        width: 28px;
        height: 28px;

        .xIcon {
            width: 14px;
            height: 14px;
        }
    }
}
