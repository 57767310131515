.root {
    // This is so the ribbon shows the 3D effect on cards that are selected
    // (This beats the z-index for the absolute positioned border .overlay .selected)
    z-index: 2;
    transition: opacity 0.3s ease-in;

    &.hidden {
        opacity: 0;
    }

    .flagText {
        color: var(--white);
        font-weight: 700;
        font-size: 10px;
        text-transform: uppercase;
    }
}
