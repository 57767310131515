@import "../../../../../styles/breakpoints.scss";

.root {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    margin-top: 16px;
    width: 100%;

    .submitButton {
        padding: 12px;
        width: 100%;
        max-width: 350px;
        font-variant: small-caps;

        &.disabled {
            border-color: var(--executive-blues-90);
            background: var(--executive-blues-90);
            color: var(--secondary-90);
        }

        &.defaultPointer {
            cursor: default;
        }

        &.lightButton {
            background: var(--seafoam-green);
            color: var(--executive-blues-80);

            &.disabled {
                border-color: var(--secondary-50);
                background: var(--secondary-50);
                color: var(--secondary-90);

                svg {
                    stroke: var(--secondary-90);
                }
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        margin-top: 24px;

        .submitButton {
            padding: 16px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        padding-right: 60px;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE) {
    .root {
        .submitButton {
            width: 180px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE) {
    .root {
        .submitButton {
            width: 232px;
        }
    }
}
