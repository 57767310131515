.root {
    &.displayFloorPlan {
        grid-column: span 2;
    }

    .lotDimensions {
        display: flex;

        gap: 3px;
    }

    .constructionStatus {
        backdrop-filter: blur(8px);
        border-radius: 20px;
        background-color: var(--executive-blues-80-alpha-40);
        padding: 4px 8px;
        width: fit-content;

        font-weight: 600;
        font-size: 10px;
        text-transform: uppercase;
    }
}
