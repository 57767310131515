@import "../../../styles/breakpoints.scss";

.root {
    position: relative;
    color: var(--executive-blues-80);
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        .textField {
            padding: 17px 14px;
        }
    }
}
