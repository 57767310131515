@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;

    .stickyHeader {
        position: sticky;
        // Add 28 pixels for mobile tab navigator
        top: calc(var(--header-bottom) + 28px);
        z-index: 4; // Above search bar

        transition: var(--header-transition);
        background-color: var(--white);
    }

    .searchBlock {
        height: 100%;

        // Add an extra 56px (Height of Icon Carousel) to stick to bottom of icon carousel + 28px for tab navigator
        $searchBarTop: calc(var(--header-bottom) + 84px);

        .mobileMapWrapper {
            // Subtract 170px to take into account the title, icon carousel, and tab navigator so it fits into view all at once with a little space left over
            height: calc(100svh - var(--header-height) - 170px);
            overflow: hidden;
        }

        .mobileSearchBarWrapper {
            top: $searchBarTop;
        }

        .mobileListViewSwitch {
            // Search bar height + 53px (Height of search bar) to stick to bottom of search bar
            // Need important since class to override is nested higher
            top: calc($searchBarTop + 53px) !important;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        // Subtract 80px on top of the header height to give extra space to make it easier to fit into view
        height: calc(100svh - var(--header-height) - 80px);

        .stickyHeader {
            top: var(--header-bottom);
        }

        .searchBlock {
            overflow: hidden;
        }
    }
}
