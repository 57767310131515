@use "../../../styles/mixins.scss" as Mixins;
@import "./search-input-with-filter.variables.scss";

.root {
    position: relative;
    // Need to be over 2 to be over house card ribbon
    z-index: 3;
    width: 100%;

    height: $SEARCH-BAR-HEIGHT;

    .expansionWrapper {
        transition: height 400ms cubic-bezier(0, 0, 0.58, 1);
        border: 1px solid var(--secondary-50);
        border-radius: 18px;
        background: var(--secondary-30);
        width: 100%;

        height: $SEARCH-BAR-HEIGHT;
        overflow: hidden;

        &.open {
            height: 176px;
        }

        .searchBar {
            display: flex;
            align-items: center;
            gap: 10px;
            margin: -1px; // -1 margin to match border with parent
            border: 1px solid var(--secondary-50);

            border-radius: 18px;

            background-color: var(--white);

            padding-left: 3px;
            width: calc(100% + 2px); // + 2px to account for parent border

            height: $SEARCH-BAR-HEIGHT;

            .searchIcon {
                margin-left: 12px;
                width: 18px;
                height: 18px;
                fill: var(--secondary-70);
            }

            .searchInput {
                flex: 1;

                color: var(--executive-blues-80);
                font-variant: small-caps;
                font-weight: 500;
                font-size: 14px;
                line-height: 30px;

                @include Mixins.ellipsis;
            }
        }

        .searchDropdownContentWrapper {
            padding: 12px 24px;

            .listWrapper {
                width: 100%;

                // Needed for children ellipsis to work
                overflow: hidden;

                color: var(--secondary-80);
                font-weight: 600;
                font-size: 12px;
                line-height: 24px;
                letter-spacing: 2px;
                text-transform: uppercase;

                .listTitle {
                    color: var(--executive-blues-80);
                    font-weight: 700;
                    font-size: 10px;

                    @include Mixins.ellipsis;
                }

                .entryText {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    @include Mixins.ellipsis;

                    .darkText {
                        cursor: pointer;
                        color: var(--executive-blues-80);
                    }

                    &:hover {
                        color: var(--executive-blues-80);
                        font-weight: 700;
                    }
                }
            }
        }

        .noSearchResultsWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            gap: 10px;

            width: 100%;
            // Set height to full parent - search bar height to properly center content in center
            height: calc(100% - $SEARCH-BAR-HEIGHT);

            color: var(--executive-blues-80);
            font-weight: 600;
            font-size: 16px;
            letter-spacing: 2px;
            text-transform: uppercase;

            .noSearchResultsIcon {
                width: 26px;
                height: 26px;
            }

            .noSearchText {
                max-height: 100%;
            }
        }
    }

    .buttonContainer {
        display: flex;
        position: absolute;
        top: 3px;
        right: 4px;

        gap: 3px;

        .roundButton {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            border-radius: 50%;
            background-color: var(--secondary-40);
            width: 31px;

            height: 31px;

            &.filterButton {
                z-index: 2; // higher than the filter panel to display over it

                .filterIcon {
                    width: 20px;
                    height: 20px;
                }

                &.active {
                    border: 1px solid var(--seafoam-green);
                    background-color: var(--seafoam-green-10);

                    .filterIcon {
                        stroke: var(--seafoam-green);
                    }
                }
            }
        }

        .hiddenButton {
            display: none;
        }
    }
}
