@import "../../../styles/breakpoints.scss";

.root {
    border-radius: 8px;

    padding: 40px 16px 24px;
    // Need important so we don't have to nest this class
    width: 95% !important;
    max-width: 650px;

    .bottom {
        display: flex;
        flex-direction: row;
        gap: 12px;

        .bottomButton {
            padding: 8px;
            font-variant: small-caps;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        padding: 32px;

        .inputRowWrapper {
            flex-direction: row;
            gap: 12px;
        }

        .bottom {
            .bottomButton {
                width: 180px;
            }
        }
    }
}
