@use "../../../styles/mixins.scss" as Mixins;
@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    gap: 4px;

    cursor: pointer;

    // Add two pixels to fill complete height of selected one
    border-bottom: 2px solid transparent;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    padding: 4px;
    width: 100%;
    min-height: 28px;

    text-decoration: none;

    .textWrapper {
        display: flex;
        max-width: 100%;

        color: var(--secondary-80);
        font-weight: 500;
        font-size: 10px;
        text-align: center;
        text-transform: uppercase;

        .nameWrapper {
            max-width: 100%;
            @include Mixins.ellipsis;
        }
    }

    &:hover {
        background-color: var(--seafoam-green-10);
    }

    &.selected {
        border-bottom: 2px solid var(--seafoam-green);

        .textWrapper {
            .nameWrapper {
                color: var(--executive-blues-80);
                font-weight: 700;
            }
        }

        &:hover {
            background-color: transparent;
        }
    }

    &.hasIcon {
        margin-top: 4px;

        &.selected {
            border-bottom: 2px solid transparent;
            background-color: var(--seafoam-green-30);
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        .name {
            font-size: 12px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-X-LARGE-DESKTOP) {
    .root {
        gap: 8px;
        padding: 8px;
    }
}
