@import "../../../styles/breakpoints.scss";
@import "../../cards/cards.variables.scss";

.root {
    display: flex;
    position: relative;
    flex-direction: column;

    $card-container-horizontal-padding: 12px;

    $mobileHorizontalPadding: 12px;

    .cardContainer {
        padding-top: 6px;
        padding-right: $card-container-horizontal-padding;
        padding-bottom: 16px; // This makes the bottom cards not have a hard cutoff look due to the shadow at end position

        padding-left: $card-container-horizontal-padding;
        width: 100%;
    }

    .mapContainer {
        // This is needed for the width to take up 100% of available width space
        flex-grow: 1;
    }

    .mobileSearchBarWrapper {
        display: flex;
        position: sticky;

        top: var(--header-bottom);
        flex-direction: column;
        gap: 8px;
        z-index: 4; // Higher than list switch for dropdown to not be underneath

        transition: var(--header-transition);

        background-color: var(--white);
        padding: 8px $mobileHorizontalPadding;
    }

    .mobileListView {
        display: flex;
        flex-direction: column;

        .listSwitch {
            position: sticky;
            // Add extra 28 for tab navigator height
            top: calc(var(--header-bottom) + 53px);
            z-index: 3; // Higher than card flags

            transition: var(--header-transition);

            border-radius: 0;
            background-color: var(--white);
            padding: 0px $mobileHorizontalPadding 8px;
            width: 100%;

            button {
                padding: 8px;
            }
        }

        .viewSelectorContentWrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            gap: 8px;

            .listIcon {
                height: 16px;
            }

            .mapIcon {
                height: 18px;
            }
        }

        .listWrapper {
            padding: 0px $mobileHorizontalPadding;
        }
    }

    .mobileMapView {
        display: flex;
        position: relative; // Position relative for switch
        flex-direction: column;
        gap: 8px;

        padding-bottom: 4px;

        // Map view should be 100svh - header height and - search bar height so page takes up full screen
        height: calc(100svh - var(--header-height) - 60px);
        // Hide overflow to not have horizontal scroll bar
        overflow: hidden;

        .mapSwitch {
            position: absolute;
            top: 8px;
            left: 8px;
            border-radius: 8px;

            background-color: white;

            width: fit-content;

            button {
                padding: 0px 8px;
                height: 42px;
            }
        }

        .placeholderCard {
            padding: 24px;
            height: 200px;

            .locationPinIcon {
                height: 16px;
                overflow: visible;

                fill: none;
                stroke: var(--white);
            }
        }

        .mobileMapCardContainer {
            align-self: center;
            width: $DEFAULT-CARD-WIDTH;
        }
    }

    .hidden {
        display: none;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        flex-direction: row;
        $card-container-horizontal-padding: 20px;

        .leftContainer {
            display: flex;
            flex-shrink: 0;
            flex-direction: column;

            gap: 6px;

            width: calc($DEFAULT-CARD-WIDTH + ($card-container-horizontal-padding * 2)); // Width for 1 cards + padding

            .switchAndSearchContainer {
                display: flex;
                flex-direction: column;

                gap: 8px;
                margin-right: $card-container-horizontal-padding;
                margin-left: $card-container-horizontal-padding;
                width: calc(100% - ($card-container-horizontal-padding * 2));

                .multiStateSwitchButtons {
                    $paddingVertical: 4px;
                    padding-top: $paddingVertical;
                    padding-bottom: $paddingVertical;
                }

                .searchRow {
                    display: flex;
                    gap: 8px;
                }
            }

            &.padTop {
                padding-top: 12px;
            }
        }

        .cardContainer {
            padding-right: 8px;
            padding-left: $card-container-horizontal-padding;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE) {
    .root {
        $card-container-horizontal-padding: 20px;

        .leftContainer {
            // Width for 2 cards + padding and gap
            width: calc(($DEFAULT-CARD-WIDTH * 2) + ($card-container-horizontal-padding * 2) + $DEFAULT-CARD-GAP);
        }
    }
}
