@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;
    margin: 20px auto 0;
    width: 90%;
    max-width: 1600px;
    min-height: 130px;
    color: var(--executive-blues-80);

    .leftWrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-right: none;
        text-align: center;

        .titleText {
            margin-top: 15px;
            padding: 0 10px;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            text-transform: uppercase;
        }

        @media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
            border-right: 1px solid var(--secondary-50);
        }
    }

    .content {
        display: flex;
        flex: 6;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 10px 0px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;

        .paragraph2 {
            margin-top: 20px;
        }
    }

    @media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
        flex-direction: row;
        margin: 30px auto 100px;
        .content {
            padding: 10px 0px 10px 40px;
            text-align: left;
        }
    }
}
