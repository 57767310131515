@import "../../../../styles/breakpoints.scss";

.root {
    position: relative;
    width: 100%;
    height: 100%;

    .textBlock {
        // Absolute position the text boxes to the bottom left
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2; // z-index above before background gradient

        background: linear-gradient(180deg, transparent 0%, var(--executive-blues-90) 100%);

        padding: 24px 12px 30px;
        height: fit-content;

        &.extraBottomPaddingOnMobile {
            padding-bottom: 38px;
        }

        .title {
            color: var(--white);
            font-weight: 700;
            font-size: 14px;
            line-height: 150%;
            text-transform: uppercase;
        }

        .description {
            color: var(--white);
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;

            // Set paragraph margin to 0 to avoid unnecessary spacing on description
            p {
                margin: 0;
            }
        }
    }

    &.salesSlide {
        .textBlock {
            .title {
                font-size: 32px;
                line-height: 100%;
                text-transform: uppercase;
            }

            .description {
                font-size: 12px;
            }
        }
    }
}

.attachmentInSlide {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.clickableAttachment {
        cursor: pointer;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .textBlock {
            padding-bottom: 10px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE) {
    .root {
        .textBlock {
            .title {
                font-size: 16px;
            }

            .description {
                font-size: 14px;
            }
        }

        &.salesSlide {
            .textBlock {
                padding-right: 32px;
                padding-left: 32px;

                .title {
                    font-size: 48px;
                }

                .description {
                    font-size: 16px;
                }
            }
        }
    }
}
