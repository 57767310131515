@use "./eh-header.variables.scss";
@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    position: sticky;
    flex-direction: column;

    align-items: center;
    z-index: 10;

    background-image: linear-gradient(var(--black-60), transparent);

    width: 100%;

    &.fixed {
        position: fixed;
    }

    .pageList {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 0;
        padding: 0;
        color: var(--white);
        list-style-type: none;

        .selected {
            position: relative;

            &::before {
                position: absolute;
                top: auto;
                right: 0;
                bottom: 0;
                left: 0;
                margin: 0 auto;
                background: var(--seafoam-green);
                width: 100%;
                height: 1px;
                content: "";
            }
        }

        li a {
            display: flex;
            align-items: center;

            transition:
                font-size 0.1s linear,
                opacity 0.15s ease-in-out;
            width: fit-content;
            height: 100%;

            color: inherit;
            font-weight: 800;
            font-size: 20px;
            letter-spacing: 2px;
            text-decoration: none;
            text-transform: uppercase;
            white-space: nowrap;

            &:hover {
                opacity: 0.65;
            }
        }
    }

    &.white {
        background-image: none;

        &::before {
            background-color: var(--white);
        }

        .pageList {
            color: var(--executive-blues-80);
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .pageList {
            flex-direction: row;
            height: 100%;

            .selected {
                &::before {
                    bottom: 12px;
                }
            }

            li a {
                font-weight: 600;
                font-size: 10px;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE) {
    .root {
        .pageList {
            gap: 24px;

            li a {
                font-size: 14px;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-DESKTOP) {
    .root {
        .pageList {
            gap: 32px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE-DESKTOP) {
    .root {
        .pageList {
            gap: 48px;
        }
    }
}
