@use "../../../styles/mixins.scss" as Mixins;
@import "../../../styles/breakpoints.scss";

$border-style: solid;
$border-color: var(--white);

.root {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: space-around;

    backdrop-filter: blur(5px);
    box-shadow: var(--default-box-shadow);

    border: $border-style 2px $border-color;
    border-radius: 20px;

    background: var(--white-05);

    .statistic {
        display: flex;
        flex-direction: column;

        align-items: flex-start;

        gap: 4px;

        padding: 8px 16px;

        width: 100%;

        color: var(--white);
        font-style: normal;
        text-transform: uppercase;

        text-wrap: nowrap;

        &:nth-child(-n + 3) {
            border-bottom: $border-style 1px $border-color;
        }

        .cardTitle {
            font-weight: 600;
            font-size: 18px;
            line-height: 100%;
            letter-spacing: 0.36px;
        }

        .subtitle {
            color: var(--white);
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 1px;
        }

        .monthsTitleWrapper {
            display: flex;
            flex-direction: row;

            align-items: baseline;

            gap: 3px;

            .months {
                font-weight: 700;
                font-size: 16px;
                letter-spacing: 0.2px;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-X-SMALL) {
    .root {
        flex-wrap: wrap;
        .statistic {
            width: 50%;

            &:nth-child(odd) {
                border-right: $border-style 1px $border-color;
            }

            &:nth-child(-n + 2) {
                border-bottom: $border-style 1px $border-color;
            }

            &:nth-child(3) {
                border-bottom: none;
            }
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        flex-wrap: nowrap;
        .statistic {
            width: auto;

            &:nth-child(odd) {
                border-right: none;
            }

            &:nth-child(-n + 2) {
                border-bottom: none;
            }

            &:not(:last-child) {
                border-right: $border-style 1px $border-color;
            }
        }
    }
}
