@import "../../styles/breakpoints.scss";

.root {
    .brickGroup {
        display: flex;
        flex-direction: column;
        margin-right: auto;

        margin-left: auto;

        max-width: 874px;

        .brickImage {
            align-self: center;

            margin-bottom: 20px;
            width: 100%;
            max-width: 500px;
        }
    }
}

@media (min-width: $HORIZONTAL_BREAKPOINT_MEDIUM) {
    .root {
        .brickGroup {
            display: block;

            .brickImage {
                width: 427px;
                height: 242px;

                &:nth-child(odd) {
                    margin-right: 10px;
                }

                &:nth-child(even) {
                    margin-left: 10px;
                }
            }
        }
    }
}
