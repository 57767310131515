.root {
    cursor: pointer;

    // Need important to override google maps assignment of none
    pointer-events: all !important;

    .label {
        display: block;

        position: absolute;
        // Place above parent by 4px and center horizontally
        bottom: calc(100% + 4px);
        left: 50%;
        transform: translateX(-50%);

        border-width: 3px;
        border-style: solid;
        border-radius: 8px;

        background: var(--executive-blues-90);

        padding: 8px 10px;

        color: var(--white);
        font-variant: small-caps;
        font-weight: 500;
        font-size: 15px;
        font-family: var(--montserrat);
        text-wrap: nowrap;

        &.hidden {
            display: none;
        }
    }
}
