@use "../../../../styles/mixins.scss" as Mixins;
@import "../../../../styles/breakpoints.scss";

.root {
    .mobileTitleStatsWrapper {
        display: flex;
        gap: 8px;
        width: 100%;

        .mobileTitleStat {
            display: flex;
            flex: 1;
            justify-content: center;
            gap: 4px;

            border: 1px solid var(--secondary-50);
            border-radius: 4px;
            background: var(--secondary-40);
            padding-top: 4px;
            padding-bottom: 4px;

            color: var(--executive-blues-80);
            font-variant: all-small-caps;
            font-weight: 300;
            font-size: 12px;
        }
    }

    .mobileDescriptionDetailsWrapper {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 4px;

        .descriptionDetail {
            display: flex;
            align-items: center;
            gap: 8px;

            svg {
                // Needed to prevent text from shrinking this
                flex-shrink: 0;
                width: 22px;
                height: 22px;
            }

            .detailText {
                display: flex;
                flex-direction: column;
                // Needed for children ellipsis
                overflow: hidden;

                label {
                    color: var(--executive-blues-80);
                    font-weight: 700;
                    font-size: 10px;
                    text-transform: uppercase;
                    @include Mixins.ellipsis;
                }

                span {
                    color: var(--executive-blues-80);
                    font-weight: 400;
                    font-size: 10px;
                    text-transform: uppercase;
                    @include Mixins.ellipsis;
                }
            }
        }
    }

    .buttonSection {
        display: flex;
        gap: 8px;
        width: 100%;

        button {
            flex: 1;
        }
    }

    .convenienceMap {
        // -14px Height for map so we don't cut off google water-marks
        height: calc(100% - 20px);
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .convenienceMap {
            height: 100%;
        }
    }
}
