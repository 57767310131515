.root {
    height: 100%;
    overflow: hidden;

    $borderRadius: 8px;

    .attachmentCarousel {
        height: 100%;
    }

    .extraBottomDots {
        bottom: 20px;
    }
}
