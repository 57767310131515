@mixin ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin noScrollBar {
    // Used to target only firefox
    // If this is not here it will mess up the webkit Chrome style
    @-moz-document url-prefix() {
        /* Custom scrollbar styling for firefox */
        scrollbar-width: none;
    }

    -ms-overflow-style: none; /* Older IE and Edge */

    // This hides the scrollbar for webkit browsers
    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin customScrollBar {
    // Used to target only firefox
    // If this is not here it will mess up the webkit Chrome style
    @-moz-document url-prefix() {
        scrollbar-color: var(--secondary-50) var(secondary-30);
        /* Custom scrollbar styling for firefox */
        scrollbar-width: thin;
    }

    /* Custom scrollbar styling for Webkit browsers */
    &::-webkit-scrollbar {
        display: block;
        border-radius: 8px;
        width: 12px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: var(--secondary-60);
    }

    &::-webkit-scrollbar-track {
        border: 1px solid var(--secondary-45);
        border-radius: 10px;
        background-color: var(--secondary-45);
    }
}

@mixin fullSizeAbsolute {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
