@use "../../../styles/mixins.scss" as Mixins;

.root {
    display: flex;
    justify-content: center;
    z-index: 1;
    background-color: var(--white);

    padding-top: 24px;
    width: 100%;

    height: 100%;

    .player {
        width: 100px;
        height: 100px;
    }

    svg {
        path {
            fill: var(--secondary-50);
            stroke: var(--secondary-50);
        }
    }
}
