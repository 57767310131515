.root {
    transition: height 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
    border: 1px solid var(--executive-blues-80);
    border-radius: 24px;
    width: 100%;
    overflow: hidden;

    .titleButton {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 13px 16px;

        width: 100%;

        color: var(--white);
        font-weight: 500;
        font-size: 16px;
        line-height: 14px;
        letter-spacing: 2px;
        text-transform: uppercase;

        .chevronIcon {
            margin-left: auto;
            width: 12px;
            height: 12px;
            stroke: var(--white);
        }
    }

    .childWrapper {
        margin-inline: 16px;
    }

    &.darkText {
        border: 1px solid var(--secondary-50);

        .titleButton {
            color: var(--executive-blues-80);

            .chevronIcon {
                stroke: var(--executive-blues-80);
            }
        }
    }
}
