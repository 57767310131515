@use "../../../styles/mixins.scss" as Mixins;
@import "../../../styles/breakpoints.scss";

.root {
    height: 100%;

    .detailsImage {
        border-radius: 0;
        height: 100%;
    }

    .detailsStatsSection {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 8px;
        width: 100%;

        .singleDetailSection {
            display: flex;
            flex-direction: row;

            align-items: center;

            justify-self: start;

            gap: 8px;
            width: fit-content;
            max-width: 100%;

            .detailsStat {
                // Overflow hidden for children ellipsis to work
                overflow: hidden;

                .measurementTitle {
                    color: var(--executive-blues-80);
                    font-weight: 700;
                    font-size: 10px;
                    text-transform: uppercase;

                    @include Mixins.ellipsis;
                }

                .measurement {
                    color: var(--executive-blues-80);
                    font-weight: 400;
                    font-size: 10px;
                    text-transform: uppercase;

                    @include Mixins.ellipsis;
                }
            }
        }

        .divider {
            // Makes the divider take up an entire row
            grid-column: 1 /3;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        display: flex;
        flex-direction: column;

        .detailsImage {
            height: 100%;
        }

        .detailsStatsSection {
            gap: 0px;
            margin: 24px;
            max-width: 100%;

            .singleDetailSection {
                justify-self: center;
                gap: 24px;

                .detailsStat {
                    .measurementTitle {
                        font-size: 14px;
                    }

                    .measurement {
                        font-size: 16px;
                    }
                }
            }

            .divider {
                margin-top: 16px;
                margin-bottom: 16px;

                background-color: var(--secondary-40);
                height: 1px;
            }
        }
    }
}
