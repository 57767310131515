.root {
    .pointWrapper {
        position: absolute;
        top: 50%;
        left: 2px;
        transform: translateY(-50%);

        .point {
            transform: rotate(-45deg);

            background-color: var(--executive-blues-80);

            width: 16px;
            height: 16px;
        }
    }

    .label {
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);

        border-radius: 8px;

        background-color: var(--executive-blues-80);

        padding: 12px;

        color: var(--white);
        font-weight: 600;
        font-size: 12px;
        font-family: var(--montserrat);
        text-transform: uppercase;
        text-wrap: nowrap;
    }
}
