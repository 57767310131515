@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    position: relative;
    flex-direction: column;

    height: fit-content;

    .stickyTop {
        position: sticky;
        // Add 28 pixels for mobile tab navigator
        top: calc(var(--header-bottom) + 28px);
        // Above + signs on upgrade cards
        z-index: 2;

        transition: var(--header-transition);

        background-color: var(--white);

        .iconTabWithButtonWrapper {
            position: relative;

            .xOnSearch {
                position: absolute;
                right: 0;

                cursor: pointer;

                padding: 8px;

                .xIcon {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    .mobileSearchBarWrapper {
        padding: 8px 16px;
    }

    .ehLogo {
        overflow: visible;
        fill: none;
    }
}

.infoModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;

    padding: 48px 16px 24px;

    width: 90%;
    max-width: 650px;

    color: var(--executive-blues-80);

    font-size: 16px;
    line-height: 28px;
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        margin-bottom: 50px;
        background-image: none;
        padding-bottom: 0;

        .stickyTop {
            top: var(--header-height);
        }

        &::after {
            content: none;
        }
    }
}
