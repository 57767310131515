@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 100%;

    color: var(--executive-blues-80);
    font-style: normal;
    text-align: center;

    .iconMargin {
        margin-bottom: 8px;
    }

    .differenceMakerTitle {
        margin-bottom: 4px;
        font-weight: 700;

        font-size: 24px;
        line-height: 22px;
    }

    .differenceMakerDescription {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-SMALL) {
    .root {
        align-items: normal;
        text-align: left;

        .differenceMakerTitle {
            text-align: left;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-LARGE) {
    .root {
        max-width: 400px;
    }
}
