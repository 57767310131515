@use "../../../styles/mixins.scss" as Mixins;
@import "../../../styles/breakpoints.scss";

.root {
    position: relative;
    background-color: var(--executive-blues-100);
    width: 100%;
    height: 100%;

    .compass {
        position: absolute;
        top: 24px;
        left: 24px;

        width: 48px;
        height: 48px;
    }

    .platImage {
        @include Mixins.fullSizeAbsolute;

        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-DESKTOP) {
    .root {
        .compass {
            width: 64px;
            height: 64px;
        }
    }
}
