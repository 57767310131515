@import "../../../styles/breakpoints.scss";

.root {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;

    .similarNeighborhoodCardContainer {
        display: flex;
        flex-direction: row;

        // Gap between first card and carousel
        gap: 8px;
        margin-top: 24px;
        padding-bottom: 24px;
        overflow: hidden;

        &.static {
            justify-content: center;
        }

        .stickyCardWrapper {
            z-index: 1;

            background-color: var(--white);

            padding-left: 16px;
        }

        .carousel {
            // Width of a card + carousel gap
            width: 178px;
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        margin-top: 50px;
        margin-bottom: 50px;

        .similarNeighborhoodCardContainer {
            gap: 24px;
        }
    }
}
