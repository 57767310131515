@import "../../styles/breakpoints.scss";

.root {
    color: var(--executive-blues-80);

    .pageTitle {
        margin-top: 0;
        margin-bottom: 12px;
        font-weight: 400;

        font-size: 24px;
        letter-spacing: 2px;
        text-align: center;
        text-transform: uppercase;
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-DESKTOP) {
    .root {
        .pageTitle {
            font-size: 40px;
        }
    }
}
