.root {
    display: flex;

    gap: 2px;

    .dollarSignIcon {
        width: 7px;
        height: 12px;
    }

    .arrowIcon {
        width: 5px;
        height: 12px;
    }
}
