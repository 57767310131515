@use "../../../styles/mixins.scss" as Mixins;
@import "../../../styles/breakpoints.scss";
@import "../cards.variables.scss";

.root {
    position: relative;
    cursor: pointer;

    box-shadow: var(--default-box-shadow);
    border-radius: 8px;

    width: 100%;
    height: $DEFAULT-CARD-HEIGHT;
    overflow: hidden;

    .informationButton {
        display: flex;
        position: absolute;
        top: 8px;
        right: 8px;
        justify-content: center;
        align-items: center;

        backdrop-filter: blur(2px);

        cursor: pointer;
        outline: none;

        border-radius: 50%;

        background-color: var(--executive-blues-80-alpha-40);

        width: 32px;
        height: 32px;

        .informationIcon {
            width: 18px;
            height: 18px;
        }

        .xIcon {
            stroke-width: 1.5px;
            stroke: var(--white);

            width: 14px;
            height: 14px;
        }
    }

    .bottomDetails {
        display: flex;
        position: absolute;
        bottom: 0;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        background: linear-gradient(180deg, transparent, var(--executive-blues-90) 100%);

        padding: 8px;
        width: 100%;

        overflow: hidden;
        user-select: none;

        .nameAndPrice {
            display: flex;
            flex-direction: column;

            max-width: 100%;
            overflow: hidden;

            .name {
                color: var(--white);
                font-variant: small-caps;
                font-weight: 700;
                font-size: 14px;
                line-height: 150%;

                @include Mixins.ellipsis;
            }

            .price {
                color: var(--white);
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
                text-transform: uppercase;
            }
        }

        .iconWrapper {
            display: flex;

            border: 1px solid var(--executive-blues-70);
            border-radius: 50%;
            background: var(--executive-blues-80-alpha-60);

            padding: 8px;

            .icon {
                width: 16px;
                height: 16px;
            }

            &.selected {
                border: 1px solid var(--seafoam-green);
                background: var(--seafoam-green);

                .icon {
                    fill: var(--executive-blues-90);
                }
            }
        }
    }

    .expandedPanel {
        padding: 24px;
        padding-right: 60px;

        .name {
            color: var(--white);
            font-weight: 700;
            font-size: 20px;
            line-height: 125%;
            text-transform: uppercase;
        }

        hr {
            margin-left: 0;
            border-color: var(--seafoam-green);
            width: 50%;
        }

        .description {
            color: var(--white);
            font-weight: 200;
            font-size: 14px;
            line-height: 125%;
        }
    }

    &.upgrade {
        &.selected {
            border: 3px solid var(--seafoam-green);
        }
    }
}

@media (min-width: $HORIZONTAL-BREAKPOINT-MEDIUM) {
    .root {
        .bottomDetails {
            .iconWrapper {
                &:hover {
                    border: 1px solid var(--seafoam-green);
                    background-color: var(--seafoam-green-50);
                }

                // Need to put selected here again to not get overridden by hover
                &.selected {
                    border: 1px solid var(--seafoam-green);
                    background: var(--seafoam-green);
                }
            }
        }
    }
}
