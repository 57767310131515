.root {
    position: relative;
    width: 100%;
    height: 50px;

    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        border-radius: 10px;
        background-color: var(--secondary-50);
        width: 30px;
        height: 5px;
        content: "";
    }
}
