@use "../../../styles/mixins.scss" as Mixins;

.root {
    display: flex;
    position: fixed;
    top: 0;
    align-items: center;
    gap: 12px;
    // Above eh-header
    z-index: 11;

    transition: top 0.3s ease-out;
    border-bottom: 1px solid var(--secondary-50);

    background-color: var(--white);
    padding: 8px 24px;
    width: 100%;

    height: var(--header-height);

    &.isHidden {
        // Set top above screen when hidden
        top: calc(0px - var(--header-height));
    }

    .ehLogo {
        width: 32px;
        // Need min-width or logo shrinks when page is shrunk horizontally
        min-width: 32px;
        height: 32px;
        fill: var(--executive-blues-80);
    }

    .detailsWrapper {
        display: flex;
        // Add flex 1 and min-width to get address ellipsis to work
        flex: 1;
        gap: 16px;
        min-width: 200px;

        .price {
            color: var(--executive-blues-80);
            font-weight: 500;
            font-size: 24px;
        }

        .fullAddress {
            display: flex;
            flex-direction: column;
            justify-content: center;
            // Add min-width to get ellipsis to work
            min-width: 80px;

            .streetAddress {
                color: var(--executive-blues-80);
                font-weight: 700;
                font-size: 12px;
                line-height: 14px; /* 116.667% */
                text-transform: uppercase;

                @include Mixins.ellipsis;
            }

            .neighborhoodAndCity {
                color: var(--secondary-80);
                font-variant: small-caps;
                font-size: 12px;

                @include Mixins.ellipsis;
            }
        }

        .selection {
            display: flex;
            align-items: center;
            gap: 6px;

            .selectionIcon {
                stroke: var(--executive-blues-80);
                width: 20px;
                height: 20px;
            }

            .selectionText {
                color: var(--executive-blues-80);
                font-variant: small-caps;
                font-weight: 500;
                font-size: 14px;
                text-wrap: nowrap;
            }
        }

        // Give all immediate children divs a left border and padding to act as gap
        > div:not(:first-child) {
            border-left: 1px solid var(--secondary-50);
            padding-left: 16px;
        }
    }

    .buttonsWrapper {
        display: flex;
        align-items: center;

        gap: 8px;

        // Assign to all children buttons and links the same general styles
        a,
        button {
            display: flex;
            align-items: center;
            gap: 4px;
            padding: 0px 8px;

            height: 28px;

            color: var(--executive-blues-80);
            font-weight: 500;
            font-size: 14px;
            text-wrap: nowrap;

            .informationIcon {
                width: 16px;
                height: 16px;
                stroke: var(--executive-blues-80);
            }
        }
    }
}
