@import "../../cards/cards.variables.scss";

.root {
    width: calc($DEFAULT-CARD-WIDTH + $DEFAULT-CARD-GAP);
    height: $DEFAULT-CARD-HEIGHT;

    &.bottomHalfCard {
        width: 100%;
    }

    &.showArrows {
        .imageCarousel {
            .carouselArrows {
                opacity: 1;
            }
        }
    }

    .singleImage {
        width: 100%;
        height: 100%;

        object-fit: contain;
    }

    .imageCarousel {
        width: 100%;
        // -8px to leave extra padding at the bottom below the dots
        height: calc(100% - 8px);

        .carouselArrows {
            top: 53%; // Make top 53% to line up top with arrows of the property Image Carousel
            opacity: 0;
            transition: opacity 0.3s;

            background-color: var(--black-25);
        }

        .imageInSlide {
            max-width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
