/*
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.root {
    cursor: pointer;
    outline: none;

    .hamburgerBox {
        display: inline-block;
        position: relative;
        width: 20px;
        height: 10px;

        // Create all three lines for the hamburger
        // component div = middle line
        // before = top line
        // after = bottom line
        .hamburgerInner,
        .hamburgerInner::before,
        .hamburgerInner::after {
            display: block;
            position: absolute;
            background-color: var(--white);
            width: 20px;
            height: 1px;
        }

        .hamburgerInner {
            top: 50%;
            transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            margin-top: -2px;

            &::before {
                top: -6px;
                transition:
                    top 0.1s ease-in 0.25s,
                    opacity 0.1s ease-in;
                content: "";
            }

            &::after {
                bottom: -6px;
                transition:
                    bottom 0.1s ease-in 0.25s,
                    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
                content: "";
            }

            &.isActive {
                transform: rotate(225deg);
                transition: transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;

                &::before {
                    top: 0;
                    opacity: 0;
                    transition:
                        top 0.1s ease-out,
                        opacity 0.1s ease-out 0.12s;
                }

                &::after {
                    bottom: 0;
                    transform: rotate(-90deg);
                    transition:
                        bottom 0.1s ease-out,
                        transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
                }
            }
        }
    }

    &.dark {
        .hamburgerBox {
            .hamburgerInner,
            .hamburgerInner::before,
            .hamburgerInner::after {
                background-color: var(--executive-blues-80);
            }
        }
    }
}
