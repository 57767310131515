.root {
    display: flex;
    align-items: center;

    gap: 4px;

    .value {
        font-weight: 600;
    }

    svg {
        height: 14px;
    }

    .unitOfMeasurement {
        font-weight: 300;
    }
}
